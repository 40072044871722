<template>
  <div class="plan_adesao-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group label="Órgão Responsável" label-for="plan_adesoes-id_cadastro">
            <v-select
              id="plan_adesoes-id_cadastro"
              :options="listOrgaos"
              v-model="itemData.id_cadastro"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o Órgão Responsável..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
         <b-col md="6" sm="12">
          <b-form-group
            label="Nº do Processo (Licitação)"
            label-for="plan_adesoes-id_plan_licit"
          >
            <v-select
              id="plan_adesoes-id_plan_licit"
              :options="listLicitacao"
              v-model="itemData.id_plan_licit"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe a Licitação ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Enquadramento"
            label-for="plan_adesoes-enquadramento"
          >
            <b-form-select
              id="plan_adesoes-enquadramento"
              :options="optionsEnquadAdesao"
              v-model="itemData.enquadramento"
              required
              placeholder="Informe o Enquadramento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Modalidade" label-for="plan_adesoes-modalidade">
            <b-form-select
              id="plan_adesoes-modalidade"
              :options="optionsModalidadeReg"
              v-model="itemData.modalidade"
              required
              placeholder="Informe a Modalidade ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Forma de Pregão"
            label-for="plan_adesoes-forma_pregao"
          >
            <b-form-select
              id="plan_adesoes-forma_pregao"
              :options="optionsFormaPregao"
              v-model="itemData.forma_pregao"
              placeholder="Informe a Forma de Pregão ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Esfera" label-for="plan_adesoes-esfera">
            <b-form-select
              id="plan_adesoes-esfera"
              :options="optionsEsfera"
              v-model="itemData.esfera"
              required
              placeholder="Informe a Esfera ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Poder" label-for="plan_adesoes-poder">
            <b-form-select
              id="plan_adesoes-poder"
              :options="optionsPoder"
              v-model="itemData.poder"
              required
              placeholder="Informe o Poder ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Adesão Registros: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";
import {
  optionsEnquadAdesao,
  optionsModalidadeReg,
  optionsFormaPregao,
  optionsEsfera,
  optionsPoder,
} from "@/config/lists";

export default {
  name: "PlanAdesaoData",
  props: ["item"],
  components: {},
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listLicitacao: [],
      listOrgaos: [],
      optionsEnquadAdesao: optionsEnquadAdesao,
      optionsModalidadeReg: optionsModalidadeReg,
      optionsFormaPregao: optionsFormaPregao,
      optionsEsfera: optionsEsfera,
      optionsPoder: optionsPoder,
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/plan-adesoes/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/plan-adesoes${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListPlanLicitacoesRG() {
      const url = `${baseApiUrl}/plan-licitacoes-list_licitacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listLicitacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaOrgaos() {
      const url = `${baseApiUrl}/cadastros/1`;
      axios
        .patch(url)
        .then((res) => {
          this.listOrgaos = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListPlanLicitacoesRG();
    this.getListaOrgaos();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
