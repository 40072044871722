<template>
  <div class="siap-panel">
    <PageTitle icon="far fa-edit" main="Compras Públicas" sub="Planejamento" />
    <div class="siap-panel-tabs">
      <b-card no-body id="siap-panel">
        <b-tabs card>
          <b-tab title="Licitações" lazy>
            <PlanLicitacoesGrid ref="PlanLicitacoesGrid" />
          </b-tab>
          <b-tab title="Contratações" lazy>
            <PlanContratacoesGrid ref="PlanContratacoesGrid" />
          </b-tab>
          <!-- <b-tab title="XXXXXXX Orgãos Participantes" lazy :visible="userParams.admin == 2">
            <PlanOrgaosGrid ref="PlanOrgaosGrid" />
          </b-tab>
          <b-tab title="XXXXXXXX Orgão Itens" lazy :visible="userParams.admin == 2">
            <PlanOrgItensGrid ref="PlanOrgItensGrid" />
          </b-tab> -->
          <b-tab title="Adesão de Registro de Preço" lazy>
            <PlanAdesoesGrid ref="PlanAdesoesGrid" />
          </b-tab>
          <b-tab title="Agentes de Contratação" lazy>
            <PlanAgentesGrid ref="PlanAgentesGrid" />
          </b-tab>
          <b-tab title="Categorias de Itens" lazy>
            <PlanCategorias ref="PlanCategorias" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import PlanLicitacoesGrid from "../planLicitacoesAdmin/PlanLicitacoesGrid";
import PlanAgentesGrid from "../planAgentesAdmin/PlanAgentesGrid";
import PlanContratacoesGrid from "../planContratacoesAdmin/PlanContratacoesGrid";
import PlanCategorias from "./PlanCategorias";
// import PlanOrgaosGrid from "../planOrgaosAdmin/PlanOrgaosGrid";
// import PlanOrgItensGrid from "../planOrgItensAdmin/PlanOrgItensGrid";
import PlanAdesoesGrid from "../planAdesoesAdmin/PlanAdesoesGrid";
import PageTitle from "../template/PageTitle";

export default {
  name: "PlanAdminPanels",
  components: {
    PageTitle,
    PlanLicitacoesGrid,
    PlanAgentesGrid,
    PlanContratacoesGrid,
    PlanCategorias,
    // PlanOrgaosGrid,
    // PlanOrgItensGrid,
    PlanAdesoesGrid,
  },
  data: function () {
    return {
      userParams: {},
    };
  },
  methods: {
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
  },

  mounted() {
    this.loadUserParams()
  },

  computed: mapState(["user"]),
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>