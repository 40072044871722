<template>
  <div class="siap-panel">
    <PageTitle icon="fa fa-pencil" main="Editais" sub="Dados & Arquivos" />
    <div class="siap-panel-tabs">
      <b-card no-body id="siap-panel">
        <EditaisGrid ref="EditaisGrid"/>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditaisGrid from "../editaisAdmin/EditaisGrid";
import PageTitle from "../template/PageTitle";

export default {
  name: "EditaisPanels",
  components: { EditaisGrid, PageTitle },
  data: function () {
    return {};
  },
  methods: {},
  mounted() {},
  computed: mapState(["user"]),
};
</script>

<style>
</style>