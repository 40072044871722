<template>
  <div class="exec_alteracao-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nº do Processo (Licitação)"
            label-for="exec_alteracoes-id_pl_lic"
          >
            <v-select
              id="exec_alteracoes-id_pl_lic"
              :options="listLicitacao"
              v-model="itemData.id_pl_lic"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe a Licitação ..."
              @input="getLicitacao"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Veículo Publicação"
            label-for="exec_alteracoes-vei_pub_revogacao"
          >
            <b-form-select
              id="exec_alteracoes-vei_pub_revogacao"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_pub_revogacao"
              placeholder="Informe o Veículo de Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Tipo Aditivo"
            label-for="exec_alteracoes-tipo_aditivo"
          >
            <b-form-select
              id="exec_alteracoes-tipo_aditivo"
              :options="optionsTipoAditivo"
              v-model="itemData.tipo_aditivo"
              placeholder="Informe o Tipo de Aditivo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Tipo Alteração Valor"
            label-for="exec_alteracoes-tipo_alt_valor"
          >
            <b-form-select
              id="exec_alteracoes-tipo_alt_valor"
              :options="optionsTipoAlteracao"
              v-model="itemData.tipo_alt_valor"
              placeholder="Informe o Tipo de Alteração Valor ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Data de Publicação"
            label-for="exec_alteracoes-data_pub"
          >
            <date-picker
              v-model="itemData.data_pub"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Fim Vigência"
            label-for="exec_alteracoes-fim_vigencia"
          >
            <date-picker
              v-model="itemData.fim_vigencia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Justificativa Tipo"
            label-for="exec_alteracoes-just_tipo_aditivo"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_alteracoes-just_tipo_aditivo"
              type="text"
              v-model="itemData.just_tipo_aditivo"
              maxlength="1024"
              placeholder="Justificativa Outro Tipo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Link Publicação"
            label-for="exec_alteracoes-link_pub"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_alteracoes-link_pub"
              type="text"
              v-model="itemData.link_pub"
              maxlength="1024"
              placeholder="Link para Publicação ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Alteração Ata: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import {
  optionsTipoAditivo,
  optionsTipoAlteracao,
  optionsVeiculosPub,
} from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ExecAlteracaoData",
  props: ["item"],
  components: { DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listLicitacao: [],
      licitacao: {},
      isDate: "",
      optionsTipoAditivo: optionsTipoAditivo,
      optionsTipoAlteracao: optionsTipoAlteracao,
      optionsVeiculosPub: optionsVeiculosPub,
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/exec-alteracoes/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.getLicitacao();
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/exec-alteracoes${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.getLicitacao();
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getLicitacao() {
      const url = `${baseApiUrl}/plan-licitacoes/${this.itemData.id_pl_lic}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.licitacao = res.data
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListPlanLicitacoes() {
      const url = `${baseApiUrl}/exec-alteracoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listLicitacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListPlanLicitacoes();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
