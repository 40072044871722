<template>
  <div>
    <h2>{{ categoria.nome }}</h2>
    <b-button variant="outline-info" @click="newSubCategoria" v-b-tooltip.focus title="Nova Sub Categoria">
      Nova Sub Categoria
      <b-icon icon="plus" />
    </b-button>
    <PlanSubCategoria :keyId="key" :subCategoria="subCategoria" v-for="(subCategoria, key) in subCategorias" :key="key"
      class="ml-2" />
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/env";
import { showError } from "@/global";
import PlanSubCategoria from "./PlanSubCategoria";

export default {
  name: "PlanSubCategorias",
  components: { PlanSubCategoria },
  props: ["categoria", "keyId"],
  data: function () {
    return {
      subCategorias: [],
    };
  },
  methods: {
    loadSubCategorias(id_pai) {
        const url = `${baseApiUrl}/plan-categorias?id_pai=${id_pai}`;
        axios.patch(url).then((res) => {
          this.subCategorias = res.data.data.map((data) => {
            return {
              id: data.id,
              nome: data.nome,
            };
          });
        });
    },
    newSubCategoria() {
      const newName = prompt("Por favor insira nome da sub categoria", "");
      const url = `${baseApiUrl}/plan-categorias`;
      if (newName != null && newName.length > 0) {
        axios
          .post(url, { id_pai: this.categoria.id, nome: newName })
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.loadSubCategorias(this.categoria.id);
          })
          .catch((error) => {
            showError(error);
          });
      } else showError("Nome é obrigatório")
    },
  },
  mounted() {
    this.loadSubCategorias(this.categoria.id);
  },
};
</script>

<style>
</style>