<template>
  <div>
    <b-card no-body>
      <div class="adj_prop_itens-grid pdd-10">
        <b-input-group size="md" class="mt-3 mb-3">
          <!-- :disabled="btnNoNewItem" -->
          <b-button
            variant="outline-primary"
            slot="prepend"
            @click="newRow()"
            :title="`${btnNoNewItem ? 'Não há mais itens a inserir' : ''}`"
          >
            Novo Item
          </b-button>
          <b-form-input
            type="text"
            placeholder="Digite aqui para localizar o registro..."
            v-b-tooltip.hover
            :title="`Digite ao menos ${charSearchCount} caracteres para pesquisa dinâmica e use espaços para pesquisar por mais de uma expressão`"
            v-model="keyword"
            @input="searchAdjPropItem(charSearchCount)"
            @blur="searchAdjPropItem(0)"
            ref="keyword"
          />
          <b-btn size="sm" @click="searchAdjPropItem(0)">
            <i class="fa fa-search"></i>
          </b-btn>
          <b-input-group-text slot="append">
            <span v-if="isBrowser">{{ keyword_res }}&nbsp;</span>
            <b-btn
              :disabled="!keyword"
              variant="link"
              size="sm"
              @click="getRefresh"
              class="p-0"
            >
              <i class="fa fa-remove"></i>
            </b-btn>
          </b-input-group-text>
        </b-input-group>
        <b-table
          hover
          striped
          responsive
          :items="adj_prop_itens"
          :fields="fields"
        >
          <template #cell(adjLabel)="data">
            <span v-html="data.item.adjLabel" />
          </template>
          <template #cell(n_item)="data">
            <span v-html="data.item.n_item" />
          </template>
          <template #cell(descricao)="data">
            <span v-html="data.item.descricao" />
          </template>
          <template #cell(actions)="row">
            <b-button
              v-if="userParams.id >= 1"
              variant="outline-info"
              size="sm"
              class="mr-1"
              v-b-tooltip.hover
              title="Editar registro"
              @click="showRow(row.item, row.index, $event.target)"
              href="#header"
            >
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button
              v-if="userParams.id >= 1"
              variant="outline-danger"
              size="sm"
              class="mr-1"
              v-b-tooltip.hover
              title="Remover registro"
              @click="remove(row.item.id)"
            >
              <i class="fa fa-trash"></i>
            </b-button>
            <b-button
              v-if="userParams.id >= 1"
              :variant="`outline-${
                row.item.adjudicado == '1' ? 'dark' : 'success'
              }`"
              size="sm"
              v-b-tooltip.hover
              :title="`${
                row.item.adjLabel
                  ? 'Item ' + row.item.adjLabel
                  : 'Adjudicar Item'
              }`"
              :disabled="(row.item.adjudicado && row.item.adjudicado > 0) || row.item.isAdjudicado == 1"
              @click="adjudicar(row.item)"
            >
              <i class="fa fa-trophy"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination
          size="md"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
        />
      </div>
    </b-card>
    <b-modal
      size="xl"
      id="modal-form-AdjPropItemData"
      :title="modalTitle"
      centered
      scrollable
      hide-footer
    >
      <AdjPropItemData
        :tipoProposta="objProponente"
        :item="adjPropItemInputs"
        @objectInputs="loadAdjPropItem"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import { getDecimalFormater } from "@/config/globalFacilities";
import { showError, highlight, removeMark } from "@/global";
import AdjPropItemData from "./AdjPropItemData";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "AdjPropItensGrid",
  components: { AdjPropItemData },
  props: ["item"],
  data: function () {
    return {
      userParams: {},
      charSearchCount: 0,
      modalTitle: "Novo Registro",
      adj_prop_itens: [], // table
      adj_prop_item: {}, // field
      adjPropItemInputs: {}, // form data
      loading: false,
      objProponente: "1",
      mode: "save",
      keyword: "",
      isBrowser: isBrowser,
      btnNoNewItem: false,
      keyword_res: "",
      lblCancelar: "Cancelar",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        { key: "adjLabel", label: "Situação", sortable: true },
        { key: "n_item", label: "Item", sortable: true },
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "quantidade", label: "Quant", sortable: true },
        { key: "valor_unit", label: "R$ Unit", sortable: true },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions3",
          tdClass: "col-actions3",
        },
      ],
    };
  },
  methods: {
    searchAdjPropItem(length) {
      if (
        this.keyword.toString().length >= length ||
        this.keyword.toString().length == 0
      )
        this.loadAdjPropItem();
    },
    loadAdjPropItem() {
      const url = `${baseApiUrl}/adj-prop-itens/${this.item.id}?page=${this.page}&key=${this.keyword}`;
      axios.get(url).then((res) => {
        this.adj_prop_itens = res.data.data;
        this.adj_prop_itens.forEach((element) => {
          switch (element.adjudicado) {
            case "1":
              element.adjLabel = "Adjudicado";
              break;
            case "2":
              element.adjLabel = "Anulado";
              break;
            case "3":
              element.adjLabel = "Revogado";
              break;
            case "4":
              element.adjLabel = "Fracassado";
              break;
            default:
              element.adjLabel = undefined;
              break;
          }
          if (element.descricao)
            element.descricao = highlight(
              element.descricao
                .split(" ")
                .slice(0, 5)
                .toString()
                .replaceAll(",", " ") +
                `${element.descricao.split(" ").length > 5 ? "..." : ""}`,
              this.keyword.toString().toUpperCase()
            );
        });
        this.getListPlanItens();
        this.count = res.data.count;
        this.limit = res.data.limit;
        const pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${pluralize}`;
      });
    },
    getListPlanItens() {
      const id_cadastro = this.item.id_cad ? `id_cadastro=${this.item.id_cad}` : ''
      const url = `${baseApiUrl}/adj-prop-itens/${this.item.id}/0?${id_cadastro}`;
      axios
        .patch(url)
        .then((res) => {
          this.btnNoNewItem = res.data.data.length == 0;
        })
        .catch((error) => {
          showError(error);
        });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.searchAdjPropItem(0);
      this.getListPlanItens();
    },
    reset() {
      this.keyword = "";
      this.mode = "save";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.getRefresh();
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    newRow() {
      if (this.item.id_pl_lic) {
        this.objProponente = 1;
        this.modalTitle = `Novo Registro de Item de Proponente para a Licitação`;
      } else if (this.item.id_pl_cont) {
        this.objProponente = 2;
        this.modalTitle = `Novo Registro de Item de Proponente para a Contratação`;
      }
      this.adjPropItemInputs = {
        id_pl_lic: this.item.id_pl_lic,
        id_pl_cont: this.item.id_pl_cont,
        id_adj_prop: this.item.id,
        id_cadastro: this.item.id_cad,
      };
      this.$root.$emit("bv::show::modal", "modal-form-AdjPropItemData");
    },
    showRow(item, index, button) {
      Object.entries(item).forEach(([key, value]) => {
        if (item)
          this.adjPropItemInputs = {
            ...this.adjPropItemInputs,
            [key]: removeMark(value || ""),
          };
      });
      if (item.id_pl_lic) this.objProponente = "1";
      else if (item.id_pl_cont) this.objProponente = "2";
      this.modalTitle = `Item: ${this.adjPropItemInputs.marca_item} (${this.adjPropItemInputs.cod_barras})`;
      this.$root.$emit("bv::show::modal", "modal-form-AdjPropItemData", button);
    },
    remove(id) {
      const url = `${baseApiUrl}/adj-prop-itens/${this.item.id}/${id}`;
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.adjPropItemInputs = {};
                this.searchAdjPropItem(0);
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
    adjudicar(item) {
      this.$confirm({
        message: `Confirma que este proponente é o vencedor deste item?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            item.adjudicado = "1";
            axios
              .put(
                `${baseApiUrl}/adj-prop-itens/${item.id_adj_prop}/${item.id}`,
                item
              )
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.loadAdjPropItem();
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
  },
  mounted() {
    this.loadUserParams();
    this.searchAdjPropItem(0);
  },
  watch: {
    page() {
      this.searchAdjPropItem(0);
    },
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.adj_prop_itens-data {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>