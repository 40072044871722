<template>
  <div class="conv_aditivo-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nº do Convênio"
            label-for="conv_aditivos-id_conv_conv"
          >
            <v-select
              id="conv_aditivos-id_conv_conv"
              :options="listConvenio"
              v-model="itemData.id_conv_conv"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Termo do Convênio ..."
              @input="getConvenio"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Tipo Aditivo" label-for="conv_aditivos-tipo">
            <b-form-select
              id="conv_aditivos-tipo"
              :options="optionsTipoAditConv"
              v-model="itemData.tipo"
              required
              placeholder="Informe o Tipo Aditivo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nº Aditivo"
            label-for="conv_aditivos-termo_aditivo"
          >
            <b-form-input
              id="conv_aditivos-termo_aditivo"
              type="text"
              v-model="itemData.termo_aditivo"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe o Termo Aditivo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Aditivo"
            label-for="conv_aditivos-data"
          >
            <date-picker
              v-model="itemData.data"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Fim Vigência"
            label-for="conv_aditivos-data_fim_vig"
          >
            <date-picker
              v-model="itemData.data_fim_vig"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Publicação"
            label-for="conv_aditivos-data_pub"
          >
            <date-picker
              v-model="itemData.data_pub"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Veiculo Publicação"
            label-for="conv_aditivos-vei_pub"
          >
            <b-form-select
              id="conv_aditivos-vei_pub"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_pub"
              required
              placeholder="Informe o Veiculo Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Ato Publicação"
            label-for="conv_aditivos-ato_pub"
          >
            <b-form-input
              id="conv_aditivos-ato_pub"
              type="text"
              v-model="itemData.ato_pub"
              maxlength="32"
              required
              placeholder="Informe o Ato Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Valor Repasse"
            label-for="conv_aditivos-v_repasse"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.v_repasse"
                v-bind="money"
                id="conv_aditivos-v_repasse"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Valor Contrapartida"
            label-for="conv_aditivos-v_contrapartida"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.v_contrapartida"
                v-bind="money"
                id="conv_aditivos-v_contrapartida"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Valor Global"
            label-for="conv_aditivos-v_global"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.v_global"
                v-bind="money"
                id="conv_aditivos-v_global"
                class="valor-field-right form-control"
                required
                readonly
                @input="getSoma"
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Objeto do Aditivo"
            label-for="conv_aditivos-objeto"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="conv_aditivos-objeto"
              type="text"
              v-model="itemData.objeto"
              maxlength="1024"
              required
              placeholder="Descrição Objeto do Termo Aditivo ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Aditivo Convênio: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { Money } from "v-money";
import { optionsTipoAditConv, optionsVeiculosPub } from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ConvAditivoData",
  props: ["item"],
  components: { Money, DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listConvenio: [],
      convenio: {},
      optionsTipoAditConv: optionsTipoAditConv,
      optionsVeiculosPub: optionsVeiculosPub,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/conv-aditivos/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.getConvenio();
          this.getSoma();
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/conv-aditivos${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.getConvenio();
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    getSoma(){
      if (this.itemData.v_repasse && this.itemData.v_contrapartida){
        this.itemData.v_global = this.itemData.v_repasse + this.itemData.v_contrapartida
      } else if (this.itemData.v_global != this.itemData.v_repasse + this.itemData.v_contrapartida){
        //this.itemData.v_global = '';
      }
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListConvenios() {
      const url = `${baseApiUrl}/conv-convenios`;
      axios
        .patch(url)
        .then((res) => {
          this.listConvenio = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_termo}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getConvenio() {
      const url = `${baseApiUrl}/conv-convenios/${this.itemData.id_conv_conv}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.convenio = res.data
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListConvenios();
    this.getSoma();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
