<template>
  <div class="siap-panel">
    <PageTitle icon="far fa-edit" main="Compras Públicas" sub="Arquivos" />
    <div class="siap-panel-tabs">
      <b-card no-body id="siap-panel">
        <b-tabs card>
          <b-tab title="Arquivos" lazy>
            <Arquivos ref="Arquivos" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Arquivos from "../siapAdmin/Arquivos";
import PageTitle from "../template/PageTitle";

export default {
  name: "SiapAdminPanels",
  components: {
    PageTitle,
    Arquivos
  },
  data: function () {
    return {};
  },
  methods: {},

  mounted() {},

  computed: mapState(["user"]),
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>