<template>
  <div>
    <b-overlay :show="downloading" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <Loading />
          <p id="cancel-label">Aguarde só um pouco &#128521;</p>
          <p id="cancel-label">O arquivo está no forno e chega já ...</p>
        </div>
      </template>
      <b-form>
        <b-row>
          <b-col md="6" sm="12">
            <b-row>
              <b-col md="12" sm="12">
                <h2 class="mt-5 mb-5 text-center">Relatórios</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" sm="12">
                <b-form-group label="Selecione a Unidade" label-for="relatorios-cardug">
                  <v-select id="relatorios-cardug" :options="listUnidades" v-model="item_relatorio.cardug"
                    :reduce="(label) => label.code" @input="getListaSetores()" required multiple
                    placeholder="Informe a Unidade Autonôma ...">
                    <div slot="no-options">
                      A opção digitada não existe!!! Tente novamente...
                    </div>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12">
                <b-form-group label="Selecione o Setor" label-for="relatorios-setor">
                  <v-select id="relatorios-setor" :options="listSetores" v-model="item_relatorio.setor"
                    :reduce="(label) => label.code" @input="getListaDepartamentos()" required multiple
                    placeholder="Informe o Setor ...">
                    <div slot="no-options">
                      A opção digitada não existe!!! Tente novamente...
                    </div>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="8" sm="12">
                <b-form-group label="Selecione o Departamento" label-for="relatorios-departamento">
                  <v-select id="relatorios-departamento" :options="listDepartamentos"
                    v-model="item_relatorio.departamento" :reduce="(label) => label.code" required multiple
                    placeholder="Informe o Departamento ...">
                    <div slot="no-options">
                      A opção digitada não existe!!! Tente novamente...
                    </div>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12">
                <b-form-group label="." label-class="invis" label-for="arquivos-mes">
                  <b-button @click="printReport" variant="outline-success" block :disabled="reporting">
                    Imprimir <i class="fas fa-print"></i>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6" sm="12">
            <b-row>
              <b-col md="12" sm="12">
                <h2 class="mt-5 mb-5 text-center">Baixar Arquivos SIAP / Patrimônio</h2>
              </b-col>
            </b-row>
            <b-row class="">
              <b-col md="12" sm="12">
                <b-form-group label="Selecione a Unidade" label-for="arquivos-cardug">
                  <v-select id="arquivos-cardug" :options="listUnidades" v-model="item.cardug"
                    :reduce="(label) => label.code" @input="activeDownload" required
                    placeholder="Informe a Unidade Autonôma ...">
                    <div slot="no-options">
                      A opção digitada não existe!!! Tente novamente...
                    </div>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="5" sm="12">
                <b-form-group label="Exercício" label-for="arquivos-exercicio">
                  <b-form-select id="arquivos-exercicio" type="text" v-model="item.exercicio"
                    :options="optionExercicios" @input="activeDownload" required
                    placeholder="Informe o exercício ..." />
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12">
                <b-form-group label="Mes" label-for="arquivos-mes">
                  <b-form-select id="arquivos-mes" type="text" v-model="item.mes" :options="optionMes"
                    @input="activeDownload" required placeholder="Informe o mes ..." />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12">
                <b-form-group label="." label-class="invis" label-for="arquivos-mes">
                  <b-button @click="downloadFiles" variant="outline-success" block :disabled="disabled || downloading">
                    Baixar Arquivos <i class="fas fa-file-download"></i>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { showError, downloadFile } from "@/global";
import { baseApiUrl } from "@/env";
import axios from "axios";
import Loading from "@/components/template/Loading";

export default {
  name: "Arquivos",
  props: ["userParams"],
  components: { Loading },
  data: function () {
    return {
      item: {},
      item_relatorio: {},
      reportParams: {},
      optionExercicios: [{ value: new Date().getFullYear(), text: new Date().getFullYear() }],
      optionMes: [
        { value: "01", text: "01" },
        { value: "02", text: "02" },
        { value: "03", text: "03" },
        { value: "04", text: "04" },
        { value: "05", text: "05" },
        { value: "06", text: "06" },
      ],
      listUnidades: [],
      listSetores: [],
      listDepartamentos: [],
      disabled: true,
      downloading: false,
      disabledReporting: true,
      reporting: false,
      Loading: false,
      downloadFile: downloadFile,
    };
  },
  methods: {
    getListaUnidades() {
      const url = `${baseApiUrl}/und-autonomas`;
      axios
        .patch(url)
        .then((res) => {
          this.listUnidades = res.data.data.map((data) => {
            return {
              code: data.cardug,
              label: `(${data.cnpj})- ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaSetores() {
      const url = `${baseApiUrl}/patr-setores`;
      axios
        .patch(url, { cardug: this.item_relatorio.cardug })
        .then((res) => {
          this.listSetores = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.setor}`,
            };
          });
          delete this.item_relatorio.setor
          delete this.item_relatorio.departamento
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaDepartamentos() {
      const url = `${baseApiUrl}/patr-deptos`;
      axios
        .patch(url, { setor: this.item_relatorio.setor })
        .then((res) => {
          this.listDepartamentos = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.departamento}`,
            };
          });
          delete this.item_relatorio.departamento
        })
        .catch((error) => {
          showError(error);
        });
    },
    getExercicios() {
      this.optionExercicios = []
      const today = new Date()
      const anoAtual = today.getFullYear()
      for (let index = 0; index < 5; index++) {
        this.optionExercicios.push({ value: anoAtual - index, text: anoAtual - index })
      }
      this.getMes()
    },
    getMes() {
      const today = new Date()
      const mesAtual = today.getMonth()
      this.optionMes = []
      if (new Date().getFullYear() == this.item.exercicio) {
        for (let index = 0; index < mesAtual + 1; index++) {
          this.optionMes.push({ value: mesAtual + 1 - index, text: mesAtual + 1 - index })
        }
      } else {
        this.optionMes = [
          { value: 1, text: 1 },
          { value: 2, text: 2 },
          { value: 3, text: 3 },
          { value: 4, text: 4 },
          { value: 5, text: 5 },
          { value: 6, text: 6 },
          { value: 7, text: 7 },
          { value: 8, text: 8 },
          { value: 9, text: 9 },
          { value: 10, text: 10 },
          { value: 11, text: 11 },
          { value: 12, text: 12 }
        ]
      }
    },
    // getExercicios() {
    //   const url = `${baseApiUrl}/patrimonio/f-a/gle`;
    //   axios
    //     .get(url)
    //     .then((body) => {
    //       this.optionExercicios = body.data.data.map((data) => {
    //         return {
    //           value: data.exercicio,
    //           text: data.exercicio,
    //         };
    //       });
    //     })
    //     .catch(showError);
    // },
    // getMes() {
    //   const url = `${baseApiUrl}/patrimonio/f-a/glm`;
    //   axios
    //     .get(url)
    //     .then((body) => {
    //       this.optionMes = body.data.data.map((data) => {
    //         return {
    //           value: data.mes,
    //           text: data.mes,
    //         };
    //       });
    //     })
    //     .catch(showError);
    // },
    downloadFiles() {
      this.downloading = true;
      let url = `${baseApiUrl}/patrimonio-xml`;
      //console.log(this.item);
      axios
        .post(url, this.item)
        .then((body) => {
          setTimeout(() => {
            url = `${baseApiUrl}/patrimonio-xml/f-a/szf`;
            axios
              .post(url, body.data)
              .then((body) => {
                this.$toasted.global.defaultSuccess({
                  msg: `Arquivo ${body.data.data.zipNameFile} baixado com sucesso!`,
                });
                const urlDown = `${baseApiUrl}/${body.data.data.zipUrlFile}`;
                // console.log(urlDown);
                this.downloadFile(urlDown, body.data.data.zipNameFile);
                this.downloading = false;
              })
              .catch((error) => {
                // console.log(1, error);
                showError({ msg: error });
              });
          }, 3000);
        })
        .catch((error) => {
          // console.log(2, error);
          showError({ msg: error });
        });
    },
    activeDownload() {
      this.disabled = !(
        this.item.cardug &&
        this.item.exercicio &&
        this.item.mes
      );
    },
    printReport() {
      this.reportParams = {}
      let body = {}
      console.log(this.item_relatorio);
      let ua = '1 = 1 '
      let setor = 'and 1 = 1 '
      let departamento = 'and 1 = 1 '
      if (this.item_relatorio.cardug && this.item_relatorio.cardug.length > 0) {
        ua = 'ua.cardug IN ('
        this.item_relatorio.cardug.forEach(element => {
          ua += `${element},`
        });
        ua = ua.substring(0, ua.length - 1)
        ua += ') '
      }
      if (this.item_relatorio.setor && this.item_relatorio.setor.length > 0) {
        setor = 'and ps.id IN ('
        this.item_relatorio.setor.forEach(element => {
          setor += `${element},`
        });
        setor = setor.substring(0, setor.length - 1)
        setor += ') '
      }
      if (this.item_relatorio.departamento && this.item_relatorio.departamento.length > 0) {
        departamento = 'and pd.id IN ('
        this.item_relatorio.departamento.forEach(element => {
          departamento += `${element},`
        });
        departamento = departamento.substring(0, departamento.length - 1)
        departamento += ') '
      }
      const filterReport = ua + setor + departamento
      let title = "Bens Patrimonais";
      let description = "Resumo de bens e suas locações";
      body = {
        ...body,
        filterReport: filterReport,
        title: title,
        description: description,
        encoding: "base64",
        exportType: "pdf",
      }
      this.loading = !this.loading;
      const url = `${baseApiUrl}/relatorios/f-a/grp`;
      this.fileSrc = undefined;
      axios
        .post(url, body)
        .then((res) => {
          this.fileSrc = res.data;
          if (this.downloadACopy)
            this.downloadPDF(
              res.data,
              title.replaceAll(" ", "_").replace("_-_", "_")
            );
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            `<iframe width='100%' height='100%' src='data:application/pdf;base64, 
              ${encodeURI(this.fileSrc)} '></iframe>`
          );
          this.loading = !this.loading;
        })
        .catch(showError);
    },
  },
  mounted() {
    this.getListaUnidades();
    // this.getExercicios();
    // this.getMes();
  },
  watch: {
  },
  computed: mapState(["user"]),
};
</script>

<style>
.invis {
  color: white;
}
</style>