<template>
  <div class="page-title">
    <b-row>
      <b-col md="12">
        <h1 v-html="main"><i v-if="icon" :class="icon"></i></h1>
        <h2>{{ sub }}</h2>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<script>
import { baseApiUrl } from "@/env";
import { capitalizeFirst } from "@/config/globalFacilities";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "PageTitle",
  props: ["icon", "main", "sub"],
  components: {},
  data: function () {
    return {
      userParams: {},
      dominios: [],
      keyword: "",
    };
  },
  methods: {
    loadCadastros() {
      if (this.keyword) {
        if (!["0", "1"].includes(this.userParams.tipoUsuario.toString())) {
          this.$router.push({
            path: `/cadastros`,
            query: { key: this.keyword },
          });
          location.reload();
        } else if (this.userParams.tipoUsuario >= 1) {
          const url = `/servidor-panel/${this.keyword}`;
          this.$router.push({ path: url });
          location.reload();
        }
      }
    },
    getKeySearch() {
      if (this.$route.query.key) this.keyword = this.$route.query.key;
    },
    loadDominios(cliente) {
      const param = {
        dominio: cliente,
        meta: "domainName",
        forceDominio: true,
      };
      axios.post(`${baseApiUrl}/params`, param).then((res) => {
        this.dominios = res.data.data.map((data) => {
          return {
            value: data.value,
            text: capitalizeFirst(data.label.replace("_", " ")),
          };
        });
      });
    },
    loadUserParams() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/users/${this.user.id}`;
        axios.get(url).then((res) => {
          this.userParams = res.data;
          if (this.userParams && this.userParams.cliente)
            this.loadDominios(this.userParams.cliente);
        });
      }
    },
  },
  mounted() {
    this.getKeySearch();
    if (this.user && this.user.id) this.loadUserParams();
  },
  computed: mapState(["user"]),
};
</script>

<style>
.page-title h1 {
  margin: 0px;
  color: #3a1413d8;
}

.page-title h2 {
  color: #3a1413c2;
  margin-top: 5px;
  font-size: 1.3rem;
}

.search {
  max-width: 50%;
}
</style>
