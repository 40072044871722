<template>
  <div class="obra_drt-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
         <b-col md="12" sm="12">
          <b-form-group
            label="Nº do Contrato"
            label-for="obras_drts-id_exec_contrat"
          >
            <v-select
              id="obras_drts-id_exec_contrat"
              :options="listContrato"
              v-model="itemData.id_exec_contrat"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº do Contrato ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Responsável (Pessoas & Empresas)" label-for="obras_drts-id_cadastro">
            <v-select
              id="obras_drts-id_cadastro"
              :options="listCadas"
              v-model="itemData.id_cadastro"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o Responsável Técnico..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <!-- <b-col md="4" sm="12">
          <b-form-group
            label="Qualificação Profissional"
            label-for="obras_drts-qualificacao"
          >
            <b-form-select
              id="obras_drts-qualificacao"
              :options="optionsQualificacao"
              v-model="itemData.qualificacao"
              required
              placeholder="Informe a Qualificação ..."
            />
          </b-form-group>
        </b-col> -->
        <b-col md="3" sm="12">
          <b-form-group
            label="Data da Emissão"
            label-for="obras_drts-data"
          >
            <date-picker
              v-model="itemData.data"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>  
        <b-col md="3" sm="12">
          <b-form-group label="Nº Documento RT" label-for="obras_drts-n_doc">
            <b-form-input
              id="obras_drts-n_doc"
              v-model="itemData.n_doc"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe o Nº Documento de Responsabilidade Técnica..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Etapa" label-for="obras_drts-etapa">
            <b-form-select
              id="obras_drts-etapa"
              :options="optionsEtapas"
              v-model="itemData.etapa"
              required
              placeholder="Informe a Etapa de Responsabilidade Técnica. ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Tipo de Vinculo"
            label-for="obras_drts-tipo_vinculo"
          >
            <b-form-select
              id="obras_drts-tipo_vinculo"
              :options="optionsTipoVinc"
              v-model="itemData.tipo_vinculo"
              placeholder="Informe o Tipo de Vinculo do Servidor Fiscal.. ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >DRT: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { optionsEtapas, optionsTipoVinc } from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ObraDrtData",
  props: ["item"],
  components: { DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listContrato: [],
      listCadas: [],
      optionsEtapas: optionsEtapas,
      optionsTipoVinc: optionsTipoVinc,
      isDate: "",
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      // optionsQualificacao: [
      //   { value: "01", text: "Engenheiro Civil" },
      //   { value: "02", text: "Engenheiro Eletricista" },
      //   { value: "03", text: "Engenheiro de Telecomunicações" },
      //   { value: "04", text: "Engenheiro – Mecânica" },
      //   { value: "05", text: "Engenheiro – Minas" },
      //   { value: "06", text: "Engenheiro – Químico" },
      //   { value: "07", text: "Engenheiro – Naval" },
      //   { value: "08", text: "Engenheiro – Outros" },
      //   { value: "09", text: "Geólogo" },
      //   { value: "10", text: "Técnico – Edificações" },
      //   { value: "11", text: "Técnico – Estradas" },
      //   { value: "12", text: "Técnico – Eletrônica" },
      //   { value: "13", text: "Técnico – Telecomunicações" },
      //   { value: "14", text: "Técnico – Eletrotécnico" },
      //   { value: "15", text: "Técnico – Agrícola" },
      //   { value: "16", text: "Técnico – Refrigeração" },
      //   { value: "17", text: "Técnico – Mecânica" },
      //   { value: "18", text: "Técnico – Mineração" },
      //   { value: "19", text: "Técnico – Outros" },
      //   { value: "20", text: "Arquiteto" },
      //   { value: "99", text: "Outros" },
      // ],
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/obras-drts/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/obras-drts${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
     getListExecContratos() {
      const url = `${baseApiUrl}/exec-contratos`;
      axios
        .patch(url)
        .then((res) => {
          this.listContrato = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contrato}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadas = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListExecContratos();
    this.getListaCadastros();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
