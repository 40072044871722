<template>
  <div class="home">
    <PageTitle icon="" :main="title" :sub="subTitle" />
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import { mapState } from "vuex";
import { baseApiUrl } from "@/env";
import { assets } from "@/global";
import axios from "axios";

export default {
  name: "Home",
  components: { PageTitle },
  data: function () {
    return {
      userParams: {},
      assets: assets,
      statsBrowser: "",
      title: `Sistema Municipal de Gestão`,
      subTitle: undefined,
    };
  },
  computed: mapState(["user"]),
  methods: {
    loadUserParams() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/users/${this.user.id}`;
        axios.get(url).then((res) => {
          this.userParams = res.data;
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadUserParams();
    }, 1000);
  },
};
</script>

<style>
.non-pointed {
  list-style-type: none;
}
</style>
