<template>
  <div class="obras-panel">
    <PageTitle icon="far fa-edit" main="Obras" sub="Dados & Cia" />
    <div class="obras-panel-tabs">
      <b-card no-body id="obras-panel">
        <b-tabs v-model="tabIndex" card>
          <b-tab title="Obras" lazy>
            <ObrasGrid ref="ObrasGrid" />
          </b-tab>
          <b-tab title="Ordens de Serviço" lazy>
            <ObrasOSGrid ref="ObrasOSGrid" />
          </b-tab>
          <b-tab title="Cadastro Nacional" lazy>
            <ObrasCnosGrid ref="ObrasCnosGrid" />
          </b-tab>
          <b-tab title="Acompanhamentos" lazy>
            <ObrasAcompansGrid ref="ObrasAcompansGrid" />
          </b-tab>
          <b-tab title="Medições" lazy>
            <ObrasMedicoesGrid ref="ObrasMedicoesGrid" />
          </b-tab>
          <b-tab title="DRTs" lazy>
            <ObrasDrtsGrid ref="ObrasDrtsGrid" />
          </b-tab>
          <b-tab title="Licença Ambiental" lazy>
            <ObrasLicencasGrid ref="ObrasLicencasGrid" />
          </b-tab>
          <b-tab title="Arquivos" lazy>
            <Arquivos ref="Arquivos" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ObrasGrid from "../obrasAdmin/ObrasGrid";
import ObrasOSGrid from "../obrasOsAdmin/ObrasOSGrid";
import ObrasCnosGrid from "../obrasCnosAdmin/ObrasCnosGrid";
import ObrasAcompansGrid from "../obrasAcompansAdmin/ObrasAcompansGrid";
import ObrasMedicoesGrid from "../obrasMedicoesAdmin/ObrasMedicoesGrid";
import ObrasDrtsGrid from "../obrasDrtsAdmin/ObrasDrtsGrid";
import ObrasLicencasGrid from "../obrasLicencasAdmin/ObrasLicencasGrid";
import PageTitle from "../template/PageTitle";
import Arquivos from "./Arquivos";

export default {
  name: "ObrasAdminPanels",
  components: {
    PageTitle,
    ObrasGrid,
    ObrasOSGrid,
    ObrasCnosGrid,
    ObrasAcompansGrid,
    ObrasMedicoesGrid,
    ObrasDrtsGrid,
    ObrasLicencasGrid,
    Arquivos,
  },
  data: function () {
    return {
      tabIndex: undefined,
    };
  },
  methods: {
    goTab() {
      switch (location.hash) {
        case "#ordens-de-servicos":
          this.tabIndex = 1;
          break;
        case "#cadastro-nacional":
          this.tabIndex = 2;
          break;
        case "#acompanhamentos":
          this.tabIndex = 3;
          break;
        case "#medicoes":
          this.tabIndex = 4;
          break;
        case "#documento-responsabilidade-tecnica":
          this.tabIndex = 5;
          break;
        case "#licenca-ambiental":
          this.tabIndex = 6;
          break;
        case "#arquivos":
          this.tabIndex = 7;
          break;
        default:
          this.tabIndex = 0;
          break;
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.goTab();
    }, 250);
  },
  computed: mapState(["user"]),
  watch: {
    $route() {
      this.goTab();
    },
  },
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>