<template>
  <div class="patr_depto-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <input type="hidden" v-model="itemData.id_import" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Setor"
            label-for="patr_deptos-id_patr_setor"
          >
            <v-select
              id="patr_deptos-id_patr_setor"
              :options="listSetor"
              v-model="itemData.id_patr_setor"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o Setor do Patrimônio ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Sigla do Departamento"
            label-for="patr_deptos-sigla_depto"
          >
            <b-form-input
              id="patr_deptos-sigla_depto"
              type="text"
              v-model="itemData.sigla_depto"
              maxlength="50"
              required
              placeholder="Informe a Sigla do Departamento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nome do Departamento"
            label-for="patr_deptos-departamento"
          >
            <b-form-input
              id="patr_deptos-departamento"
              type="text"
              v-model="itemData.departamento"
              maxlength="50"
              required
              placeholder="Informe o Nome do Departamento ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset"> Cancelar </b-button>
        <code v-if="itemData.hash" class="float-right"
          >Departamento: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";

export default {
  name: "PatrDeptoData",
  props: ["item"],
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listSetor: [],
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/patr-deptos/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      this.itemData.sigla_depto = this.itemData.sigla_depto.toUpperCase()
      this.itemData.departamento = this.itemData.departamento.toUpperCase()
      axios[method](`${baseApiUrl}/patr-deptos${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
          this.$bvModal.hide("modal-form");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListaPatrSetores() {
      const url = `${baseApiUrl}/patr-setores`;
      axios
        .patch(url)
        .then((res) => {
          this.listSetor = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.setor}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaPatrSetores();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
