<template>
  <div class="patrimonio-data">
    <div class="code-capture" v-if="codeBarInCap">
      <PatrimonioCodeBarCapture ref="codeBarCapture" @getCodeBarCapt="setCodeBarCapt" />
      <b-button @click="codeBarInCap = false" block variant="outline-info">Digitar Código Manualmente</b-button>
    </div>
    <b-card no-body v-else>
      <b-tabs v-model="tabIndex" card>
        <b-tab title="Objeto" lazy>
          <b-form>
            <input type="hidden" v-model="itemData.id" />
            <input type="hidden" v-model="itemData.evento" />
            <input type="hidden" v-model="itemData.status" />
            <input type="hidden" v-model="itemData.created_at" />
            <input type="hidden" v-model="itemData.updated_at" />
            <b-row>
              <b-col md="2" sm="12">
                <b-form-group label="Ano" label-for="patrimonios-exercicio">
                  <b-input type="number" id="patrimonios-exercicio" v-model="itemData.exercicio" required
                    :class="anoIsValid" placeholder="Informe o Ano ..." :min="minExercicio" @input="setMinAnoExercicio">
                  </b-input>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Mês" label-for="patrimonios-mes">
                  <b-form-select id="patrimonios-mes" :options="optionsMes" v-model="itemData.mes" required
                    placeholder="Selecione ..." />
                </b-form-group>
              </b-col>
              <b-col md="8" sm="12">
                <b-form-group label="Unidade Autônoma" label-for="patrimonios-id_ua">
                  <v-select id="patrimonios-id_ua" :options="listPUnidade" v-model="itemData.id_ua"
                    :reduce="(label) => label.code" required :disabled="mode === 'remove'"
                    placeholder="Informe a Unidade ...">
                    <div slot="no-options">
                      A opção digitada não existe!!! Tente novamente...
                    </div>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12">
                <b-form-group label="Código" label-for="patrimonios-cod">
                  <b-input-group>
                    <b-form-input id="patrimonios-cod" type="text" v-model="itemData.cod" @keypress="isNumber($event)"
                      maxlength="16" required placeholder="Informe o Código ..." />
                    <template #append>
                      <b-button variant="outline-success" @click="codeBarInCap = !codeBarInCap">
                        <i class="fas fa-camera"></i>
                      </b-button>
                    </template>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="9" sm="12">
                <b-form-group label="Nome" label-for="patrimonios-nome_comum">
                  <b-form-input id="patrimonios-nome_comum" type="text" v-model="itemData.nome_comum" maxlength="50"
                    required placeholder="Informe o Nome ..." />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12">
                <b-form-group label="Descrição" label-for="patrimonios-descricao">
                  <b-form-textarea rows="5" max-rows="7" id="patrimonios-descricao" type="text"
                    v-model="itemData.descricao" maxlength="1024" required placeholder="Informe a Descrição ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 1">
                <b-form-group label="Tipo da Propriedade" label-for="patrimonios-tipo_propriedade">
                  <b-form-select id="patrimonios-tipo_propriedade" type="text" v-model="itemData.tipo_propriedade"
                    :options="optionsTipo" required placeholder="Informe a Propriedade ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 1">
                <b-form-group label="Natureza do Bem" label-for="patrimonios-natureza_bem">
                  <b-form-select id="patrimonios-natureza_bem" type="text" v-model="itemData.natureza_bem"
                    :options="optionsNatureza" required placeholder="Informe a Natureza do Bem ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 1">
                <b-form-group label="Tipo da Utilização" label-for="patrimonios-tipo_utilizacao">
                  <b-form-select id="patrimonios-tipo_utilizacao" type="text" v-model="itemData.tipo_utilizacao"
                    :options="optionsUtilizacao" placeholder="Informe o Tipo da Utilização ..." />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12" v-if="tipoRegistro == 1">
                <b-form-group label="Instrumento de Destinação" label-for="patrimonios-instrumento_dest">
                  <b-form-select id="patrimonios-instrumento_dest" v-model="itemData.instrumento_dest"
                    :options="optionsInstrumento" placeholder="Informe o Instrumento de Destinação ..." />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12" v-if="tipoRegistro == 1">
                <b-form-group label="Categoria do Bem" label-for="patrimonios-categoria">
                  <b-form-select id="patrimonios-categoria" v-model="itemData.categoria" :options="optionsCategoria"
                    placeholder="Informe a Categoria do Bem ..." required />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12" v-if="tipoRegistro == 1">
                <b-form-group label="Cessionário (Pessoas & Empresas)" label-for="patrimonios-cessionario">
                  <v-select id="patrimonios-cessionario" :options="listCadas" v-model="itemData.cessionario"
                    :reduce="(label) => label.code" required :disabled="mode === 'remove'"
                    placeholder="Informe o Cessionário ...">
                    <div slot="no-options">
                      A opção digitada não existe!!! Tente novamente...
                    </div>
                  </v-select>
                </b-form-group>
              </b-col>
              <!-- <b-col md="4" sm="12" v-if="tipoRegistro == 1">
                <b-form-group
                  label="CNPJ do Cessionário"
                  label-for="patrimonios-cnpj"
                >
                  <the-mask
                    id="patrimonios-cnpj"
                    class="form-control"
                    :class="`form-control ${isCnpj}`"
                    type="text"
                    @input="setValidCnpj"
                    v-model="itemData.cnpj"
                    :mask="['##.###.###/####-##']"
                    placeholder="Informe o Cnpj ..."
                  />
                </b-form-group>
              </b-col>
              <b-col md="8" sm="12" v-if="tipoRegistro == 1">
                <b-form-group
                  label="Nome do Cessionário"
                  label-for="patrimonios-cessionario"
                >
                  <b-form-input
                    id="patrimonios-cessionario"
                    type="text"
                    v-model="itemData.cessionario"
                    maxlength="255"
                    placeholder="Informe o Nome do Cessionário ..."
                  />
                </b-form-group>
              </b-col> -->
              <b-col md="2" sm="12" v-if="tipoRegistro == 2">
                <b-form-group label="Ano de Fabricação" label-for="patrimonios-vei_ano">
                  <b-form-input id="patrimonios-vei_ano" type="text" v-model="itemData.vei_ano"
                    @keypress="isNumber($event)" maxlength="4" required placeholder="Informe o Ano de Fabricação ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 2">
                <b-form-group label="Tipo de Combustível" label-for="patrimonios-vei_tipo_combustivel">
                  <b-form-select id="patrimonios-vei_tipo_combustivel" type="text" v-model="itemData.vei_tipo_combustivel"
                    :options="optionsTipoComb" required placeholder="Informe o Tipo de Combustível ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 2">
                <b-form-group label="Código FIPE" label-for="patrimonios-vei_cod_fipe">
                  <b-form-input id="patrimonios-vei_cod_fipe" type="text" v-model="itemData.vei_cod_fipe"
                    @keypress="isNumber($event)" maxlength="12" required placeholder="Informe o Código FIPE ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 2">
                <b-form-group label="Placa do Veículo" label-for="patrimonios-vei_placa">
                  <b-form-input id="patrimonios-vei_placa" type="text" v-model="itemData.vei_placa" maxlength="12"
                    required placeholder="Informe o Placa do Veículo ..." />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12" v-if="tipoRegistro == 2">
                <b-form-group label="Nº Renavan" label-for="patrimonios-vei_renavan">
                  <b-form-input id="patrimonios-vei_renavan" type="text" v-model="itemData.vei_renavan"
                    @keypress="isNumber($event)" maxlength="20" required placeholder="Informe o Número Renavan ..." />
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12" v-if="tipoRegistro == 3">
                <b-form-group label="Data do Registro" label-for="patrimonios-loc_data">
                  <date-picker v-model="itemData.loc_data" valueType="DD/MM/YYYY" format="DD/MM/YYYY" type="date" required
                    input-class="form-control" />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 3">
                <b-form-group label="CEP" label-for="patrimonios-cep">
                  <the-mask id="patrimonios-cep" type="text" @input="getEnderecoApi" v-model="itemData.loc_cep"
                    :mask="['########']" :class="`form-control ${cepClass}`" :readonly="mode === 'remove'" required
                    placeholder="Informe o Cep..." />
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12" v-if="tipoRegistro == 3">
                <b-form-group label="UF" label-for="patrimonios-uf">
                  <b-form-select id="patrimonios-uf" :options="ufList" v-model="itemData.loc_uf" @input="getListaCidades"
                    required :disabled="!isCep || mode === 'remove'" placeholder="Informe o uf ..." />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12" v-if="tipoRegistro == 3">
                <b-form-group label="Cidade" label-for="patrimonios-cidade">
                  <b-form-select id="patrimonios-cidade" v-model="itemData.loc_cidade" :options="citiesList_cidade"
                    required :disabled="!isCep || mode === 'remove'" placeholder="Informe a Cidade..." />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12" v-if="tipoRegistro == 3">
                <b-form-group label="Bairro" label-for="patrimonios-bairro">
                  <b-form-input id="patrimonios-bairro" type="text" v-model="itemData.loc_bairro"
                    :readonly="!isCep || mode === 'remove'" maxlength="128" placeholder="Informe o Bairro ..." />
                </b-form-group>
              </b-col>
              <b-col md="8" sm="12" v-if="tipoRegistro == 3">
                <b-form-group label="Logradouro" label-for="patrimonios-logradouro">
                  <b-form-input id="patrimonios-logradouro" ref="logradouro" type="text" v-model="itemData.loc_logradouro"
                    maxlength="100" required :readonly="!isCep || mode === 'remove'"
                    placeholder="Informe o Logradouro ..." />
                </b-form-group>
              </b-col>
              <b-col md="3" sm="12" v-if="tipoRegistro == 3">
                <b-form-group label="Nº do Logradouro" label-for="patrimonios-nr">
                  <b-form-input id="patrimonios-nr" ref="nr" type="text" v-model="itemData.loc_nr"
                    :readonly="!isCep || mode === 'remove'" maxlength="32" @keypress="isNumber($event)" required
                    placeholder="Informe o Número ..." />
                </b-form-group>
              </b-col>
              <b-col md="9" sm="12" v-if="tipoRegistro == 3">
                <b-form-group label="Complemento" label-for="patrimonios-complemento">
                  <b-form-input id="patrimonios-complemento" type="text" v-model="itemData.loc_complemento" maxlength="32"
                    placeholder="Informe o Complemento ..." />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12" v-if="tipoRegistro == 4">
                <b-form-group label="Tipo de Movimentação" label-for="patrimonios-mov_tipo">
                  <b-form-select id="patrimonios-mov_tipo" type="text" v-model="itemData.mov_tipo"
                    :options="optionsMovimentacaoBem" required placeholder="Informe o Tipo de Movimentação ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 4">
                <b-form-group label="Tipo de Incorporação" label-for="patrimonios-mov_tipo_inc">
                  <b-form-select id="patrimonios-mov_tipo_inc" type="text" v-model="itemData.mov_tipo_inc"
                    :options="optionsIncorporacao" required placeholder="Informe o Tipo de Incorporação ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 4">
                <b-form-group label="Conta Contábil" label-for="patrimonios-mov_conta">
                  <b-form-input id="patrimonios-mov_conta" v-model="itemData.mov_conta" maxlength="9"
                    @keypress="isNumber($event)" required placeholder="Informe Conta Contábil ..." />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" v-if="tipoRegistro == 4">
                <b-form-group label="Valor Movimentação" label-for="patrimonios-mov_valor">
                  <b-input-group prepend="R$">
                    <money v-model="itemData.mov_valor" v-bind="money" id="patrimonios-mov_valor"
                      class="valor-field-right form-control" required>
                    </money>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="clearfix">
              <b-button variant="primary" class="float-left" v-if="mode === 'save'" @click="save">
                {{ itemData.id ? "Editar" : "Salvar" }}
              </b-button>
              <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
                Excluir
              </b-button>
              <b-button class="ml-2" @click="reset"> Sair </b-button>
              <code v-if="itemData.hash" class="float-right">Patrimônio: {{ itemData.hash + itemData.tblName }}</code>
            </div>
          </b-form>
        </b-tab>
        <b-tab title="Dados Adicionais do Objeto" lazy
          v-if="alocacaoItemData.id || (!this.item.duplicate && itemData.id)">
          <PatrimonioAlocacaoData :item="alocacaoItemData" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError, setValidCep } from "@/global";
import moment from "moment"
import {
  optionsTipo,
  optionsNatureza,
  optionsUtilizacao,
  optionsCategoria,
  optionsInstrumento,
  optionsTipoComb,
  optionsMes,
  optionsMovimentacaoBem,
} from "@/config/lists";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { ufList } from "@/config/lists";
import { TheMask } from "vue-the-mask";
import { cnpj } from "cpf-cnpj-validator";
import { Money } from "v-money";
import PatrimonioCodeBarCapture from "./PatrimonioCodeBarCapture";
import PatrimonioAlocacaoData from "./PatrimonioAlocacaoData";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
// import { isMobile } from "mobile-device-detect";

export default {
  name: "PatrimonioData",
  props: ["item", "tipoRegistro"],
  components: {
    TheMask,
    PatrimonioCodeBarCapture,
    PatrimonioAlocacaoData,
    DatePicker,
    Money,
  },
  data: function () {
    return {
      userParams: {},
      minExercicio: moment().year() - 5,
      itemData: {
        exercicio: moment().year(),
        mes: moment().add(1, 'month').month(),
      },
      alocacaoItemData: {},
      mode: "save",
      isNumber: isNumber,
      labelsDatePicker: datePickerLocale,
      isCnpj: "",
      citiesList_cidade: [],
      listPUnidade: [],
      ufList: ufList,
      cepClass: undefined,
      isCep: undefined,
      tabIndex: 0,
      codeBarInCap: false,
      codeBarCapt: "",
      anoIsValid: "",
      listCadas: [],
      optionsTipo: optionsTipo,
      optionsNatureza: optionsNatureza,
      optionsUtilizacao: optionsUtilizacao,
      optionsCategoria: optionsCategoria,
      optionsInstrumento: optionsInstrumento,
      optionsTipoComb: optionsTipoComb,
      optionsMes: optionsMes,
      optionsMovimentacaoBem: optionsMovimentacaoBem,
      optionsIncorporacao: [
        { value: "1", text: "Incorporação" },
        { value: "2", text: "Desincorporação" },
      ],
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/patrimonio/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.loadAlocacaoData()
          const cep = setValidCep(this.itemData.loc_cep);
          this.cepClass = cep.cepClass;
          this.isCep = cep.isCep;
          if (this.item.duplicate == true) {
            delete this.itemData.id;
            this.codeBarInCap = true;
          }
        });
      } else {
        this.codeBarInCap = true;
        this.itemData = {
          exercicio: moment().year(),
          mes: moment().add(1, 'month').month(),
        }
      }
    },
    loadAlocacaoData() {
      const url = `${baseApiUrl}/patr-objetos/${this.item.id}`;
      axios.get(url).then((body) => {
        if (body.data) {
          this.alocacaoItemData = body.data;
          if (this.alocacaoItemData.situacao && !(this.alocacaoItemData.situacao <= 0))
            this.alocacaoItemData.situacao = 1;
        }
        this.alocacaoItemData.id_patrimonio = this.itemData.id;
        if (this.item.duplicate == 1) delete this.alocacaoItemData.id
      });
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      this.itemData.tipo_registro = this.tipoRegistro;
      if (this.itemData.cod) this.itemData.cod = this.itemData.cod.padStart(16, "0");
      axios[method](`${baseApiUrl}/patrimonio${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data
          if (this.item.id) {
            if (this.item.duplicate == true) {
              this.$confirm({
                message: `Gostaria de replicar também os dados adicionais deste registro?`,
                button: {
                  no: "Não",
                  yes: "Sim",
                },
                callback: (confirm) => {
                  if (confirm) {
                    setTimeout(() => {
                      let url = `${baseApiUrl}/patr-objetos/${this.item.id}`;
                      axios.get(url).then((bodyRes) => {
                        const body = { ...bodyRes.data };
                        delete body.id
                        this.alocacaoItemData = body
                        let url = `${baseApiUrl}/patr-objetos/${this.itemData.id}`;
                        axios
                          .post(url, body)
                          .then((res) => {
                            this.alocacaoItemData = res.data
                            setTimeout(() => {
                              this.tabIndex++;
                            }, 250);
                            // atualiza o grid patrimonios
                            this.$emit("objectInputs");
                          })
                          .catch((error) => {
                            showError(error);
                          });
                      });
                    }, 1500);
                  }
                },
              });
            }
          }
          this.loadAlocacaoData()
          // atualiza o grid patrimonios
          this.$emit("objectInputs");
          this.$toasted.global.defaultSuccess();
          if (method == "post" && !this.item.duplicate) {
            setTimeout(() => {
              this.tabIndex++;
            }, 250);
          }
        })
        .catch((error) => {
          showError(error);
        });
    },
    // afterSave(item) {
    //   this.itemData = item;
    //   delete this.itemData.duplicate;
    //   this.$confirm({
    //     message: `Gostaria de criar mais cópias deste registro?`,
    //     button: {
    //       no: "Não",
    //       yes: "Sim",
    //     },
    //     callback: (confirm) => {
    //       if (confirm) {
    //         this.$emit("keepReplicating", this.itemData);
    //       }
    //     },
    //   });
    // },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    setValidCnpj() {
      if (
        this.itemData.cnpj &&
        this.itemData.cnpj.length == 14 &&
        cnpj.isValid(this.itemData.cnpj)
      )
        this.isCnpj = "is-valid";
      else if (
        this.itemData.cnpj &&
        this.itemData.cnpj.length == 14 &&
        !cnpj.isValid(this.itemData.cnpj)
      )
        this.isCnpj = "is-invalid";
      else this.isCnpj = "";
    },
    getEnderecoApi() {
      const cep = setValidCep(this.itemData.loc_cep);
      this.cepClass = cep.cepClass;
      this.isCep = cep.isCep;
      if (this.isCep) {
        const urlCep = `https://viacep.com.br/ws/${this.itemData.loc_cep}/json/`
        axios.get(urlCep)
          .then((obj) => {
            this.cepData = obj.data;
            this.getListaCidades(this.cepData.uf);
          })
          .then(() => {
            if (this.cepData.cep) {
              this.itemData.loc_logradouro = this.cepData.logradouro;
              this.itemData.loc_complemento = this.cepData.complemento;
              this.itemData.loc_bairro = this.cepData.bairro;
              this.itemData.loc_uf = this.cepData.uf;
              this.itemData.loc_cidade = this.cepData.localidade.toUpperCase();
              if (this.cepData.logradouro) this.$refs.nr.focus();
              else this.$refs.logradouro.focus();
            }
          });
      }
    },
    getListaCidades(uf) {
      if (uf) {
        const url = `${baseApiUrl}/cidades/${uf}`;
        axios
          .get(url)
          .then((res) => {
            this.citiesList_cidade = res.data.data.map((data) => {
              return {
                value: data.municipio.toUpperCase(),
                text: data.municipio.toUpperCase(),
              };
            });
          })
          .catch((error) => {
            showError(error);
          });
      }
    },
    getListaUnidAutonomas() {
      const url = `${baseApiUrl}/und-autonomas`;
      axios
        .patch(url)
        .then((res) => {
          this.listPUnidade = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cnpj} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadas = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    setCodeBarCapt(value) {
      this.itemData.cod = value;
      this.codeBarInCap = false;
    },
    setMinAnoExercicio() {
      if (this.itemData.exercicio && this.itemData.exercicio.length > 0)
        this.anoIsValid =
          this.itemData.exercicio >= (new Date().getFullYear() - 5) ? "is-valid" : "is-invalid";
      else this.anoIsValid = "";
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaUnidAutonomas();
    this.getListaCadastros();
    if (this.itemData.uf) this.getListaCidades(this.itemData.uf);
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}

.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
