<template>
  <div id="user-params-form">
    <b-input-group>
      <b-form-select id="header-user-dominio" :options="dominios" @change="save" v-model="userParams.domainCli"
        v-if="dominios.length > 1" />
    </b-input-group>
  </div>
</template>
 
<script>
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { capitalizeFirst } from "@/config/globalFacilities";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "UserParamsForm",
  components: {},
  data: function () {
    return {
      clientes: [],
      dominios: [],
      optionYears: [],
      optionMonths: [],
      optionComplementaries: [],
      clientName: "",
      userParams: {},
      keyword: "",
    };
  },
  props: {
    title: String,
  },
  methods: {
    loadClientes() {
      if (this.userParams.multiCliente >= 1) {
        const param = {
          dominio: "root",
          meta: "clientName",
          forceDominio: true,
          order: "value",
        };
        axios.post(`${baseApiUrl}/params`, param).then((res) => {
          res.data.data.forEach((element) => {
            this.loadDominios(element.value);
          });
        });
      } else {
        this.loadDominios(this.userParams.cliente);
      }
    },
    loadDominios(cliente) {
      const param = {
        dominio: cliente,
        meta: "domainName",
        forceDominio: true,
      };
      // console.log(param);
      if (param.dominio)
        axios.post(`${baseApiUrl}/params`, param).then((res) => {
          res.data.data.map((data) => {
            this.dominios.push({
              value: `${data.dominio}_${data.value}`,
              text: capitalizeFirst(data.label.replace("_", " ")),
            });
          });
        });
    },
    save() {
      this.$cookies.set("userRoute", this.$route.fullPath);
      const url = `${baseApiUrl}/users/${this.userParams.id}`;
      const userTo = {
        dominio: this.userParams.domainCli.split("_")[1],
        cliente: this.userParams.domainCli.split("_")[0],
        email: this.userParams.email,
        cpf: this.userParams.cpf,
        name: this.userParams.name,
        telefone: this.userParams.telefone,
        f_ano: this.userParams.f_ano,
        f_mes: this.userParams.f_mes,
        f_complementar: this.userParams.f_complementar,
      };
      axios
        .put(url, userTo)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: "Parâmetro alterado com sucesso",
          });
          location.reload();
        })
        .catch((error) => {
          showError(error);
        });
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
        this.userParams.domainCli = `${this.userParams.cliente}_${this.userParams.dominio}`;
      });
    },
  },
  watch: {
    // user: function () {
    //   this.loadUserParams();
    // },
  },
  mounted() {
    this.loadUserParams();
    setTimeout(() => {
      this.loadClientes();
    }, 1500);
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.singlePayroll {
  background-color: #ffffffbf;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  width: auto;
  vertical-align: middle;
}

.input-group .input-group-25 {
  width: 25%;
}

.input-group .input-group-30 {
  width: 30%;
}

.input-group .input-group-40 {
  width: 40%;
}

.input-group .input-group-60 {
  width: 60%;
}

.input-group .input-group-70 {
  width: 70%;
}

.input-group .input-group-80 {
  width: 80%;
}

.input-group>.input-group-flex-10 {
  flex: 0 0 10%;
}

.input-group>.input-group-flex-15 {
  flex: 0 0 15%;
}

.input-group>.input-group-flex-20 {
  flex: 0 0 20%;
}

.input-group>.input-group-flex-25 {
  flex: 0 0 25%;
}

.input-group>.input-group-flex-30 {
  flex: 0 0 30%;
}

.input-group>.input-group-flex-40 {
  flex: 0 0 40%;
}</style>
