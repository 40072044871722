<template>
  <div class="adj_grupo-data">
    <hr />
    <h3 class="text-center">Dados da Adjudição</h3>
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="Situação" label-for="adj_grupos-adjudicado">
            <b-form-select
              id="adj_grupos-adjudicado"
              :options="optionsAdjudicacao"
              v-model="itemData.adjudicado"
              placeholder="Informe Situação da Adjudicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="10" sm="12">
          <b-form-group
            label="Descrição do Grupo"
            label-for="adj_grupos-desc_lote"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="adj_grupos-desc_lote"
              type="text"
              v-model="itemData.desc_lote"
              maxlength="255"
              required
              placeholder="Informe a Descrição do Grupo ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Adjudicação do Grupo: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";

export default {
  name: "AdjGrupoData",
  props: ["item"], // adj-proponente
  components: {},
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listLicitacao: [],
      listCadastros: [],
      optionsAdjudicacao: [],
    };
  },
  methods: {
    loadData() {
      const url = `${baseApiUrl}/adj-grupos/${this.item.id_pl_lic}/0?id_cadastro=${this.item.id_cad}`;
      axios.get(url).then((body) => {
        if (body.data) this.itemData = body.data;
        else this.itemData = {}
        this.optionsAdjudicacao = [
          {
            value: "1",
            text: "Adjudicado",
            disabled:
              this.itemData.isAdjudicado == "1" &&
              this.itemData.isAdjudicado != this.itemData.adjudicado,
          },
          { value: "2", text: "Anulado" },
          { value: "3", text: "Revogado" },
          { value: "4", text: "Fracassado" },
        ];
      });
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      const url = `${baseApiUrl}/adj-grupos/${this.item.id_pl_lic}${id}?id_cadastro=${this.item.id_cad}`;
      axios[method](url, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListPlanLicitacoes() {
      const url = `${baseApiUrl}/plan-licitacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listLicitacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadastros = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListPlanLicitacoes();
    this.getListCadastros();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
