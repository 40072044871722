<template>
  <StreamBarcodeReader
    @decode="onDecode"
    @error="onError"
  ></StreamBarcodeReader>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader-plus";
import { baseFrotendUrl } from "@/env";

export default {
  name: "PatrimonioCodBarCapture",
  components: { StreamBarcodeReader },
  data: function () {
    return {};
  },
  methods: {
    onDecode(result) {
      const urlS = `${baseFrotendUrl}/assets/SpeechOn.mp3`
      this.playSound(urlS)
      this.$emit("getCodeBarCapt", result);
    },
    onError(result) {
      console.log(result);
    },
    playSound (sound) {
      if(sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    }
  },
};
</script>

<style>
</style>