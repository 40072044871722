<template>
  <div class="editais-grid">
    <b-card no-body class="pdd">
      <b-input-group size="md" class="mt-3 mb-3">
        <b-button
          :variant="`outline-${lblBtnNewVariant}`"
          size="sm"
          slot="prepend"
          @click="newRow"
        >
          <i class="fa fa-plus"></i>&nbsp;
          <span>{{ lblBtnNew }}</span>
        </b-button>
        <b-form-input
          type="text"
          placeholder="Digite aqui para localizar o registro..."
          v-b-tooltip.hover
          title="Use espaços para pesquisar por mais de uma expressão"
          v-model="keyword"
          @input="searchEditais(1)"
          @blur="searchEditais(1)"
          ref="keyword"
        />
        <b-btn size="sm" @click="searchEditais(1)">
          <i class="fa fa-search"></i>
        </b-btn>
        <b-input-group-text slot="append">
          <span class>{{ keyword_res }}&nbsp;</span>
          <b-btn
            :disabled="!keyword"
            variant="link"
            size="sm"
            @click="getRefresh"
            class="p-0"
          >
            <i class="fa fa-remove"></i>
          </b-btn>
        </b-input-group-text>
      </b-input-group>
      <b-table hover striped responsive :items="editais" :fields="fields">
        <template v-slot:cell(nr_edital)="data" v-if="userParams.admin >= 1">
          <span v-html="`${data.item.nr_edital} (${data.item.id})`" />
        </template>
        <template v-slot:cell(nr_edital)="data" v-else>
          <span v-html="`${data.item.nr_edital}`" />
        </template>
        <template #cell(descricao_curta)="data">
          <span v-html="data.item.descricao_curta" />
        </template>
        <template #cell(actions)="row">
          <b-button
            v-if="userParams.id >= 1"
            variant="outline-info"
            size="sm"
            class="mr-1"
            v-b-tooltip.hover
            title="Editar registro"
            @click="showRow(row.item, row.index, $event.target)"
            href="#header"
          >
            <i class="fa fa-pencil"></i>
          </b-button>
          <b-button
            v-if="userParams.id >= 1"
            variant="outline-danger"
            size="sm"
            v-b-tooltip.hover
            title="Remover registro"
            @click="remove(row.item.id)"
          >
            <i class="fa fa-trash"></i>
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
      />
    </b-card>
    <b-modal
      size="xl"
      id="modal-form"
      :title="modalTitle"
      centered
      scrollable
      hide-footer
    >
      <EditaisData
        @objectInputs="loadEditais"
        :item="edital"
        :itemFiles="files"
        :userParams="userParams"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import { getDecimalFormater } from "@/config/globalFacilities";
import { showError, highlight, removeMark } from "@/global";
import EditaisData from "./EditaisData";

export default {
  name: "EditaisGrid",
  components: { EditaisData },
  data: function () {
    return {
      userParams: {},
      editais: [], // table
      edital: {}, // field
      files: [], // arquivos
      editalInputs: {}, // form data
      loading: false,
      modalTitle: "Novo Registro",
      lblBtnNew: "Novo Registro",
      lblBtnNewVariant: "primary",
      mode: "save",
      keyword: "",
      keyword_res: "",
      lblCancelar: "Cancelar",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        { key: "nr_edital", label: "Nº Edital", sortable: true },
        { key: "descricao_curta", label: "Descrição", sortable: true },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions2",
          tdClass: "col-actions2",
        },
      ],
    };
  },
  methods: {
    searchEditais(length) {
      if (
        this.keyword.toString().length >= length ||
        this.keyword.toString().length == 0
      )
        this.loadEditais();
    },
    loadEditais() {
      const url = `${baseApiUrl}/editais?page=${this.page}&key=${this.keyword}`;
      axios.get(url).then((res) => {
        this.editais = res.data.data;
        this.editais.forEach((element) => {
          element.descricao_curta = highlight(
            element.descricao_curta,
            this.keyword.toString().toUpperCase()
          );
          element.nr_edital = highlight(
            element.nr_edital,
            this.keyword.toString()
          );
        });
        this.count = res.data.count;
        this.limit = res.data.limit;
        const pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${pluralize}`;
        // if (!this.form_input_hide) this.showRow(this.edital);
      });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.loadEditais();
    },
    reset() {
      this.keyword = "";
      this.mode = "save";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.getRefresh();
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    newRow() {
      this.editalInputs = {};
      this.$root.$emit("bv::show::modal", "modal-form");
    },
    showRow(item, index, button) {
      Object.entries(item).forEach(([key, value]) => {
        if (item)
          this.edital = {
            ...this.edital,
            [key]: removeMark(value || ""),
          };
      });
      this.modalTitle = `Registro: ${this.edital.nr_edital}`;
      this.$root.$emit("bv::show::modal", "modal-form", button);
    },
    remove(id) {
      const url = `${baseApiUrl}/editais/${id}`;
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.editalInputs = {};
                this.loadEditais();
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
  },
  mounted() {
    this.loadUserParams();
    this.loadEditais();
  },
  watch: {
    page() {
      this.loadEditais();
    },
  },
  computed: mapState(["user"]),
};
</script>

<style>
.pdd {
  padding: 10px;
}
</style>