<template>
  <div class="siap-panel">
    <PageTitle icon="far fa-edit" main="Compras Públicas" sub="Convênio" />
    <div class="siap-panel-tabs">
      <b-card no-body id="siap-panel">
        <b-tabs card>
          <b-tab title="Convênios" lazy>
            <ConveniosGrid ref="ConveniosGrid" />
          </b-tab>
          <b-tab title="Situação" lazy>
            <ConvSituacoesGrid ref="ConvSituacoesGrid" />
          </b-tab>
          <b-tab title="Aditivos" lazy>
            <ConvAditivosGrid ref="ConvAditivosGrid" />
          </b-tab>
          <!-- <b-tab title="Cancelamentos" lazy>
            <ConvCancelsGrid ref="ConvCancelsGrid" />
          </b-tab> -->
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConveniosGrid from "../convConveniosAdmin/ConveniosGrid";
import ConvSituacoesGrid from "../convSituacoesAdmin/ConvSituacoesGrid";
import ConvAditivosGrid from "../convAditivosAdmin/ConvAditivosGrid";
//import ConvCancelsGrid from "../convCancelsAdmin/ConvCancelsGrid";
import PageTitle from "../template/PageTitle";

export default {
  name: "ConvAdminPanels",
  components: {
    PageTitle,
    ConveniosGrid,
    ConvSituacoesGrid,
    ConvAditivosGrid,
    //ConvCancelsGrid
  },
  data: function () {
    return {};
  },
  methods: {},

  mounted() {},

  computed: mapState(["user"]),
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>