/**
 * Cria um array das unidades da federação
 * O objetivo é ser usado em form-selects
 */
export const ufList = [
    { value: "null", text: "Selecione" },
    { value: "AC", text: "ACRE" },
    { value: "AL", text: "ALAGOAS" },
    { value: "AP", text: "AMAPÁ" },
    { value: "AM", text: "AMAZONAS" },
    { value: "BA", text: "BAHIA" },
    { value: "CE", text: "CEARÁ" },
    { value: "DF", text: "DISTRITO FEDERAL" },
    { value: "ES", text: "ESPÍRITO SANTO" },
    { value: "GO", text: "GOIÁS" },
    { value: "MA", text: "MARANHÃO" },
    { value: "MT", text: "MATO GROSSO" },
    { value: "MS", text: "MATO GROSSO DO SUL" },
    { value: "MG", text: "MINAS GERAIS" },
    { value: "PA", text: "PARÁ" },
    { value: "PB", text: "PARAÍBA" },
    { value: "PR", text: "PARANÁ" },
    { value: "PE", text: "PERNAMBUCO" },
    { value: "PI", text: "PIAUÍ" },
    { value: "RJ", text: "RIO DE JANEIRO" },
    { value: "RN", text: "RIO GRANDE DO NORTE" },
    { value: "RS", text: "RIO GRANDE DO SUL" },
    { value: "RO", text: "RONDÔNIA" },
    { value: "RR", text: "RORAIMA" },
    { value: "SC", text: "SANTA CATARINA" },
    { value: "SP", text: "SÃO PAULO" },
    { value: "SE", text: "SERGIPE" },
    { value: "TO", text: "TOCANTINS" }
]

export const optionsConservacaoObj = [
    { value: "1", text: "Novo - Refere-se ao bem comprado e que se encontra com menos de um ano de uso" },
    { value: "2", text: "Bom - Quando estiver em perfeitas condições e em uso normal" },
    { value: "3", text: "Ocioso - Quando, embora em perfeitas condições de uso, não estiver sendo aproveitado" },
    { value: "4", text: "Recuperável - Quando sua recuperação for possível e no máximo a 50% de seu valor de mercado" },
    { value: "5", text: "Antieconômico - Quando sua manutenção for onerosa ou seu rendimento precário" },
    { value: "6", text: "Irrecuperável - Quando não puder mais ser utilizado para o fim a que se destina" }
]

export const optionsSituacaoObj = [
    { value: "1", text: "Normal - Quando em uso normal" }
]

export const optionsTipo = [
    { value: "1", text: "Bem Próprio" },
    { value: "2", text: "Bem de Terceiro" },
]

export const optionsNatureza = [
    { value: "1", text: "Bens moveis" },
    { value: "2", text: "Bens Imóveis" },
    { value: "3", text: "Intangíveis" },
]

export const optionsUtilizacao = [
    { value: "1", text: "Dominial" },
    { value: "2", text: "De Uso Publico" },
    { value: "3", text: "De Uso Especial" },
]

export const optionsCategoria = [
    { value: 32, text: "Acessórios para Automóveis" },
    { value: 1, text: "Aeronaves" },
    { value: 2, text: "Aparelhos de Medição e Orientação" },
    { value: 3, text: "Aparelhos e Equipamentos de Comunicação" },
    { value: 5, text: "Aparelhos e Equipamentos para Esportes e Diversões" },
    { value: 6, text: "Aparelhos e Utensílios Domésticos" },
    { value: 4, text: "Aparelhos, Equipamentos, Utensílios Médico-Odontológico, Laboratorial e Hospitalar" },
    { value: 7, text: "Armamentos" },
    { value: 8, text: "Coleções e Materiais Bibliográficos" },
    { value: 9, text: "Discotecas e Filmotecas" },
    { value: 39, text: "Edificações" },
    { value: 10, text: "Embarcações" },
    { value: 11, text: "Equipamentos de Manobra e Patrulhamento" },
    { value: 33, text: "Equipamentos de Mergulho e Salvamento" },
    { value: 19, text: "Equipamentos de Processamento de Dados" },
    { value: 12, text: "Equipamentos de Proteção, Segurança e Socorro" },
    { value: 35, text: "Equipamentos e Sistema de Proteção e Vigilância Ambiental" },
    { value: 22, text: "Equipamentos e Utensílios Hidráulicos e Elétricos" },
    { value: 31, text: "Equipamentos, Peças e Acessórios de Proteção e Voo" },
    { value: 34, text: "Equipamentos, Peças e Acessórios Marítimos" },
    { value: 36, text: "Equipamentos, sobressalentes de máquinas, motor de navios e esquadra" },
    { value: 46, text: "Instalações" },
    { value: 13, text: "Instrumentos Musicais e Artísticos" },
    { value: 23, text: "Máquinas e Equipamentos Agrícolas e Rodoviários" },
    { value: 14, text: "Máquinas e Equipamentos de Natureza Industrial" },
    { value: 15, text: "Máquinas e Equipamentos Energéticos" },
    { value: 16, text: "Máquinas e Equipamentos Gráficos" },
    { value: 17, text: "Máquinas para Áudio, Vídeo e Foto" },
    { value: 21, text: "Máquinas, Ferramentas e Utensílios de Oficina" },
    { value: 20, text: "Máquinas, Instalações e Utensílios de Escritório" },
    { value: 41, text: "Marcas, Direitos e Patentes" },
    { value: 24, text: "Mobiliário em Geral" },
    { value: 25, text: "Obras de Arte e Peças para Mus" },
    { value: 18, text: "Outras Máquinas, Aparelhos, Equipamentos e Ferramentas" },
    { value: 45, text: "Outros Bens de Uso Comum do Povo" },
    { value: 47, text: "Outros Bens de Uso Especial" },
    { value: 48, text: "Outros Bens Dominicais" },
    { value: 37, text: "Outros Materiais Permanentes" },
    { value: 29, text: "Peças não incorporáveis a imóveis" },
    { value: 44, text: "Pontes e Viadutos" },
    { value: 42, text: "Praças, Parques e Bosques" },
    { value: 43, text: "Ruas, Logradouros e Estradas" },
    { value: 26, text: "Semoventes e Equipamentos de Montaria" },
    { value: 40, text: "Softwares" },
    { value: 38, text: "Terrenos" },
    { value: 30, text: "Veículos de Tração Mecânica" },
    { value: 27, text: "Veículos Diversos" },
    { value: 28, text: "Veículos Ferroviários" },
]

export const optionsMovimentacaoBem = [
    { value: "1", text: "Alienação de Bens Móveis" },
    { value: "2", text: "Aquisição" },
    { value: "3", text: "Construção" },
    { value: "4", text: "Desapropriação" },
    { value: "5", text: "Inservibilidade" },
    { value: "6", text: "Outras incorporações" },
    { value: "7", text: "Sinistro" },
    { value: "8", text: "Incorporação por transferência" },
    { value: "9", text: "Desincorporação por transferência" },
    { value: "10", text: "Outras desincorporações" },
    { value: "11", text: "Dação em Pagamento — baixa" },
    { value: "12", text: "Dação em Pagamento -incorporação" },
    { value: "13", text: "Doação — baixa" },
    { value: "14", text: "Doação — incorporação" },
    { value: "15", text: "Permuta — baixa" },
    { value: "16", text: "Permuta — incorporação" },
    { value: "17", text: "Reavaliação" },
    { value: "18", text: "Depreciação Acumulada de Bens Móveis" },
    { value: "19", text: "Alienação – Programas Municipais" },
    { value: "20", text: "Alienação – Investidura" },
    { value: "21", text: "Alienação de bens imóveis por permuta" },
    { value: "22", text: "Depreciação Acumulada de Bens de Uso Comum do Povo" },
    { value: "23", text: "Depreciação Acumulada de Bens de Uso Especial" },
    { value: "24", text: "Depreciação Acumulada de Bens Dominicais" },
    { value: "25", text: "Exaustão Acumulada" },
    { value: "26", text: "Amortização Acumulada" },
    { value: "27", text: "Redução ao Valor Recuperável de Bens Dominicais" },
    { value: "28", text: "Redução ao Valor Recuperável de Bens Imóveis de Uso Especial" },
    { value: "29", text: "Amortização Acumulada de Softwares" },
    { value: "30", text: "Amortização Acumulada de Marcas, Direitos e Patentes" },
    { value: "31", text: "Redução ao Valor Recuperável de Software" },
    { value: "32", text: "Redução ao Valor Recuperável de Marcas, Direitos e Patentes" },
    { value: "33", text: "Alienação de Bens Imóveis" },
    { value: "34", text: "Baixa por Cisão, Fusão ou Extinção" },
    { value: "35", text: "Incorporação por Cisão, Fusão ou Extinção" },
    { value: "36", text: "Baixa de Bens de Terceiros" },
    { value: "37", text: "Incorporação da Depreciação, Amortização e Exaustão Acumuladas de Bens de Cisão, Fusão ou Extinção" },
    { value: "38", text: "Apuração do Valor Líquido do Bem (na conta devedora)" },
    { value: "39", text: "Apuração do Valor Líquido do Bem — (NA CONTA CREDORA)" },
]

export const optionsInstrumento = [
    { value: "1", text: "Aforamento" },
    { value: "2", text: "Alienação" },
    { value: "3", text: "Autorização de Uso" },
    { value: "4", text: "Cessão de Uso Gratuita" },
    { value: "5", text: "Cessão de uso Onerosa" },
    { value: "6", text: "Cessão em Condições Especiais" },
    { value: "7", text: "Cessão provisória" },
    { value: "8", text: "Concessão de Direito Real de Uso — CDRU" },
    { value: "9", text: "Concessão de uso Especial para fins de Moradia — CUEM" },
    { value: "10", text: "Declaração de Interesse do Serviço Publico" },
    { value: "11", text: "Entrega" },
    { value: "12", text: "Entrega Provisória" },
    { value: "13", text: "Guarda Provisória" },
    { value: "14", text: "Inscrição de Ocupação" },
    { value: "15", text: "Permissão de Uso" },
    { value: "16", text: "Termo de Autorização de Uso Sustentável" },
    { value: "17", text: "Transferência (gratuita)" },
]

export const optionsTipoComb = [
    { value: "1", text: "Gasolina" },
    { value: "2", text: "Etanol" },
    { value: "3", text: "Diesel" },
    { value: "4", text: "Biodiesel" },
    { value: "5", text: "GNV" },
    { value: "6", text: "Querosene" },
    { value: "7", text: "Outros combustíveis" },
    { value: "8", text: "Flex ou assemelhados" },
]

export const optionsModalidadeReg = [
    { value: "1", text: "Concorrência" },
    { value: "2", text: "Pregão" },
]

export const optionsCriterioJulgLic = [
    { value: "1", text: "Menor Preço" },
    { value: "2", text: "Melhor Técnica" },
    { value: "3", text: "Técnica e Preço (Combinação de Técnica e Preço)" },
    { value: "4", text: "Menor preço por lote" },
    { value: "5", text: "Maior desconto" },
    { value: "6", text: "Maior lance ou oferta, no caso de leilão" },
    { value: "7", text: "Maior retorno econômico" },
    { value: "8", text: "Melhor conteúdo artístico" },
    { value: "9", text: "Melhor destinação de bens alienados" },
]

export const optionsNatObjCont = [
    { value: "1", text: "Obras" },
    { value: "2", text: "Serviços de engenharia" },
    { value: "3", text: "Compras" },
    { value: "4", text: "Serviços (exceto engenharia)" },
    { value: "5", text: "Locação de Imóveis" },
    { value: "6", text: "Alienação de Bens" },
]

export const optionsNatObjContEx = [
    { value: "1", text: "Obras e Serviços de Engenharia" },
    { value: "2", text: "Compras e outros serviços" },
    { value: "3", text: "Locação de Imóveis" },
    { value: "4", text: "Concessão" },
    { value: "5", text: "Permissão" },
]

export const optionSimNao = [
    { value: "1", text: "SIM" },
    { value: "2", text: "NÃO" }
]

export const optionsRegimeExeObra = [
    { value: "1", text: "Empreitada por preço global" },
    { value: "2", text: "Empreitada por preço unitário" },
    { value: "3", text: "Empreitada Integral" },
    { value: "4", text: "Tarefa" },
    { value: "5", text: "Execução Direta" },
]

export const optionsNaturezaObra = [
    { value: "1", text: "Reforma" },
    { value: "2", text: "Construção" },
    { value: "3", text: "Ampliação" },
    { value: "4", text: "Fabricação" },
    { value: "5", text: "Recuperação" },
    { value: "6", text: "Construção e Reforma" },
    { value: "7", text: "Serviços Técnicos Especializados" },
]

export const optionsTipoGarantia = [
    { value: "1", text: "Caução em dinheiro ou em títulos da dívida pública" },
    { value: "2", text: "Seguro-garantia" },
    { value: "3", text: "Fiança Bancária" },
]

//Tabela 01 – Veículos de Publicação
export const optionsVeiculosPub = [
    { value: "1", text: "Diário Oficial da União" },
    { value: "2", text: "Diário Oficial do Estado de Alagoas" },
    { value: "3", text: "Diário Oficial do Tribunal de Contas de Alagoas" },
    { value: "4", text: "Diário Oficial da Associação de Municípios de Alagoas" },
    { value: "5", text: "Diário Oficial Próprio" },
    { value: "6", text: "Site do Órgão Gerenciador" },
]

export const optionsEnquadramento = [
    { value: "1", text: "Dispensa de licitação" },
    { value: "2", text: "Inexigibilidade de licitação" },
]

export const optionsEnquadAdesao = [
    { value: "1", text: "Órgão Participante" },
    { value: "2", text: "Órgão Adeso" },
]

export const optionsFormaPregao = [
    { value: "1", text: "Pregão Presencial" },
    { value: "2", text: "Pregão Eletrônico" },
]

export const optionsEsfera = [
    { value: "F", text: "Federal" },
    { value: "E", text: "Estadual" },
    { value: "M", text: "Municipal" },
]

export const optionsPoder = [
    { value: "E", text: "Executivo" },
    { value: "L", text: "Legislativo" },
    { value: "J", text: "Judiciário" },
]

export const optionsAdjudicacao = [
    { value: "1", text: "Adjudicado" },
    { value: "2", text: "Anulado" },
    { value: "3", text: "Revogado" },
    { value: "4", text: "Fracassado" },
]

export const optionsTipoPessoa = [
    { value: "1", text: "Pessoa Física" },
    { value: "2", text: "Pessoa Jurídica" },
    { value: "3", text: "Outros" },
]

export const optionsTipoContrato = [
    { value: "1", text: "Termo de Contrato" },
    { value: "2", text: "Termo de Aditivo ao Contrato" },
    { value: "3", text: "Termo de de Re-Ratificação de Contrato" },
    { value: "4", text: "Termo de Distrato de Contrato" },
    { value: "5", text: "Termo de Rescisão de Contrato" },
    { value: "6", text: "Termo Concessão de Uso" },
    { value: "8", text: "Termo de Permissão de Uso" },
    { value: "10", text: "Termo de Autorização de Uso" },
    { value: "12", text: "Termo de Cessão" },
    { value: "14", text: "Termo de Compromisso" },
    { value: "16", text: "Termo de Direito Real de Uso" },
    { value: "18", text: "Termo de Direito Doação" },
    { value: "19", text: "Carta Contrato" },
    { value: "20", text: "Ordem de Serviços" },
    { value: "22", text: "Termo de Revogação de Autorização de Uso" },
    { value: "24", text: "Termo de Outorga" },
    { value: "26", text: "Termo de Ex-Ofício" },
    { value: "28", text: "Termo de Cooperação Técnica" },
    { value: "30", text: "Termo de Ordem de Serviços" },
    { value: "35", text: "Protocolo de Intenções" },
    { value: "38", text: "Apostila de Retificação de Contrato" },
    { value: "39", text: "Termo de Contrato de Gestão" },
    { value: "41", text: "Termo de Rescisão de Cessão" },
]

export const optionsProcessoCont = [
    { value: "1", text: "Licitação" },
    { value: "2", text: "Dispensa ou Inexigibilidade" },
    { value: "3", text: "Ata de Registro de Preço - Gerenciador" },
    { value: "4", text: "Participação em Ata de Registro de preços" },
    { value: "5", text: "Adesão à ata de registro de preços" },
]

export const optionsTipoAditivo = [
    { value: "1", text: "Aditivo de prazo" },
    { value: "2", text: "Aditivo de valor unitário" },
    { value: "3", text: "Aditivo de prazo e valor unitário" },
    { value: "4", text: "Outros" },
]

export const optionsTipoAditivos = [
    { value: "1", text: "Aditivo de quantidade" },
    { value: "2", text: "Aditivo de prazo" },
    { value: "3", text: "Aditivo de valor unitário" },
    { value: "4", text: "Aditivo de prazo e valor unitário" },
    { value: "5", text: "Aditivo de prazo e quantidade" },
    { value: "6", text: "Aditivo de valor unitário e quantidade" },
    { value: "7", text: "Outros" },
]

export const optionsTipoAlteracao = [
    { value: "1", text: "Acréscimo de valor" },
    { value: "2", text: "Decréscimo de valor" },
]

export const optionsTipoAltValor = [
    { value: "1", text: "Acréscimo de valor" },
    { value: "2", text: "Decréscimo de valor" },
    { value: "3", text: "Sem alteração de valor" },
]

export const optionsResponsavel = [
    { value: "1", text: "Concedente" },
    { value: "2", text: "Convenente" },
    { value: "3", text: "Rendimento de Aplicação" },
]

export const optionsMes = [
    { value: 1, text: "Janeiro" },
    { value: 2, text: "Fevereiro" },
    { value: 3, text: "Março" },
    { value: 4, text: "Abril" },
    { value: 5, text: "Maio" },
    { value: 6, text: "Junho" },
    { value: 7, text: "Julho" },
    { value: 8, text: "Agosto" },
    { value: 9, text: "Setembro" },
    { value: 10, text: "Outubro" },
    { value: 11, text: "Novembro" },
    { value: 12, text: "Dezembro" },
]

export const optionsAno = [
    { value: "2010", text: "2010" },
    { value: "2011", text: "2011" },
    { value: "2012", text: "2012" },
    { value: "2013", text: "2013" },
    { value: "2014", text: "2014" },
    { value: "2015", text: "2015" },
    { value: "2016", text: "2016" },
    { value: "2017", text: "2017" },
    { value: "2018", text: "2018" },
    { value: "2019", text: "2019" },
    { value: "2020", text: "2020" },
    { value: "2021", text: "2021" },
    { value: "2022", text: "2022" },
]

export const optionsModalidadeConv = [
    { value: "1", text: "Contrato de Repasse" },
    { value: "2", text: "Convenio" },
    { value: "3", text: "Termo de Colaboração" },
    { value: "4", text: "Termo de Fomento" },
    { value: "5", text: "Termo de Parceria" },
    { value: "6", text: "Acordo de Cooperação" },
    { value: "7", text: "Acordo de Parceria" },
    { value: "8", text: "Termo de Cooperação" },
    { value: "9", text: "Protocolo de Intenções" },
]

export const optionsSituacaoConv = [
    { value: "1", text: "Em andamento" },
    { value: "2", text: "Cancelado" },
    { value: "3", text: "Paralisado" },
    { value: "4", text: "Execução antecipada com Recursos Próprios" },
    { value: "5", text: "Conclusão com Recursos Próprios" },
    { value: "6", text: "Concluídos com Recursos do Concedente" },
]

export const optionsTipoAditConv = [
    { value: "1", text: "Aditivo de prazo" },
    { value: "2", text: "Aditivo de valor" },
    { value: "3", text: "Aditivo de prazo e valor" },
    { value: "4", text: "Outros" },
]

export const optionsTipoObra = [
    { value: "1", text: "Adutora" },
    { value: "2", text: "Aeroporto" },
    { value: "3", text: "Aterro Sanitário" },
    { value: "4", text: "Balança Rodoviária" },
    { value: "5", text: "Barragem" },
    { value: "6", text: "Biblioteca" },
    { value: "7", text: "Canal" },
    { value: "8", text: "Creche" },
    { value: "9", text: "Delegacia de Policia" },
    { value: "10", text: "Drenagem Urbana" },
    { value: "11", text: "Escola" },
    { value: "12", text: "Estação Elevatória" },
    { value: "13", text: "Hospital" },
    { value: "14", text: "Limpeza Pública" },
    { value: "15", text: "Linha de Distribuição de Energia Elétrica Rural" },
    { value: "16", text: "Linha de Distribuição de Energia Elétrica Urbana" },
    { value: "17", text: "Módulo Sanitário" },
    { value: "18", text: "Muro de Contenção de Aterros" },
    { value: "19", text: "Obra de Arte Corrente" },
    { value: "20", text: "Obra de Arte Especial" },
    { value: "21", text: "Passarela" },
    { value: "22", text: "Perfuração de Poço Tubular" },
    { value: "23", text: "Posto Fiscal" },
    { value: "24", text: "Praça" },
    { value: "25", text: "Praça de Pedágio" },
    { value: "26", text: "Rede de Coleta de Esgoto" },
    { value: "27", text: "Rede de Distribuição de Água" },
    { value: "28", text: "Rede de Distribuição de Energia Elétrica" },
    { value: "29", text: "Rodovia não Pavimentada" },
    { value: "30", text: "Rodovia Pavimentada" },
    { value: "31", text: "Sinalização Viária" },
    { value: "32", text: "Subestação de Energia Elétrica" },
    { value: "33", text: "Terminal Rodoviário" },
    { value: "34", text: "Unidade Administrativa" },
    { value: "35", text: "Unidade Desportiva" },
    { value: "36", text: "Unidade de Saúde" },
    { value: "37", text: "Unidade Habitacional" },
    { value: "38", text: "Unidade Prisional" },
    { value: "39", text: "Via Urbana não Pavimentada" },
    { value: "40", text: "Via Urbana Pavimentada" },
    { value: "41", text: "Outros" },
    { value: "42", text: "Parque Aquático" },
    { value: "43", text: "Instituto Médico Legal" },
    { value: "44", text: "Estadio" },
    { value: "45", text: "Via Urbana a ser Pavimentada" },
    { value: "46", text: "Centro Cirúrgico" },
    { value: "47", text: "Quadra de Esporte" },
    { value: "48", text: "Cobertura" },
    { value: "49", text: "Sanitários Públicos" },
    { value: "50", text: "Salão de Idosos" },
    { value: "51", text: "Prédio Público" },
    { value: "52", text: "Cobertura de Quadra Esportiva" },
    { value: "53", text: "Centro Cultural de Convivência" },
    { value: "54", text: "Esgotamento Sanitário" },
    { value: "55", text: "Reposição Asfáltica" },
    { value: "56", text: "Rede Coletora de Esgoto" },
    { value: "57", text: "Estacão de Tratamento de Água" },
    { value: "58", text: "Estrada Vicinal não Pavimentada" },
    { value: "59", text: "Posto de Saúde" },
    { value: "60", text: "Casas Populares" },
    { value: "61", text: "Policlínica - Clínica" },
    { value: "62", text: "Estrada Vicinal Pavimentada" },
    { value: "63", text: "Drenagem de Águas Pluviais" },
    { value: "64", text: "Ruas e Avenidas" },
    { value: "65", text: "Iluminação Pública" },
]

export const optionsTipoServ = [
    { value: "01", text: "Ampliação" },
    { value: "02", text: "Construção Nova" },
    { value: "03", text: "Manutenção" },
    { value: "04", text: "Readequação" },
    { value: "05", text: "Reforma" },
    { value: "06", text: "Restauração" },
    { value: "07", text: "Pavimentação Asfáltica" },
    { value: "08", text: "Reforma e Ampliação" },
    { value: "09", text: "Conservação" },
    { value: "10", text: "Reconstrução" },
    { value: "11", text: "Supervisão, Acompanhamento e Controle de Obras" },
    { value: "12", text: "Pavimentação" },
    { value: "13", text: "Recuperação" },
    { value: "14", text: "Fabricação" },
    { value: "15", text: "Serviços Técnicos Especializados" },
    { value: "16", text: "Outros" },
]

export const optionsSetorBenef = [
    { value: "01", text: "Cultura" },
    { value: "02", text: "Educação" },
    { value: "03", text: "Esporte" },
    { value: "04", text: "Infra-estrutura e Transporte" },
    { value: "05", text: "Meio Ambiente, Recursos Hídricos e Saneamento" },
    { value: "06", text: "Saúde" },
    { value: "07", text: "Segurança Pública" },
    { value: "08", text: "Turismo" },
    { value: "09", text: "Urbanização e Habitação" },
    { value: "10", text: "Ministério Público" },
    { value: "11", text: "Administração Central" },
    { value: "12", text: "Ação Social" },
    { value: "13", text: "Justiça" },
    { value: "14", text: "Assistência Social" },
    { value: "15", text: "Limpeza Pública" },
    { value: "16", text: "Agricultura" },
    { value: "17", text: "Comunicação" },
    { value: "18", text: "Energia" },
]

export const optionsSituacaoObra = [
    { value: "1", text: "Ativa, na hipótese de obra regular em pleno desenvolvimento da atividade de construção civil" },
    { value: "2", text: "Atrasada" },
    { value: "3", text: "Paralisada, quando informada a interrupção temporária da atividade pela contratada" },
    { value: "4", text: "Suspensa, por ato de ofício" },
    { value: "5", text: "Encerrada, quando a obra for regularizada" },
]

export const optionsEtapas = [
    { value: "1", text: "Projeto" },
    { value: "2", text: "Execução" },
    { value: "3", text: "Fiscalização" },
]

export const optionsTipoVinc = [
    { value: "1", text: "Servidor Efetivo" },
    { value: "2", text: "Servidor Contratado" },
]

export const optionsTipoLicenca = [
    { value: "1", text: "Licença Prévia" },
    { value: "2", text: "Licença de Implantação" },
    { value: "3", text: "Licença de Operação" },
    { value: "4", text: "Autorização Ambiental" },
]
// Tabela 09 - Referência Legal para Dispensa de Licitação ou Inexigibilidade
export const optionsRefLegal = [
    { value: "1", text: "Dispensável, art. 24, inciso III, da Lei 8.666/93" },
    { value: "2", text: "Dispensável, art. 24, inciso IV, da Lei 8.666/93" },
    { value: "3", text: "Dispensável, art. 24, inciso V, da Lei 8.666/93" },
    { value: "4", text: "Dispensável, art. 24, inciso VI, da Lei 8.666/93" },
    { value: "5", text: "Dispensável, art. 24, inciso VII, da Lei 8.666/93" },
    { value: "6", text: "Dispensável, art. 24, inciso VIII, da Lei 8.666/93" },
    { value: "7", text: "Dispensável, art. 24, inciso IX, da Lei 8.666/93" },
    { value: "8", text: "Dispensável, art. 24, inciso X, da Lei 8.666/93" },
    { value: "9", text: "Dispensável, art. 24, inciso XI, da Lei 8.666/93" },
    { value: "10", text: "Dispensável, art. 24, inciso XII, da Lei 8.666/93" },
    { value: "11", text: "Dispensável, art. 24, inciso XIII, da Lei 8.666/93" },
    { value: "12", text: "Dispensável, art. 24, inciso XIV, da Lei 8.666/93" },
    { value: "13", text: "Dispensável, art. 24, inciso XV, da Lei 8.666/93" },
    { value: "14", text: "Dispensável, art. 24, inciso XVI, da Lei 8.666/93" },
    { value: "15", text: "Dispensável, art. 24, inciso XVII, da Lei 8.666/93" },
    { value: "16", text: "Dispensável, art. 24, inciso XVIII, da Lei 8.666/93" },
    { value: "17", text: "Dispensável, art. 24, inciso XIX, da Lei 8.666/93" },
    { value: "18", text: "Dispensável, art. 24, inciso XX, da Lei 8.666/93" },
    { value: "19", text: "Dispensável, art. 24, inciso XXI, da Lei 8.666/93" },
    { value: "20", text: "Dispensável, art. 24, inciso XXII, da Lei 8.666/93" },
    { value: "21", text: "Dispensável, art. 24, inciso XXIII, da Lei 8.666/93" },
    { value: "22", text: "Dispensável, art. 24, inciso XXIV, da Lei 8.666/93" },
    { value: "23", text: "Dispensável, art. 24, inciso XXV, da Lei 8.666/93" },
    { value: "24", text: "Dispensável, art. 24, inciso XXVI, da Lei 8.666/93" },
    { value: "25", text: "Dispensável, art. 24, inciso XXVII, da Lei 8.666/93" },
    { value: "26", text: "Dispensável, art. 24, inciso XXVIII, da Lei 8.666/93" },
    { value: "27", text: "Dispensável, art. 24, inciso XXIX, da Lei 8.666/93" },
    { value: "28", text: "Dispensável, art. 24, inciso XXX, da Lei 8.666/93" },
    { value: "29", text: "Dispensável, art. 24, inciso XXXI, da Lei 8.666/93" },
    { value: "30", text: "Dispensável, art. 24, inciso XXXII, da Lei 8.666/93" },
    { value: "31", text: "Dispensável, art. 24, inciso XXXIII, da Lei 8.666/93" },
    { value: "32", text: "Dispensável, art. 24, inciso XXXIV, da Lei 8.666/93" },
    { value: "33", text: "Dispensável, art. 24, inciso XXXV, da Lei 8.666/93" },
    { value: "34", text: "Dispensável, art. 17, parágrafo 2, da Lei 8.666/93 – alienação" },
    { value: "35", text: "Dispensável, art. 17, parágrafo 4, da Lei 8.666/93 – alienação" },
    { value: "36", text: "Inexigível, art. 25, caput, da Lei 8.666/93" },
    { value: "37", text: "Inexigível, art. 25, inciso I, da Lei 8.666/93" },
    { value: "38", text: "Inexigível, art. 25, inciso II, da Lei 8.666/93" },
    { value: "39", text: "Inexigível, art. 25, inciso III, da Lei 8.666/93" },
    { value: "40", text: "Dispensável, art. 29, inciso I, da Lei 13.303/2016" },
    { value: "41", text: "Dispensável, art. 29, inciso II, da Lei 13.303/2016" },
    { value: "42", text: "Dispensável, art. 29, inciso III, da Lei 13.303/2016" },
    { value: "43", text: "Dispensável, art. 29, inciso IV, da Lei 13.303/2016" },
    { value: "44", text: "Dispensável, art. 29, inciso V, da Lei 13.303/2016" },
    { value: "45", text: "Dispensável, art. 29, inciso VI, da Lei 13.303/2016" },
    { value: "46", text: "Dispensável, art. 29, inciso VII, da Lei 13.303/2016" },
    { value: "47", text: "Dispensável, art. 29, inciso VIII, da Lei 13.303/2016" },
    { value: "48", text: "Dispensável, art. 29, inciso IX, da Lei 13.303/2016" },
    { value: "49", text: "Dispensável, art. 29, inciso X, da Lei 13.303/2016" },
    { value: "50", text: "Dispensável, art. 29, inciso XI, da Lei 13.303/2016" },
    { value: "51", text: "Dispensável, art. 29, inciso XII, da Lei 13.303/2016" },
    { value: "52", text: "Dispensável, art. 29, inciso XIII, da Lei 13.303/2016" },
    { value: "53", text: "Dispensável, art. 29, inciso XIV, da Lei 13.303/2016" },
    { value: "54", text: "Dispensável, art. 29, inciso XV, da Lei 13.303/2016" },
    { value: "55", text: "Dispensável, art. 29, inciso XVI, da Lei 13.303/2016" },
    { value: "56", text: "Dispensável, art. 29, inciso XVII, da Lei 13.303/2016" },
    { value: "57", text: "Dispensável, art. 29, inciso XVIII, da Lei 13.303/2016" },
    { value: "58", text: "Inexigível, art. 30, caput, da Lei 13.303/2016" },
    { value: "59", text: "Inexigível, art. 30, inciso I, da Lei 13.303/2016" },
    { value: "60", text: "Inexigível, art. 30, inciso II, alínea A, da Lei 13.303/2016" },
    { value: "61", text: "Inexigível, art. 30, inciso II, alínea B, da Lei 13.303/2016" },
    { value: "62", text: "Inexigível, art. 30, inciso II, alínea C, da Lei 13.303/2016" },
    { value: "63", text: "Inexigível, art. 30, inciso II, alínea D, da Lei 13.303/2016" },
    { value: "64", text: "Inexigível, art. 30, inciso II, alínea E, da Lei 13.303/2016" },
    { value: "65", text: "Inexigível, art. 30, inciso II, alínea F, da Lei 13.303/2016" },
    { value: "66", text: "Inexigível, art. 30, inciso II, alínea G, da Lei 13.303/2016" },
    { value: "67", text: "Dispensável, art. 75, inciso I, da Lei 14.133/2021" },
    { value: "68", text: "Dispensável, art. 75, inciso II, da Lei 14.133/2021" },
    { value: "69", text: "Dispensável, art. 75, inciso III, da Lei 14.133/2021" },
    { value: "70", text: "Dispensável, art. 75, inciso IV, alínea A, da Lei 14.133/2021" },
    { value: "71", text: "Dispensável, art. 75, inciso IV, alínea B, da Lei 14.133/2021" },
    { value: "72", text: "Dispensável, art. 75, inciso IV, alínea C, da Lei 14.133/2021" },
    { value: "73", text: "Dispensável, art. 75, inciso IV, alínea D, da Lei 14.133/2021" },
    { value: "74", text: "Dispensável, art. 75, inciso IV, alínea E, da Lei 14.133/2021" },
    { value: "75", text: "Dispensável, art. 75, inciso IV, alínea F, da Lei 14.133/2021" },
    { value: "76", text: "Dispensável, art. 75, inciso IV, alínea G, da Lei 14.133/2021" },
    { value: "77", text: "Dispensável, art. 75, inciso IV, alínea H, da Lei 14.133/2021" },
    { value: "78", text: "Dispensável, art. 75, inciso IV, alínea I, da Lei 14.133/2021" },
    { value: "79", text: "Dispensável, art. 75, inciso IV, alínea J, da Lei 14.133/2021" },
    { value: "80", text: "Dispensável, art. 75, inciso IV, alínea K, da Lei 14.133/2021" },
    { value: "81", text: "Dispensável, art. 75, inciso IV, alínea L, da Lei 14.133/2021" },
    { value: "82", text: "Dispensável, art. 75, inciso IV, alínea M, da Lei 14.133/2021" },
    { value: "83", text: "Dispensável, art. 75, inciso V, da Lei 14.133/2021" },
    { value: "84", text: "Dispensável, art. 75, inciso VI, da Lei 14.133/2021" },
    { value: "85", text: "Dispensável, art. 75, inciso VII, da Lei 14.133/2021" },
    { value: "86", text: "Dispensável, art. 75, inciso VIII, da Lei 14.133/2021" },
    { value: "87", text: "Dispensável, art. 75, inciso IX, da Lei 14.133/2021" },
    { value: "88", text: "Dispensável, art. 75, inciso X, da Lei 14.133/2021" },
    { value: "89", text: "Dispensável, art. 75, inciso XI, da Lei 14.133/2021" },
    { value: "90", text: "Dispensável, art. 75, inciso XII, da Lei 14.133/2021" },
    { value: "91", text: "Dispensável, art. 75, inciso XII, da Lei 14.133/2021" },
    { value: "92", text: "Dispensável, art. 75, inciso XIV, da Lei 14.133/2021" },
    { value: "93", text: "Dispensável, art. 75, inciso XV, da Lei 14.133/2021" },
    { value: "94", text: "Dispensável, art. 75, inciso XVI, da Lei 14.133/2021" },
    { value: "95", text: "Inexigível, art. 74, inciso I, da Lei 14.133/2021" },
    { value: "96", text: "Inexigível, art. 74, inciso II, da Lei 14.133/2021" },
    { value: "97", text: "Inexigível, art. 74, inciso III, da Lei 14.133/2021" },
    { value: "98", text: "Inexigível, art. 74, inciso IV, da Lei 14.133/2021" },
    { value: "99", text: "Inexigível, art. 74, inciso V, da Lei 14.133/2021" },
    { value: "100", text: "Dispensável, art. 4, caput, da Lei n° 13.979/2020 (COVID-19)" },
    { value: "101", text: "Dispensável, art. 2, inciso I, da Lei 14.124/2021 (vacinação COVID-19)" },
    { value: "102", text: "Dispensável, art. 2, inciso II, da Lei 14.124/2021 (vacinação COVID-19)" },
    //{ value: "101", text: "Dispensável, art. 2, inciso II" },
]
/**
 * Cria um array dos tipos de quebras de página
 * O objetivo é ser usado em formulários de impressão
 */
export const optionsInNotIn = [
    { value: 'in', text: "Incluir os itens selecionados acima" },
    { value: "not in", text: "Excluir os itens selecionados acima" },
]

/**
 * Cria um array dos tipos de quebras de página
 * O objetivo é ser usado em formulários de impressão
 */
export const optionsPageBreak = [
]
