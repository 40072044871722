<template>
  <div class="conv_convenio-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="Ano" label-for="conv_convenios-ano">
            <b-input
              type="number"
              id="conv_convenios-ano"
              v-model="itemData.ano"
              required
              :class="anoIsValid"
              placeholder="Informe o Ano ..."
              :min="minExercicio"
              @input="setMinAno"
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Mês" label-for="conv_convenios-mes">
            <b-form-select
              id="conv_convenios-mes"
              :options="optionsMes"
              v-model="itemData.mes"
              required
              placeholder="Selecione ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-form-group
            label="Unidade Autônoma Concedente"
            label-for="conv_convenios-id_ua"
          >
            <v-select
              id="conv_convenios-id_ua"
              :options="listUnidades"
              v-model="itemData.id_ua"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe a Unidade Autônoma..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nome do Convenente (Pessoas & Empresas)"
            label-for="conv_convenios-id_cadastro"
          >
            <v-select
              id="conv_convenios-id_cadastro"
              :options="listCadastros"
              v-model="itemData.id_cadastro"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Nome do Convenente ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Nº Convênio" label-for="conv_convenios-n_termo">
            <b-form-input
              id="conv_convenios-n_termo"
              type="text"
              v-model="itemData.n_termo"
              maxlength="16"
              required
              placeholder="Informe o Nº Convênio ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Modalidade"
            label-for="conv_convenios-modalidade"
          >
            <b-form-select
              id="conv_convenios-modalidade"
              :options="optionsModalidadeConv"
              v-model="itemData.modalidade"
              required
              placeholder="Informe a Modalidade ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Celebração"
            label-for="conv_convenios-data_celebracao"
          >
            <date-picker
              v-model="itemData.data_celebracao"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Data Publicação"
            label-for="conv_convenios-data_publicacao"
          >
            <date-picker
              v-model="itemData.data_publicacao"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Data Início Vigência"
            label-for="conv_convenios-data_inicio_vig"
          >
            <date-picker
              v-model="itemData.data_inicio_vig"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Data Fim Vigência"
            label-for="conv_convenios-data_fim_vig"
          >
            <date-picker
              v-model="itemData.data_fim_vig"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Assinatura"
            label-for="conv_convenios-data_assinatura"
          >
            <date-picker
              v-model="itemData.data_assinatura"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group
            label="Ato Publicação"
            label-for="conv_convenios-ato_publicacao"
          >
            <b-form-input
              id="conv_convenios-ato_publicacao"
              type="text"
              v-model="itemData.ato_publicacao"
              maxlength="32"
              placeholder="Informe o Ato Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group
            label="Veiculo Publicação"
            label-for="conv_convenios-vei_publicacao"
          >
            <b-form-select
              id="conv_convenios-vei_publicacao"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_publicacao"
              placeholder="Informe o Veiculo de Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Valor Repasse"
            label-for="exec_desembolsos-v_repasse"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.v_repasse"
                v-bind="money"
                id="exec_desembolsos-v_repasse"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Valor Contrapartida"
            label-for="exec_desembolsos-v_contrapartida"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.v_contrapartida"
                v-bind="money"
                id="exec_desembolsos-v_contrapartida"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Valor Global"
            label-for="exec_desembolsos-v_global"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.v_global"
                v-bind="money"
                id="exec_desembolsos-v_global"
                class="valor-field-right form-control"
                required
                readonly
                @input="getSoma"
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Objeto do Convênio"
            label-for="conv_convenios-objeto"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="conv_convenios-objeto"
              type="text"
              v-model="itemData.objeto"
              maxlength="1024"
              required
              placeholder="Informe o Objeto do Convênio ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Convênio: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { Money } from "v-money";
import {
  optionsModalidadeConv,
  optionsVeiculosPub,
  optionsMes,
} from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import moment from "moment";

export default {
  name: "ConvenioData",
  props: ["item"],
  components: { Money, DatePicker },
  data: function () {
    return {
      userParams: {},
      minExercicio: moment().year() - 5,
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listUnidades: [],
      listCadastros: [],
      optionsModalidadeConv: optionsModalidadeConv,
      optionsVeiculosPub: optionsVeiculosPub,
      optionsMes: optionsMes,
      anoIsValid: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/conv-convenios/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.getSoma();
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/conv-convenios${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    getSoma() {
      if (this.itemData.v_repasse && this.itemData.v_contrapartida) {
        this.itemData.v_global =
          this.itemData.v_repasse + this.itemData.v_contrapartida;
      } else if (
        this.itemData.v_global !=
        this.itemData.v_repasse + this.itemData.v_contrapartida
      ) {
        //this.itemData.v_global = '';
      }
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListaUnidAutonomas() {
      const url = `${baseApiUrl}/und-autonomas`;
      axios
        .patch(url)
        .then((res) => {
          this.listUnidades = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cnpj} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadastros = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    setMinAno() {
      if (this.itemData.ano && this.itemData.ano.length > 0)
        this.anoIsValid = this.itemData.ano >= (new Date().getFullYear() - 5) ? "is-valid" : "is-invalid";
      else this.anoIsValid = "";
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaUnidAutonomas();
    this.getListCadastros();
    this.getSoma();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
