<template>
  <div>
    <b-card>
      <b-button
        variant="outline-info"
        @click="newCategoria"
        v-b-tooltip.focus
        title="Nova Categoria"
        class="ml-2 mb-2"
      >
        Nova Categoria
        <b-icon icon="plus" />
      </b-button>
      <b-button-group
        class="ml-2 mb-2"
        v-for="(categoria, key) in categorias"
        :key="key"
        @click="setCategoria(categoria)"
        v-b-tooltip.focus
        title="Clique para expandir"
      >
        <b-button>{{ categoria.nome }}</b-button>
        <b-dropdown right :id="`dropdown-${categoria.id}`">
          <b-dropdown-item @click="editCat(categoria)"
            >Renomear
            <b-icon icon="pencil" />
          </b-dropdown-item>
          <b-dropdown-item @click="removeCat"
            >Excluir
            <b-icon icon="trash" />
          </b-dropdown-item>
        </b-dropdown>
      </b-button-group>
      <PlanCategoria
        v-if="showCategories"
        :categoria="categoria"
        class="ml-2"
        ref="planCategoria"
      />
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/env";
import { showError } from "@/global";
import PlanCategoria from "./PlanCategoria";

export default {
  name: "PlanCategorias",
  components: { PlanCategoria },
  data: function () {
    return {
      categorias: [],
      categoria: {},
      showCategories: false,
      visible: true,
    };
  },
  methods: {
    loadCategorias() {
      const url = `${baseApiUrl}/plan-categorias?pai=1`;
      axios.patch(url).then((res) => {
        this.categorias = res.data.data.map((data) => {
          return {
            id: data.id,
            nome: data.nome,
          };
        });
      });
    },
    setCategoria(cat) {
      this.showCategories = true;
      this.categoria = cat;
      if (
        this.$refs.planCategoria &&
        this.$refs.planCategoria.loadSubCategorias
      )
        this.$refs.planCategoria.loadSubCategorias(cat.id);
    },
    newCategoria() {
      const newName = prompt("Por favor insira o nome categoria", "");
      if (newName != null && newName.length > 0) {
        const url = `${baseApiUrl}/plan-categorias`;
        axios
          .post(url, { id_pai: null, nome: newName })
          .then(() => {
            this.loadCategorias();
            this.$toasted.global.defaultSuccess();
          })
          .catch((error) => {
            showError(error);
          });
      } else showError("Nome é obrigatório");
    },
    removeCat() {
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            const url = `${baseApiUrl}/plan-categorias/${this.categoria.id}`;
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.showCategories = false;
                this.loadCategorias();
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
    editCat(cat) {
      this.showCategories = true;
      this.categoria = cat;
      if (
        this.$refs.planCategoria &&
        this.$refs.planCategoria.loadSubCategorias
      )
        this.$refs.planCategoria.loadSubCategorias(cat.id);
      const newName = prompt(
        "Por favor insira o novo nome",
        this.categoria.nome
      );
      const url = `${baseApiUrl}/plan-categorias/${this.categoria.id}`;
      axios
        .put(url, { nome: newName })
        .then(() => {
          this.categoria.nome = newName;
          this.$toasted.global.defaultSuccess();
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  mounted() {
    this.loadCategorias();
  },
};
</script>

<style>
</style>