<template>
  <div class="exec_aditivo-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group
            label="Nº do Contrato"
            label-for="exec_aditivos-id_exec_cont"
          >
            <v-select
              id="exec_aditivos-id_exec_cont"
              :options="listContrato"
              v-model="itemData.id_exec_cont"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Nº do Contrato ..."
              @input="getContrato"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Nº Aditivo" label-for="exec_aditivos-n_aditivo">
            <b-form-input
              id="exec_aditivos-n_aditivo"
              type="text"
              v-model="itemData.n_aditivo"
              @keypress="isNumber($event)"
              maxlength="16"
              required
              placeholder="Informe o Nº do Aditivo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Tipo Aditivo"
            label-for="exec_aditivos-tipo_aditivo"
          >
            <b-form-select
              id="exec_aditivos-tipo_aditivo"
              :options="optionsTipoAditivos"
              v-model="itemData.tipo_aditivo"
              required
              placeholder="Informe o Tipo do Aditivo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group
            label="Veículo Publicação"
            label-for="exec_aditivos-vei_publicacao"
          >
            <b-form-select
              id="exec_aditivos-vei_publicacao"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_publicacao"
              required
              placeholder="Informe o Veículo de Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="7">
          <b-row>
            <b-col md="4" sm="12">
              <b-form-group
                label="Tipo Alteração Valor"
                label-for="exec_aditivos-tipo_alt_valor"
              >
                <b-form-select
                  id="exec_aditivos-tipo_alt_valor"
                  :options="optionsTipoAltValor"
                  v-model="itemData.tipo_alt_valor"
                  placeholder="Informe o Tipo de Alteração Valor ..."
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group
                label="Reforma"
                label-for="exec_aditivos-aditivo_reforma"
              >
                <b-form-select
                  id="exec_aditivos-aditivo_reforma"
                  :options="optionSimNao"
                  v-model="itemData.aditivo_reforma"
                  required
                  placeholder="Informe se Aditivo é Reforma ..."
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group
                label="Vigência do Aditivo"
                label-for="exec_aditivos-vigencia"
              >
                <date-picker
                  v-model="itemData.vigencia"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  required
                  input-class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group
                label="Data de Publicação"
                label-for="exec_aditivos-data_pub"
              >
                <date-picker
                  v-model="itemData.data_pub"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  required
                  input-class="form-control"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Justificativa Tipo"
            label-for="exec_aditivos-just_tipo_aditivo"
          >
            <b-form-textarea
              rows="5"
              max-rows="7"
              id="exec_aditivos-just_tipo_aditivo"
              type="text"
              v-model="itemData.just_tipo_aditivo"
              maxlength="1024"
              placeholder="Justificativa Outro Tipo ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right">
          Aditivos Contratos: {{ itemData.hash + itemData.tblName }}
        </code>
      </div>
    </b-form>
    <ExecItensGrid :item="itemData" class="mt-3" v-if="itemData.id"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import ExecItensGrid from "../execItensAdmin/ExecItensGrid";
import {
  optionsTipoAditivos,
  optionsTipoAltValor,
  optionSimNao,
  optionsVeiculosPub,
} from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ExecAditivoData",
  props: ["item"],
  components: { DatePicker, ExecItensGrid },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listContrato: [],
      contrato: {},
      isDate: "",
      optionsTipoAditivos: optionsTipoAditivos,
      optionsTipoAltValor: optionsTipoAltValor,
      optionSimNao: optionSimNao,
      optionsVeiculosPub: optionsVeiculosPub,
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/exec-aditivos/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.itemData.aditivo = this.item
          this.getContrato();
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/exec-aditivos${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.itemData.aditivo = this.item
          this.getContrato();
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListContratos() {
      const url = `${baseApiUrl}/exec-contratos`;
      axios
        .patch(url)
        .then((res) => {
          this.listContrato = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contrato}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getContrato() {
      const url = `${baseApiUrl}/exec-contratos/${this.itemData.id_exec_cont}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.contrato = res.data;
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListContratos();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
