<template>
  <div class="cpl-panel">
    <PageTitle icon="far fa-edit" main="Compras Públicas" sub="Dados & Cia" />
    <div class="cpl-panel-tabs">
      <b-card no-body id="cpl-panel">
        <b-tabs v-model="tabIndex" card>
          <b-tab title="Arquivos" lazy>
            <CplArquivos ref="CplArquivos" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PageTitle from "../template/PageTitle";
import CplArquivos from "./CplArquivos";

export default {
  name: "CplAdminPanels",
  components: {
    PageTitle,
    CplArquivos,
  },
  data: function () {
    return {
      tabIndex: undefined,
    };
  },
  methods: {},
  mounted() {},
  computed: mapState(["user"]),
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>