import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/home/Home'
import AdminPages from '@/components/admin/AdminPages'
import PrintingPages from '@/components/printing/PrintingPages'
import Auth from '@/components/auth/Auth'
import PasswordReset from '@/components/auth/PasswordReset'
import RequestPasswordReset from '@/components/auth/RequestPasswordReset'
import PatrimonioAdminPanels from '@/components/patrimonioAdmin/PatrimonioAdminPanels'
import UAGrid from '@/components/uAAdmin/UAGrid'
import CadastrosGrid from '@/components/cadastrosAdmin/CadastrosGrid'
import CadServidoresGrid from '@/components/cadservidoresAdmin/CadServidoresGrid'
import SiapAdminPanels from '@/components/siapAdmin/SiapAdminPanels'
import PlanAdminPanels from '@/components/planejamentoAdmin/PlanAdminPanels'
import AdjAdminPanels from '@/components/adjudicacaoAdmin/AdjAdminPanels'
import ExecAdminPanels from '@/components/execucaoAdmin/ExecAdminPanels'
import ConvAdminPanels from '@/components/convenioAdmin/ConvAdminPanels'
import ObrasAdminPanels from '@/components/ObraAdmin/ObrasAdminPanels'
import CplAdminPanels from '@/components/CplAdmin/CplAdminPanels'
import EditaisPanels from '@/components/editaisAdmin/EditaisPanels'
import AlmAdminPanels from '@/components/AlmoxarifadoAdmin/AlmAdminPanels'

Vue.use(VueRouter)

const routes = [{
    name: 'home',
    path: '/',
    component: Home
}, {
    name: 'user-unlock',
    path: '/user-unlock/:id/:token',
}, {
    name: 'adminPages',
    path: '/admin',
    component: AdminPages,
}, {
    name: 'printingPages',
    path: '/relatorios',
    component: PrintingPages,
}, {
    name: 'auth',
    path: '/auth',
    component: Auth,
}, {
    name: 'password-reset',
    path: '/password-reset/:token',
    component: PasswordReset
}, {
    name: 'request-password-reset',
    path: '/request-password-reset',
    component: RequestPasswordReset
}, {
    name: 'patrimonio',
    path: '/patrimonio',
    component: PatrimonioAdminPanels
}, {
    name: 'und-autonomas',
    path: '/und-autonomas',
    component: UAGrid
}, {
    name: 'cadastro',
    path: '/cadastros',
    component: CadastrosGrid
}, {
    name: 'cad-servidores',
    path: '/cad-servidores',
    component: CadServidoresGrid
}, {
    name: 'siap',
    path: '/cpl/siap',
    component: SiapAdminPanels
}, {
    name: 'planejamento',
    path: '/cpl/planejamento',
    component: PlanAdminPanels
}, {
    name: 'adjudicacao',
    path: '/cpl/adjudicacao',
    component: AdjAdminPanels
}, {
    name: 'execucao',
    path: '/cpl/execucao',
    component: ExecAdminPanels
}, {
    name: 'convenio',
    path: '/cpl/convenio',
    component: ConvAdminPanels
}, {
    name: 'cpl',
    path: '/cpl',
    component: CplAdminPanels
}, {
    name: 'editais',
    path: '/cpl/editais',
    component: EditaisPanels
}, {
    name: 'obras',
    path: '/obra',
    component: ObrasAdminPanels
}, {
    name: 'almoxarifado',
    path: '/almoxarifado',
    component: AlmAdminPanels
}
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router