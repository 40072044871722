<template>
  <div>
    <b-card no-body>
      <div class="exec_contratos-grid pdd-10">
        <b-input-group size="md" class="mt-3 mb-3">
          <b-dropdown
            text="Novo registro"
            :variant="`outline-${lblBtnNewVariant}`"
            slot="prepend"
          >
            <b-dropdown-item href="#" @click="newRow('1')"
              >Licitação</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="newRow('2')"
              >Contratação</b-dropdown-item
            >
          </b-dropdown>
          <b-form-input
            type="text"
            placeholder="Digite aqui para localizar o registro..."
            v-b-tooltip.hover
            :title="`Digite ao menos ${charSearchCount} caracteres para pesquisa dinâmica e use espaços para pesquisar por mais de uma expressão`"
            v-model="keyword"
            @input="searchExeCont(charSearchCount)"
            @blur="searchExeCont(0)"
            ref="keyword"
          />
          <b-btn size="sm" @click="searchExeCont(0)">
            <i class="fa fa-search"></i>
          </b-btn>
          <b-input-group-text slot="append">
            <span v-if="isBrowser">{{ keyword_res }}&nbsp;</span>
            <b-btn
              :disabled="!keyword"
              variant="link"
              size="sm"
              @click="getRefresh"
              class="p-0"
            >
              <i class="fa fa-remove"></i>
            </b-btn>
          </b-input-group-text>
        </b-input-group>
        <b-table
          hover
          striped
          responsive
          :items="exec_contratos"
          :fields="fields"
        >
          <template v-slot:cell(n_licitacao)="data">
            <span
              v-if="data.item.n_licitacao"
              v-html="
                `Licitação: ${data.item.n_licitacao} ${
                  userParams.admin >= 1 ? `(${data.item.id})` : ''
                }`
              "
            />
            <span
              v-if="data.item.n_contratacao"
              v-html="
                `Contratação: ${data.item.n_contratacao} ${
                  userParams.admin >= 1 ? `(${data.item.id})` : ''
                }`
              "
            />
          </template>
          <template #cell(n_contrato)="data">
            <span v-html="data.item.n_contrato" />
          </template>
          <template #cell(descricao)="data">
            <span v-html="data.item.descricao" />
          </template>
          <template #cell(actions)="row">
            <b-button
              v-if="userParams.id >= 1"
              variant="outline-info"
              size="sm"
              class="mr-1"
              v-b-tooltip.hover
              title="Editar registro"
              @click="showRow(row.item, row.index, $event.target)"
              href="#header"
            >
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button
              v-if="userParams.id >= 1"
              variant="outline-danger"
              size="sm"
              v-b-tooltip.hover
              title="Remover registro"
              @click="remove(row.item.id)"
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination
          size="md"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
        />
      </div>
    </b-card>
    <b-modal
      size="xl"
      id="modal-form"
      :title="modalTitle"
      centered
      scrollable
      hide-footer
    >
      <ExecContratoData
        :tipoProposta="objProponente"
        :item="exeContratoInputs"
        @objectInputs="loadExecContratos"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import { getDecimalFormater } from "@/config/globalFacilities";
import { showError, highlight, removeMark } from "@/global";
import ExecContratoData from "./ExecContratoData";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "ExecContratosGrid",
  components: { ExecContratoData },
  data: function () {
    return {
      userParams: {},
      charSearchCount: 3,
      modalTitle: "Novo Registro",
      exec_contratos: [], // table
      exec_contrato: {}, // field
      exeContratoInputs: {}, // form data
      loading: false,
      lblBtnNew: "Novo Registro",
      lblBtnNewVariant: "primary",
      objProponente: "1",
      mode: "save",
      keyword: "",
      isBrowser: isBrowser,
      keyword_res: "",
      lblCancelar: "Cancelar",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        {
          key: "n_licitacao",
          label: "Nº Licitação/Contratação",
          sortable: true,
        },
        { key: "n_contrato", label: "Nº Contrato", sortable: true },
        { key: "descricao", label: "Descrição", sortable: true },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions2",
          tdClass: "col-actions2",
        },
      ],
    };
  },
  methods: {
    searchExeCont(length) {
      if (
        this.keyword.toString().length >= length ||
        this.keyword.toString().length == 0
      )
        this.loadExecContratos();
    },
    loadExecContratos() {
      const url = `${baseApiUrl}/exec-contratos?page=${this.page}&key=${this.keyword}`;
      axios.get(url).then((res) => {
        this.exec_contratos = res.data.data;
        this.exec_contratos.forEach((element) => {
          if (element.n_licitacao)
            element.n_licitacao = highlight(
              element.n_licitacao,
              this.keyword.toString().toUpperCase()
            );
          if (element.n_contrato)
            element.n_contrato = highlight(
              element.n_contrato.toUpperCase(),
              this.keyword.toString().toUpperCase()
            );
          if (element.descricao)
            element.descricao = highlight(
              element.descricao
                .split(" ")
                .slice(0, 20)
                .toString()
                .replaceAll(",", " ") +
                `${element.descricao.split(" ").length > 20 ? "..." : ""}`,
              this.keyword.toString().toUpperCase()
            );
        });
        this.count = res.data.count;
        this.limit = res.data.limit;
        const pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${pluralize}`;
      });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.loadExecContratos();
    },
    reset() {
      this.keyword = "";
      this.mode = "save";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.getRefresh();
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    newRow(value) {
      this.objProponente = value;
      this.exeContratoInputs = {};
      this.modalTitle = `Novo Registro de Proponente para a ${
        value == "1" ? "Licitação" : "Contratação"
      }`;
      this.$root.$emit("bv::show::modal", "modal-form");
    },
    showRow(item, index, button) {
      Object.entries(item).forEach(([key, value]) => {
        if (item)
          this.exeContratoInputs = {
            ...this.exeContratoInputs,
            [key]: removeMark(value || ""),
          };
      });
      if (item.id_pl_lic) this.objProponente = "1";
      else if (item.id_pl_cont) this.objProponente = "2";
      this.modalTitle = `Registro: ${this.exeContratoInputs.n_contrato}`;
      this.$root.$emit("bv::show::modal", "modal-form", button);
    },
    remove(id) {
      const url = `${baseApiUrl}/exec-contratos/${id}`;
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.exeContratoInputs = {};
                this.loadExecContratos();
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
  },
  mounted() {
    this.loadUserParams();
    this.loadExecContratos();
  },
  watch: {
    page() {
      this.loadExecContratos();
    },
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.exec_contratos-data {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>