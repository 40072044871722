<template>
  <div class="plan_contratacao-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="Ano" label-for="plan_contratacoes-exercicio">
            <b-input
              type="number"
              id="plan_contratacoes-exercicio"
              v-model="itemData.exercicio"
              required
              :class="anoIsValid"
              placeholder="Informe o Ano ..."
              
              @input="setMinAnoExercicio"
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Mês" label-for="plan_contratacoes-mes">
            <b-form-select
              id="plan_contratacoes-mes"
              :options="optionsMes"
              v-model="itemData.mes"
              required
              placeholder="Selecione ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-form-group
            label="Unidade Autônoma"
            label-for="plan_contratacoes-id_ua"
          >
            <v-select
              id="plan_contratacoes-id_ua"
              :options="listPUnidade"
              v-model="itemData.id_ua"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe a Unidade ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nº do Processo"
            label-for="plan_contratacoes-n_processo"
          >
            <b-form-input
              id="plan_contratacoes-n_processo"
              type="text"
              v-model="itemData.n_processo"
              @keypress="isNumber($event)"
              maxlength="32"
              required
              placeholder="Informe o Número do Processo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Nº da Contratação"
            label-for="plan_contratacoes-n_contratacao"
          >
            <b-form-input
              id="plan_contratacoes-n_contratacao"
              type="text"
              v-model="itemData.n_contratacao"
              @keypress="isNumber($event)"
              maxlength="16"
              required
              placeholder="Informe o Nº da Contratação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Natureza Objeto"
            label-for="plan_contratacoes-natureza_obj"
          >
            <b-form-select
              id="plan_contratacoes-natureza_obj"
              :options="optionsNatObjCont"
              v-model="itemData.natureza_obj"
              required
              placeholder="Informe a Natureza Objeto ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Referência Legal"
            label-for="plan_contratacoes-referencia_legal"
          >
            <b-form-select
              id="plan_contratacoes-referencia_legal"
              :options="optionsRefLegal"
              v-model="itemData.referencia_legal"
              required
              placeholder="Informe o Referência Legal ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Enquadramento"
            label-for="plan_contratacoes-enquadramento"
          >
            <b-form-select
              id="plan_contratacoes-enquadramento"
              :options="optionsEnquadramento"
              v-model="itemData.enquadramento"
              required
              placeholder="Informe o Enquadramento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Código Programa"
            label-for="plan_contratacoes-cod_programa"
          >
            <b-form-input
              id="plan_contratacoes-cod_programa"
              type="text"
              v-model="itemData.cod_programa"
              @keypress="isNumber($event)"
              maxlength="32"
              placeholder="Informe o Código do Programa ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Valor Previsto"
            label-for="plan_contratacoes-valor_previsto"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor_previsto"
                v-bind="money"
                id="plan_contratacoes-valor_previsto"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Orçamento Proprio"
            label-for="plan_contratacoes-orcamento_proprio"
          >
            <b-form-select
              id="plan_contratacoes-orcamento_proprio"
              :options="optionSimNao"
              v-model="itemData.orcamento_proprio"
              required
              placeholder="Informe o Orçamento Proprio ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Veiculo Publicação"
            label-for="plan_contratacoes-vei_publicacao"
          >
            <b-form-select
              id="plan_contratacoes-vei_publicacao"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_publicacao"
              required
              placeholder="Informe o Veiculo Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data da Publicação"
            label-for="plan_contratacoes-data_publicacao"
          >
            <date-picker
              v-model="itemData.data_publicacao"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data da Publicação Edital"
            label-for="plan_contratacoes-data_pub_edital"
          >
            <date-picker
              v-model="itemData.data_pub_edital"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Objeto" label-for="plan_contratacoes-objeto">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="plan_contratacoes-objeto"
              type="text"
              v-model="itemData.objeto"
              maxlength="1024"
              required
              placeholder="Objeto da Contratação Direta..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Documento Jurídico"
            label-for="plan_contratacoes-doc_juridico"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="plan_contratacoes-doc_juridico"
              type="text"
              v-model="itemData.doc_juridico"
              maxlength="1024"
              required
              placeholder="Informe o Documento Jurídico ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <b-button
          class="ml-2"
          variant="warning"
          v-if="itemData.id"
          @click="showRow"
        >
          Itens da Contratação
        </b-button>
        <code v-if="itemData.hash" class="float-right">
          Contratação Direta: {{ itemData.hash + itemData.tblName }}
        </code>
      </div>
    </b-form>
    <b-modal
      size="xl"
      id="modal-form-plan-itens-grid"
      :title="`Itens da Contratação ${itemData.n_contratacao}`"
      centered
      scrollable
      hide-footer
    >
      <PlanItensGrid :item="itemData" tipoProposta="2" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import PlanItensGrid from "../planItensAdmin/PlanItensGrid";
import {
  optionsEnquadramento,
  optionsNatObjCont,
  optionSimNao,
  optionsVeiculosPub,
  optionsRefLegal,
  optionsMes,
} from "@/config/lists";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "PlanContratacaoData",
  props: ["item"],
  components: { Money, DatePicker, PlanItensGrid },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isEmail: "",
      optionsEnquadramento: optionsEnquadramento,
      optionsNatObjCont: optionsNatObjCont,
      optionSimNao: optionSimNao,
      optionsVeiculosPub: optionsVeiculosPub,
      optionsRefLegal: optionsRefLegal,
      optionsMes: optionsMes,
      anoIsValid: "",
      isCnpj: "",
      isDate: "",
      listPUnidade: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/plan-contratacoes/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      } else {
        this.itemData = this.item;
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/plan-contratacoes${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListaUnidAutonomas() {
      const url = `${baseApiUrl}/und-autonomas`;
      axios
        .patch(url)
        .then((res) => {
          this.listPUnidade = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cnpj} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    showRow(item, index, button) {
      this.$root.$emit("bv::show::modal", "modal-form-plan-itens-grid", button);
    },
    setMinAnoExercicio() {
      if (
        this.itemData.exercicio &&
        this.itemData.exercicio.length > 0
      )
        this.anoIsValid =
          this.itemData.exercicio >= 2019 ? "is-valid" : "is-invalid";
      else this.anoIsValid = "";
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaUnidAutonomas();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
