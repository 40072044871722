<template>
  <div class="siap-panel">
    <PageTitle icon="far fa-edit" main="Compras Públicas" sub="Adjudicação" />
    <div class="siap-panel-tabs">
      <b-card no-body id="siap-panel">
        <b-tabs card>
          <b-tab title="Adjudicação Licitações" lazy>
            <AdjLicitacoesGrid ref="AdjLicitacoesGrid" />
          </b-tab>
          <b-tab title="Proponentes" lazy>
            <AdjProponentesGrid ref="AdjProponentesGrid" />
          </b-tab>
          <b-tab title="Ata de Registro de Preços" lazy>
            <AdjRegistrosGrid ref="AdjRegistrosGrid" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AdjLicitacoesGrid from "../adjLicitacoesAdmin/AdjLicitacoesGrid";
import AdjProponentesGrid from "../adjProponentesAdmin/AdjProponentesGrid";
import AdjRegistrosGrid from "../adjRegistrosAdmin/AdjRegistrosGrid";
import PageTitle from "../template/PageTitle";

export default {
  name: "AdjAdminPanels",
  components: {
    PageTitle,
    AdjLicitacoesGrid,
    AdjProponentesGrid,
    AdjRegistrosGrid
  },
  data: function () {
    return {};
  },
  methods: {},

  mounted() {},

  computed: mapState(["user"]),
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>