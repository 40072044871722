<template>
  <div class="cadastro-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="3" sm="12">
          <b-form-group label="CNPJ/CPF" label-for="cadastros-cpf_cnpj">
            <the-mask
              id="cadastros-cpf_cnpj"
              class="form-control"
              :class="`form-control ${isCnpj}`"
              type="text"
              v-model="itemData.cpf_cnpj"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              required
              @input="setValidCnpjCpf"
              :readonly="mode === 'remove'"
              placeholder="Informe o CNPJ/CPF..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" v-if="tipoCadastro == 0">
          <b-form-group label="IE / RG / Isento" label-for="cadastros-rg_ie">
            <b-input-group>
              <b-form-input
                id="cadastros-rg_ie"
                type="text"
                v-model="itemData.rg_ie"
                maxlength="10"
                required
                :disabled="rgIsento"
                placeholder="RG ou Inscrição Estadual ..."
              />
              <b-input-group-append is-text title="Selecione para RG/IE Isento">
                <b-form-checkbox
                  switch
                  class="mr-n2"
                  v-model="rgIsento"
                  id="cadastros-rgIsento"
                >
                  <span class="sr-only">Selecione para RG/IE Isento</span>
                </b-form-checkbox>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :md="tipoCadastro == 0 ? '6' : '9'" sm="12">
          <b-form-group label="Nome" label-for="cadastros-cadas_nome">
            <b-form-input
              id="cadastros-cadas_nome"
              type="text"
              v-model="itemData.cadas_nome"
              maxlength="50"
              required
              placeholder="Informe o Nome ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12" v-if="tipoCadastro == 0">
          <b-form-group
            label="Qualificação Profissional"
            label-for="cadastros-qualificacao"
          >
            <b-form-select
              id="cadastros-qualificacao"
              :options="optionsQualificacao"
              v-model="itemData.qualificacao"
              required
              placeholder="Informe a Qualificação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Telefone 1" label-for="cadastros-telefone1">
            <the-mask
              id="cadastros-telefone1"
              class="form-control"
              v-model="itemData.telefone1"
              :mask="['(##) ####-####', '(##) #####-####']"
              required
              :readonly="mode === 'remove'"
              placeholder="Informe o Telefone ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Telefone 2" label-for="cadastros-telefone2">
            <the-mask
              id="cadastros-telefone2"
              class="form-control"
              v-model="itemData.telefone2"
              :mask="['(##) ####-####', '(##) #####-####']"
              required
              :readonly="mode === 'remove'"
              placeholder="Informe o Telefone ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Email" label-for="cadastros-email">
            <b-form-input
              id="cadastros-email"
              :class="`form-control ${isEmail}`"
              type="email"
              v-model="itemData.email"
              @input="setValidEmail"
              required
              placeholder="Informe o Email ..."
            />
          </b-form-group>
        </b-col>
        <b-col :md="tipoCadastro == 0 ? '6' : '9'" sm="12">
          <b-form-group
            label="Nome Pessoa contato"
            label-for="cadastros-pessoa_contato"
          >
            <b-form-input
              id="cadastros-pessoa_contato"
              type="text"
              v-model="itemData.pessoa_contato"
              maxlength="50"
              required
              placeholder="Informe o Nome Pessoa Contato ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            :label="`Data de ` + (tipoCadastro == 0 ? 'Nascimento' : 'Fundação')"
            label-for="cadastros-nascimento"
          >
            <the-mask
              id="cadastros-nascimento"
              class="form-control"
              :class="`form-control ${isDate}`"
              v-model="itemData.nascimento"
              :mask="['##/##/####']"
              masked
              placeholder="dd/mm/aaaa..."
              required
              @input="setValidDate"
              :readonly="mode === 'remove'"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" v-if="tipoCadastro == 0">
          <b-form-group
            label="Usuário do Patrimônio"
            label-for="cadastros-patrimonio"
          >
            <b-form-checkbox
              switch
              size="lg"
              v-model="itemData.patrimonio"
              id="cadastros-patrimonio"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12" v-if="tipoCadastro == 0">
          <b-form-group label="Nº CREA" label-for="cadastros-crea">
            <b-form-input
              id="cadastros-crea"
              type="text"
              v-model="itemData.crea"
              @keypress="isNumber($event)"
              maxlength="50"
              required
              placeholder="Informe Nº CREA ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Observação" label-for="cadastros-obs">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="cadastros-obs"
              type="text"
              v-model="itemData.obs"
              maxlength="2000"
              required
              placeholder="Observações ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Cadastro: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import {
  isNumber,
  emailOrError,
  datePickerLocale,
} from "@/config/globalFacilities";
import { ufList } from "@/config/lists";
import { TheMask } from "vue-the-mask";
import { cnpj, cpf } from "cpf-cnpj-validator";

export default {
  name: "CadastrosData",
  props: ["item", "tipoCadastro"], 
  components: { TheMask },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblFundacao: "Fundação",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      rgIsento: false,
      isEmail: "",
      ufList: ufList,
      isCnpj: "",
      isDate: "",
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      optionsEstadoCivil: [
        { value: "1", text: "Solteiro(a)" },
        { value: "2", text: "União Estável" },
        { value: "3", text: "Casado(a)" },
        { value: "4", text: "Divorciado(a)" },
        { value: "5", text: "Viúvo(a)" },
        { value: "6", text: "Outros" },
      ],
      optionsQualificacao: [
        { value: "01", text: "Engenheiro Civil" },
        { value: "02", text: "Engenheiro Eletricista" },
        { value: "03", text: "Engenheiro de Telecomunicações" },
        { value: "04", text: "Engenheiro – Mecânica" },
        { value: "05", text: "Engenheiro – Minas" },
        { value: "06", text: "Engenheiro – Químico" },
        { value: "07", text: "Engenheiro – Naval" },
        { value: "08", text: "Engenheiro – Outros" },
        { value: "09", text: "Geólogo" },
        { value: "10", text: "Técnico – Edificações" },
        { value: "11", text: "Técnico – Estradas" },
        { value: "12", text: "Técnico – Eletrônica" },
        { value: "13", text: "Técnico – Telecomunicações" },
        { value: "14", text: "Técnico – Eletrotécnico" },
        { value: "15", text: "Técnico – Agrícola" },
        { value: "16", text: "Técnico – Refrigeração" },
        { value: "17", text: "Técnico – Mecânica" },
        { value: "18", text: "Técnico – Mineração" },
        { value: "19", text: "Técnico – Outros" },
        { value: "20", text: "Arquiteto" },
        { value: "99", text: "Outros" },
      ],
    };
  },
  methods: {
    async loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/cadastros/${this.item.id}`;
        await axios.get(url).then((body) => {
          this.itemData = body.data;
          this.rgIsento = this.itemData.rg_ie && this.itemData.rg_ie.trim().length == 0;
          if (this.itemData.nascimento) {
            this.itemData.patrimonio = this.itemData.patrimonio == 1;
          }
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      this.itemData.rg_isento = this.rgIsento;
      this.setTipoCadastro();
      this.itemData.tipo_cadastro = this.tipoCadastro;
      if (this.rgIsento) delete this.itemData.rg_ie;
      axios[method](`${baseApiUrl}/cadastros${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
          // this.$bvModal.hide("modal-form");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    setValidCnpjCpf() {
      if (this.itemData && this.itemData.cpf_cnpj) {
        if (this.itemData.cpf_cnpj.length == 14) {
          if (cnpj.isValid(this.itemData.cpf_cnpj)) this.isCnpj = "is-valid";
          else this.isCnpj = "is-invalid";
        } 
        else if (this.itemData.cpf_cnpj.length == 11) {
          if (cpf.isValid(this.itemData.cpf_cnpj)) this.isCnpj = "is-valid";
          else this.isCnpj = "is-invalid";
        } 
        else this.isCnpj = "";
      } else this.isCnpj = "";
    },
    setValidDate() {
      if (this.itemData.nascimento && this.itemData.nascimento.length == 8)
        this.isDate = "is-valid";
      else if (
        this.itemData.nascimento &&
        this.itemData.nascimento.length > 0 &&
        this.itemData.nascimento.length < 8
      )
        this.isDate = "is-invalid";
      else this.isDate = "";
    },
    setValidEmail() {
      if (this.itemData.email && this.itemData.email.length > 0) {
        if (emailOrError(this.itemData.email)) this.isEmail = "is-valid";
        else this.isEmail = "is-invalid";
      } else this.isEmail = "";
    },
    setTipoCadastro() {
      this.tipoCadastro = this.$route.params.ft || "0";
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.setTipoCadastro();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
