<template>
  <div class="adj_licitacao-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group
            label="Nº do Processo (Licitação)"
            label-for="adj_licitacoes-id_plan_lic"
          >
            <v-select
              id="adj_licitacoes-id_plan_lic"
              :options="listLicitacao"
              v-model="itemData.id_plan_lic"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe a Licitação ..."
              @input="getLicitacao"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="1" sm="12">
          <b-form-group
            label="Fracassada"
            label-for="adj_licitacoes-fracassada"
          >
            <b-form-select
              id="adj_licitacoes-fracassada"
              :options="optionSimNaoAdj"
              v-model="itemData.fracassada"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Homologação Parcial"
            label-for="adj_licitacoes-homologacao_parcial"
          >
            <b-form-select
              id="adj_licitacoes-homologacao_parcial"
              :options="optionSimNao"
              v-model="itemData.homologacao_parcial"
              required
              placeholder="Informe se Homologação é parcial. ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Vigência"
            label-for="adj_licitacoes-data_vigencia"
          >
            <date-picker
              v-model="itemData.data_vigencia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              input-class="form-control"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group
            label="Veiculo Publicação Resultado"
            label-for="adj_licitacoes-vei_pub_res"
          >
            <b-form-select
              id="adj_licitacoes-vei_pub_res"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_pub_res"
              required
              placeholder="Informe o Veiculo Publicação Resultado ..."
            />
          </b-form-group>
        </b-col>
        <!------------------->
        <b-col md="7" sm="12">
          <b-row>
            <b-col md="4" sm="12">
              <b-form-group
                label="Data Adjudicação"
                label-for="adj_licitacoes-data_adj"
              >
                <date-picker
                  v-model="itemData.data_adj"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  input-class="form-control"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group
                label="Data Homologação"
                label-for="adj_licitacoes-data_homologacao"
              >
                <date-picker
                  v-model="itemData.data_homologacao"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  input-class="form-control"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group
                label="Data Publicação"
                label-for="adj_licitacoes-data_pub_res"
              >
                <date-picker
                  v-model="itemData.data_pub_res"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  input-class="form-control"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!------------------->

        <b-col md="5" sm="12">
          <b-form-group
            label="Veículo Publicação Anulação"
            label-for="adj_licitacoes-vei_pub_anulacao"
          >
            <b-form-select
              id="adj_licitacoes-vei_pub_anulacao"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_pub_anulacao"
              placeholder="Informe o Veículo de Publicação Anulação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="7" sm="12">
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group
                label="Data Anulação"
                label-for="adj_licitacoes-data_anulacao"
              >
                <date-picker
                  v-model="itemData.data_anulacao"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  input-class="form-control"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group
                label="Data Publicação"
                label-for="adj_licitacoes-data_pub_anulacao"
              >
                <date-picker
                  v-model="itemData.data_pub_anulacao"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  input-class="form-control"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group
            label="Veículo Publicação Revogação"
            label-for="adj_licitacoes-vei_pub_revogacao"
          >
            <b-form-select
              id="adj_licitacoes-vei_pub_revogacao"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_pub_revogacao"
              placeholder="Informe o Veículo Publicação Revogação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="7" sm="12">
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group
                label="Data da Revogação"
                label-for="adj_licitacoes-data_revogacao"
              >
                <date-picker
                  v-model="itemData.data_revogacao"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  input-class="form-control"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group
                label="Data Publicação"
                label-for="adj_licitacoes-data_pub_revogacao"
              >
                <date-picker
                  v-model="itemData.data_pub_revogacao"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  input-class="form-control"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Motivo Anulação"
            label-for="adj_licitacoes-motivo_anulacao"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="adj_licitacoes-motivo_anulacao"
              type="text"
              v-model="itemData.motivo_anulacao"
              maxlength="1024"
              placeholder="Informe o Motivo da Anulação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Motivo Revogação"
            label-for="adj_licitacoes-motivo_revogacao"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="adj_licitacoes-motivo_revogacao"
              type="text"
              v-model="itemData.motivo_revogacao"
              maxlength="1000"
              placeholder="Informe o Motivo da Revogação ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Adjudicação Licitação: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { optionsVeiculosPub, optionSimNao } from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "AdjLicitacaoData",
  props: ["item"],
  components: { DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listLicitacao: [],
      licitacao: {},
      optionsVeiculosPub: optionsVeiculosPub,
      optionSimNao: optionSimNao,
      optionSimNaoAdj: [
        { value: "3", text: "SIM" },
        { value: "4", text: "NÃO" },
      ],
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    async loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/adj-licitacoes/${this.item.id}`;
        await axios.get(url).then((body) => {
          this.itemData = body.data;
          this.getLicitacao();
        });
      }
      this.getListPlanLicitacoes();
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/adj-licitacoes${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
          this.getLicitacao();
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListPlanLicitacoes() {
      const id_plan_lic = this.itemData.id
        ? `?id_plan_lic=${this.itemData.id_plan_lic}`
        : "";
      const url = `${baseApiUrl}/adj-licitacoes-list_licitacoes${id_plan_lic}`;
      axios
        .patch(url)
        .then((res) => {
          this.listLicitacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getLicitacao() {
      const url = `${baseApiUrl}/plan-licitacoes/${this.itemData.id_plan_lic}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.licitacao = res.data;
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
