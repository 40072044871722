<template>
  <div class="obra-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12" v-if="tipoProposta == '1'">
          <b-form-group label="Nº do Processo (Licitação)" label-for="obras-id_plan_licit">
            <v-select
              id="obras-id_plan_licit"
              :options="listLicitacao"
              v-model="itemData.id_plan_licit"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe a Licitação ..."
              @input="getLicitacao"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12" v-if="tipoProposta == '2'">
          <b-form-group
            label="Nº da Contratação"
            label-for="obras-id_plan_contrat"
          >
            <v-select
              id="obras-id_plan_contrat"
              :options="listContratacao"
              v-model="itemData.id_plan_contrat"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº da Contratação ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nº do Contrato"
            label-for="obras-id_exec_contrat"
          >
            <v-select
              id="obras-id_exec_contrat"
              :options="listContrato"
              v-model="itemData.id_exec_contrat"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº do Contrato ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Codigo Executor (Pessoas & Empresas)"
            label-for="obras-id_cadas_executor"
          >
            <v-select
              id="obras-id_cadas_executor"
              :options="listCadas"
              v-model="itemData.id_cadas_executor"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o cadastro ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Responsável pela Fiscalização (Pessoas & Empresas)"
            label-for="obras-id_cadas_crea"
          >
            <v-select
              id="obras-id_cadas_crea"
              :options="listCadas"
              v-model="itemData.id_cadas_crea"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o  Responsável pela Fiscalização ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Nº do Processo" label-for="obras-n_processo">
            <b-form-input
              id="obras-n_processo"
              type="text"
              v-model="itemData.n_processo"
              @keypress="isNumber($event)"
              maxlength="32"
              required
              placeholder="Informe o Nº do Processo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Ano Licitação" label-for="obras-ano">
            <b-input
              type="number"
              id="obras-ano"
              v-model="itemData.ano"
              required
              :class="anoIsValid"
              placeholder="Informe o Ano ..."
              :min="minExercicio"
              @input="setMinAno"
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Regime de Execução"
            label-for="obras-regime_exec"
          >
            <b-form-select
              id="obras-regime_exec"
              type="text"
              v-model="itemData.regime_exec"
              :options="optionsRegimeExeObra"
              placeholder="Informe o Regime de Execução da Obra ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Data Início" label-for="obras-data_inicio">
            <date-picker
              v-model="itemData.data_inicio"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Data Fim" label-for="obras-data_fim">
            <date-picker
              v-model="itemData.data_fim"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Prazo de Execução" label-for="obras-prazo_exec">
            <b-form-input
              id="obras-prazo_exec"
              type="text"
              v-model="itemData.prazo_exec"
              @keypress="isNumber($event)"
              maxlength="4"
              required
              placeholder="Informe o Prazo de Execução ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Tipo de Obra" label-for="obras-tipo">
            <b-form-select
              id="obras-tipo"
              :options="optionsTipoObra"
              v-model="itemData.tipo"
              required
              placeholder="Informe o Tipo de Obra ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Tipo de Serviço" label-for="obras-tipo_servico">
            <b-form-select
              id="obras-tipo_servico"
              :options="optionsTipoServ"
              v-model="itemData.tipo_servico"
              required
              placeholder="Informe o Tipo de Serviço ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group
            label="Setor Beneficiado"
            label-for="obras-setor_beneficiado"
          >
            <b-form-select
              id="obras-setor_beneficiado"
              :options="optionsSetorBenef"
              v-model="itemData.setor_beneficiado"
              required
              placeholder="Informe o Setor Beneficiado ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Natureza Obra" label-for="obras-natureza_obra">
            <b-form-select
              id="obras-natureza_obra"
              :options="optionsNaturezaObra"
              v-model="itemData.natureza_obra"
              placeholder="Informe a Natureza da Obra ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Qualificação Profissional"
            label-for="obras-qualificacao"
          >
            <b-form-select
              id="obras-qualificacao"
              :options="optionsQualificacao"
              v-model="itemData.qualificacao"
              required
              placeholder="Informe a Qualificação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="CEP" label-for="obras-cep">
            <the-mask
              id="obras-cep"
              type="text"
              @input="getEnderecoApi"
              v-model="itemData.cep"
              :mask="['########']"
              :class="`form-control ${cepClass}`"
              :readonly="mode === 'remove'"
              required
              placeholder="Informe o Cep..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="UF" label-for="obras-uf">
            <b-form-select
              id="obras-uf"
              :options="ufList"
              v-model="itemData.uf"
              @input="getListaCidades"
              required
              :disabled="!isCep || mode === 'remove'"
              placeholder="Informe o uf ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group label="Cidade" label-for="obras-cidade">
            <b-form-select
              id="obras-cidade"
              v-model="itemData.cidade"
              :options="citiesList_cidade"
              required
              :disabled="!isCep || mode === 'remove'"
              placeholder="Informe a Cidade..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Bairro" label-for="obras-bairro">
            <b-form-input
              id="obras-bairro"
              type="text"
              v-model="itemData.bairro"
              :readonly="!isCep || mode === 'remove'"
              maxlength="50"
              required
              placeholder="Informe o Bairro ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Logradouro" label-for="obras-logradouro">
            <b-form-input
              id="obras-logradouro"
              ref="logradouro"
              type="text"
              v-model="itemData.logradouro"
              maxlength="100"
              required
              :readonly="!isCep || mode === 'remove'"
              placeholder="Informe o Logradouro ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Nº:" label-for="obras-nr">
            <b-form-input
              id="obras-nr"
              ref="nr"
              type="text"
              v-model="itemData.nr"
              :readonly="!isCep || mode === 'remove'"
              required
              placeholder="Informe o Número ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-form-group label="Complemento" label-for="obras-complemento">
            <b-form-input
              id="obras-complemento"
              type="text"
              v-model="itemData.complemento"
              maxlength="50"
              placeholder="Informe o Complemento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Latitude" label-for="obras-latitude">
            <b-form-input
              id="obras-latitude"
              type="text"
              v-model="itemData.latitude"
              @keypress="isNumber($event)"
              maxlength="16"
              placeholder="Informe a Latitude ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Longitude" label-for="obras-longitude">
            <b-form-input
              id="obras-longitude"
              type="text"
              v-model="itemData.longitude"
              @keypress="isNumber($event)"
              maxlength="16"
              placeholder="Informe a Longitude ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Objeto do Contrato"
            label-for="obras-obj_contrato"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="obras-obj_contrato"
              type="text"
              v-model="itemData.obj_contrato"
              maxlength="1024"
              required
              placeholder="Informe o Objeto do Contrato ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Obra: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError, setValidCep } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import {
  ufList,
  optionsRegimeExeObra,
  optionsTipoObra,
  optionsTipoServ,
  optionsSetorBenef,
  optionsNaturezaObra,
} from "@/config/lists";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import moment from "moment";

export default {
  name: "ObraData",
  props: ["item", "tipoProposta"],
  components: { TheMask, DatePicker },
  data: function () {
    return {
      userParams: {},
      minExercicio: moment().year() - 5,
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      citiesList_cidade: [],
      ufList: ufList,
      labelsDatePicker: datePickerLocale,
      optionsRegimeExeObra: optionsRegimeExeObra,
      optionsTipoObra: optionsTipoObra,
      optionsTipoServ: optionsTipoServ,
      optionsSetorBenef: optionsSetorBenef,
      optionsNaturezaObra: optionsNaturezaObra,
      listLicitacao: [],
      licitacao: {},
      listContratacao: [],
      listContrato: [],
      listCadas: [],
      cepClass: undefined,
      isCep: undefined,
      isDate: "",
      anoIsValid: "",
      optionsTipo: [
        { value: "1", text: "Tipo 1" },
        { value: "2", text: "Tipo 2" },
      ],
      optionsQualificacao: [
        { value: "01", text: "Engenheiro Civil" },
        { value: "02", text: "Engenheiro Eletricista" },
        { value: "03", text: "Engenheiro de Telecomunicações" },
        { value: "04", text: "Engenheiro – Mecânica" },
        { value: "05", text: "Engenheiro – Minas" },
        { value: "06", text: "Engenheiro – Químico" },
        { value: "07", text: "Engenheiro – Naval" },
        { value: "08", text: "Engenheiro – Outros" },
        { value: "09", text: "Geólogo" },
        { value: "10", text: "Técnico – Edificações" },
        { value: "11", text: "Técnico – Estradas" },
        { value: "12", text: "Técnico – Eletrônica" },
        { value: "13", text: "Técnico – Telecomunicações" },
        { value: "14", text: "Técnico – Eletrotécnico" },
        { value: "15", text: "Técnico – Agrícola" },
        { value: "16", text: "Técnico – Refrigeração" },
        { value: "17", text: "Técnico – Mecânica" },
        { value: "18", text: "Técnico – Mineração" },
        { value: "19", text: "Técnico – Outros" },
        { value: "20", text: "Arquiteto" },
        { value: "99", text: "Outros" },
      ],
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/obras/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.getLicitacao();
          const cep = setValidCep(this.itemData.cep);
          this.cepClass = cep.cepClass;
          this.isCep = cep.isCep;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/obras${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.getLicitacao();
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getEnderecoApi() {
      const cep = setValidCep(this.itemData.cep);
      this.cepClass = cep.cepClass;
      this.isCep = cep.isCep;
      if (this.isCep) {
        const urlCep = `https://viacep.com.br/ws/${this.itemData.cep}/json/`
        axios.get(urlCep)
          .then((obj) => {
            this.cepData = obj.data;
            this.getListaCidades(this.cepData.uf);
          })
          .then(() => {
            if (this.cepData.cep) {
              this.itemData.logradouro = this.cepData.logradouro;
              this.itemData.complemento = this.cepData.complemento;
              this.itemData.bairro = this.cepData.bairro;
              this.itemData.uf = this.cepData.uf;
              this.itemData.cidade = this.cepData.localidade.toUpperCase();
              if (this.cepData.logradouro) this.$refs.nr.focus();
              else this.$refs.logradouro.focus();
            }
          });
      }
    },
    getListaCidades(uf) {
      if (uf) {
        const url = `${baseApiUrl}/cidades/${uf}`;
        axios
          .get(url)
          .then((res) => {
            this.citiesList_cidade = res.data.data.map((data) => {
              return {
                value: data.municipio.toUpperCase(),
                text: data.municipio.toUpperCase(),
              };
            });
          })
          .catch((error) => {
            showError(error);
          });
      }
    },
    getListaCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadas = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListPlanLicitacoes() {
      const url = `${baseApiUrl}/plan-licitacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listLicitacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getLicitacao() {
      const url = `${baseApiUrl}/plan-licitacoes/${this.itemData.id_plan_licit}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.licitacao = res.data;
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListPlanContratacoes() {
      const url = `${baseApiUrl}/plan-contratacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listContratacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contratacao}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListExecContratos() {
      const url = `${baseApiUrl}/exec-contratos`;
      axios
        .patch(url)
        .then((res) => {
          this.listContrato = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contrato}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    setMinAno() {
      if (
        this.itemData.ano &&
        this.itemData.ano.length > 0
      )
        this.anoIsValid =
          this.itemData.ano >= (new Date().getFullYear() - 5) ? "is-valid" : "is-invalid";
      else this.anoIsValid = "";
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaCadastros();
    this.getListExecContratos();
    if (this.tipoProposta == "1") this.getListPlanLicitacoes();
    else if (this.tipoProposta == "2") this.getListPlanContratacoes();
    if (this.itemData.uf) this.getListaCidades(this.itemData.uf);
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
