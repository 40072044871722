<template>
  <div class="plan_licitacao-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="Ano" label-for="plan_licitacoes-exercicio">
            <b-input
              type="number"
              id="plan_licitacoes-exercicio"
              v-model="itemData.exercicio"
              required
              :class="anoIsValid"
              placeholder="Informe o Ano ..."
              :min="minExercicio"
              @input="setMinAnoExercicio"
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Mês" label-for="plan_licitacoes-mes">
            <b-form-select
              id="plan_licitacoes-mes"
              :options="optionsMes"
              v-model="itemData.mes"
              required
              placeholder="Selecione ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-form-group
            label="Unidade Autônoma"
            label-for="plan_licitacoes-id_ua"
          >
            <v-select
              id="plan_licitacoes-id_ua"
              :options="listPUnidade"
              v-model="itemData.id_ua"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe a Unidade Licitante ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nº Licitação"
            label-for="plan_licitacoes-n_licitacao"
          >
            <b-form-input
              id="plan_licitacoes-n_licitacao"
              type="text"
              v-model="itemData.n_licitacao"
              @keypress="isNumber($event)"
              maxlength="16"
              required
              placeholder="Informe o Nº da Licitação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nº do Processo"
            label-for="plan_licitacoes-n_processo"
          >
            <b-form-input
              id="plan_licitacoes-n_processo"
              type="text"
              v-model="itemData.n_processo"
              @keypress="isNumber($event)"
              maxlength="32"
              required
              placeholder="Informe o Número do Processo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Possui Participantes"
            label-for="plan_licitacoes-participantes"
          >
            <b-form-select
              id="plan_licitacoes-participantes"
              :options="optionSimNao"
              v-model="itemData.participantes"
              required
              @input="setOptionsRegPreco"
              placeholder="Informe se Possui Participantes ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Registro Preco"
            label-for="plan_licitacoes-registro_preco"
          >
            <b-form-select
              id="plan_licitacoes-registro_preco"
              :options="optionRegistro"
              v-model="itemData.registro_preco"
              required
              @input="setOptionsModalidadeNatureza"
              placeholder="Informe se é Registro Preço ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Modalidade"
            label-for="plan_licitacoes-modalidade"
          >
            <b-form-select
              id="plan_licitacoes-modalidade"
              :options="optionsModalidadeLic"
              v-model="itemData.modalidade"
              required
              placeholder="Informe a Modalidade ..."
              :disabled="
                !itemData.registro_preco || itemData.registro_preco.length == 0
              "
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Critério de Julgamento"
            label-for="plan_licitacoes-criterio_tipo"
          >
            <b-form-select
              id="plan_licitacoes-criterio_tipo"
              :options="optionsCriterioJulgLic"
              v-model="itemData.criterio_tipo"
              required
              placeholder="Informe o Critério de Julgamento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Natureza Objeto"
            label-for="plan_licitacoes-natureza_obj"
          >
            <b-form-select
              id="plan_licitacoes-natureza_obj"
              :options="optionsNaturezaObj"
              v-model="itemData.natureza_obj"
              required
              placeholder="Informe a Natureza Objeto ..."
              @input="
                () => {
                  if (!['1', '2'].includes(itemData.natureza_obj)) {
                    itemData.regime_exec_obra = undefined;
                    itemData.natureza_obra = undefined;
                  }
                }
              "
              :disabled="
                !itemData.registro_preco || itemData.registro_preco.length == 0
              "
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Regime Execucação Obra"
            label-for="plan_licitacoes-regime_exec_obra"
          >
            <b-form-select
              id="plan_licitacoes-regime_exec_obra"
              :options="optionsRegimeExeObra"
              v-model="itemData.regime_exec_obra"
              :disabled="!['1', '2'].includes(itemData.natureza_obj)"
              placeholder="Informe o Regime de Execucação Obra ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Natureza da Obra"
            label-for="plan_licitacoes-natureza_obra"
          >
            <b-form-select
              id="plan_licitacoes-natureza_obra"
              :options="optionsNaturezaObra"
              v-model="itemData.natureza_obra"
              :disabled="!['1', '2'].includes(itemData.natureza_obj)"
              placeholder="Informe a Natureza da Obra ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Agrupamento"
            label-for="plan_licitacoes-agrupamento"
          >
            <b-form-select
              id="plan_licitacoes-agrupamento"
              :options="optionsAgrupamento"
              v-model="itemData.agrupamento"
              required
              placeholder="Informe o Agrupamento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Código do Programa"
            label-for="plan_licitacoes-cod_programa"
          >
            <b-form-input
              id="plan_licitacoes-cod_programa"
              type="text"
              v-model="itemData.cod_programa"
              maxlength="16"
              placeholder="Informe o Código do Programa ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Garantia" label-for="plan_licitacoes-garantia">
            <b-form-select
              id="plan_licitacoes-garantia"
              :options="optionSimNao"
              v-model="itemData.garantia"
              required
              placeholder="Exige Garantia?"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Valor Previsto"
            label-for="plan_licitacoes-valor_previsto"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor_previsto"
                v-bind="money"
                id="plan_licitacoes-valor_previsto"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Valor Máximo"
            label-for="plan_licitacoes-valor_maximo"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor_maximo"
                v-bind="money"
                id="plan_licitacoes-valor_maximo"
                class="valor-field-right form-control"
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- <b-col md="3" sm="12">
          <b-form-group
            label="Percentual Valor Garantia"
            label-for="plan_licitacoes-percentual_garantia"
          >
            <b-input-group append="%">
              <money
                v-model="itemData.percentual_garantia"
                v-bind="money"
                id="plan_licitacoes-percentual_garantia"
                class="valor-field-right form-control"
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col> -->
        <!-- <b-col md="4" sm="12">
          <b-form-group
            label="Tipo Garantia"
            label-for="plan_licitacoes-tipo_garantia"
          >
            <b-form-select
              id="plan_licitacoes-tipo_garantia"
              :options="optionsTipoGarantia"
              v-model="itemData.tipo_garantia"
              placeholder="Informe o Tipo Garantia ..."
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col md="4" sm="12">
          <b-form-group
            label="Inicio Vigência Garantia"
            label-for="plan_licitacoes-inicio_vig_garantia"
          >
            <date-picker
              v-model="itemData.inicio_vig_garantia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Fim Vigência Garantia"
            label-for="plan_licitacoes-fim_vig_garantia"
          >
            <date-picker
              v-model="itemData.fim_vig_garantia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col> -->
        <b-col md="3" sm="12">
          <b-form-group
            label="Orçamento Proprio"
            label-for="plan_licitacoes-orcamento_proprio"
          >
            <b-form-select
              id="plan_licitacoes-orcamento_proprio"
              :options="optionSimNao"
              v-model="itemData.orcamento_proprio"
              required
              placeholder="Informe o Orçamento Proprio ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group
            label="Veiculo Publicação Edital"
            label-for="plan_licitacoes-vei_pub_edital"
          >
            <b-form-select
              id="plan_licitacoes-vei_pub_edital"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_pub_edital"
              required
              placeholder="Informe o Veiculo Publicação Edital ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Data Publicação Edital"
            label-for="plan_licitacoes-data_pub_edital"
          >
            <date-picker
              v-model="itemData.data_pub_edital"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Justificativa Grupo Lote"
            label-for="plan_licitacoes-just_grupo_lote"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="plan_licitacoes-just_grupo_lote"
              type="text"
              v-model="itemData.just_grupo_lote"
              maxlength="255"
              :required="itemData.agrupamento == '2'"
              placeholder="Justificativa para Agrupamento dos Itens ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Objeto" label-for="plan_licitacoes-objeto">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="plan_licitacoes-objeto"
              type="text"
              v-model="itemData.objeto"
              maxlength="1024"
              required
              placeholder="Objeto da Licitação ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <b-button
          class="ml-2"
          variant="warning"
          v-if="itemData.id"
          @click="showRow"
        >
          {{
            `${itemData.agrupamento == 2 ? "Grupo de " : ""}Itens da Licitação`
          }}
        </b-button>
        <code v-if="itemData.hash" class="float-right">
          Planejamento Licitação: {{ itemData.hash + itemData.tblName }}
        </code>
      </div>
    </b-form>
    <b-modal
      size="xl"
      id="modal-form-plan-itens-grid"
      :title="`${
        itemData.agrupamento == 2 ? 'Grupo de ' : ''
      }Itens da Licitação ${itemData.n_licitacao}`"
      centered
      scrollable
      hide-footer
    >
      <PlanItensGrid
        :item="itemData"
        tipoProposta="1"
        v-if="itemData.agrupamento == 1"
      />
      <PlanGrupoData :item="itemData" v-if="itemData.agrupamento == 2" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import PlanItensGrid from "../planItensAdmin/PlanItensGrid";
import PlanGrupoData from "../planGruposAdmin/PlanGrupoData";
import {
  optionsCriterioJulgLic,
  optionSimNao,
  optionsRegimeExeObra,
  optionsNaturezaObra,
  optionsTipoGarantia,
  optionsVeiculosPub,
  optionsMes,
} from "@/config/lists";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import moment from "moment";

export default {
  name: "PlanLicitacaoData",
  props: ["item"],
  components: { Money, DatePicker, PlanItensGrid, PlanGrupoData },
  data: function () {
    return {
      userParams: {},
      minExercicio: moment().year() - 5,
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      optionsModalidadeLic: [],
      optionsNaturezaObj: [],
      optionRegistro: [],
      optionsCriterioJulgLic: optionsCriterioJulgLic,
      optionSimNao: optionSimNao,
      optionsRegimeExeObra: optionsRegimeExeObra,
      optionsNaturezaObra: optionsNaturezaObra,
      optionsTipoGarantia: optionsTipoGarantia,
      optionsVeiculosPub: optionsVeiculosPub,
      optionsMes: optionsMes,
      isDate: "",
      anoIsValid: "",
      listPUnidade: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      optionsAgrupamento: [
        { value: "1", text: "ITEM" },
        { value: "2", text: "GRUPO/LOTE" },
      ],
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/plan-licitacoes/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.setOptionsModalidadeNatureza();
          this.setOptionsRegPreco();
        });
      } else {
        this.itemData = this.item;
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/plan-licitacoes${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    setOptionsRegPreco() {
      if (this.itemData.participantes == "1") {
        this.optionRegistro = [{ value: "1", text: "SIM" }];
        this.itemData.registro_preco = 1;
      } else {
        this.optionRegistro = [
          { value: "1", text: "SIM" },
          { value: "2", text: "NÃO" },
        ];
      }
    },
    setOptionsModalidadeNatureza() {
      if (this.itemData.registro_preco == "1") {
        this.optionsModalidadeLic = [
          { value: "5", text: "Concorrência" },
          { value: "6", text: "Pregão Presencial" },
          { value: "7", text: "Pregão Eletrônico" },
        ];
        this.optionsNaturezaObj = [
          { value: "3", text: "Compras" },
          { value: "4", text: "Serviços (exceto engenharia)" },
        ];
      } else if (this.itemData.registro_preco == "2") {
        this.optionsModalidadeLic = [
          { value: "1", text: "Convite" },
          { value: "2", text: "Concurso" },
          { value: "3", text: "Credenciamento" },
          { value: "4", text: "Tomada de Preços" },
          { value: "5", text: "Concorrência" },
          { value: "6", text: "Pregão Presencial" },
          { value: "7", text: "Pregão Eletrônico" },
          { value: "8", text: "Leilão" },
          { value: "9", text: "Diálogo Competitivo" },
          { value: "10", text: "Regime Diferenciado de Compras - RDC" },
        ];
        this.optionsNaturezaObj = [
          { value: "1", text: "Obras" },
          { value: "2", text: "Serviços de engenharia" },
          { value: "3", text: "Compras" },
          { value: "4", text: "Serviços (exceto engenharia)" },
          { value: "5", text: "Locação de Imóveis" },
          { value: "6", text: "Concessão" },
          { value: "7", text: "Permissão" },
          { value: "8", text: "Alienação de Bens" },
          { value: "9", text: "Locação de ativos" },
          { value: "10", text: "Parceria Público-Privada" },
        ];
      }
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListaUnidAutonomas() {
      const url = `${baseApiUrl}/und-autonomas`;
      axios
        .patch(url)
        .then((res) => {
          this.listPUnidade = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cnpj} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    showRow(item, index, button) {
      this.$root.$emit("bv::show::modal", "modal-form-plan-itens-grid", button);
    },
    setMinAnoExercicio() {
      if (
        this.itemData.exercicio &&
        this.itemData.exercicio.length > 0
      )
        this.anoIsValid =
          this.itemData.exercicio >= (new Date().getFullYear() - 5) ? "is-valid" : "is-invalid";
      else this.anoIsValid = "";
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaUnidAutonomas();
    this.setOptionsModalidadeNatureza();
    this.setOptionsRegPreco();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
