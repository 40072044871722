<template>
  <div class="home">
    <PageTitle icon="" :main="title" :sub="subTitle" v-if="user && user.id" />
    <Index v-else />
    <b-card title="Seja bem vindo ao iMobil" sub-title="Últimas atualizações do sistema" class="mb-3">
      <b-card-text class="ml-5">
        01 de junho: Liberadas todas as telas de Execução de Contratos e Convênios
      </b-card-text>
      <b-card-text class="ml-5">
        27 de maio: Validação das operações de lançamento de adjudicação e
        lançamento para homologação pelo cliente
      </b-card-text>
      <b-card-text class="ml-5">
        23 de maio: Conclusão da operação de importação de itens por meio de
        arquivo de texto
      </b-card-text>
      <b-card-text class="ml-5">
        20 de maio: Conclusão de operações de Execução de Contratos(Aditivos
        Contratos)
      </b-card-text>
    </b-card>
    <StatsPatrimonio v-if="userParams && userParams.patrimonio > 0" />
    <StatsCPL v-if="userParams && userParams.cpl > 0 && userParams.admin >= 2" />
    <StatsObras v-if="userParams && userParams.obras > 0 && userParams.admin >= 2" />
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import { mapState } from "vuex";
import { baseApiUrl } from "@/env";
import axios from "axios";
import { isBrowser } from "mobile-device-detect";
import Index from "@/components/home/Index";
import StatsPatrimonio from "./StatsPatrimonio";
import StatsCPL from "./StatsCPL";
import StatsObras from "./StatsObras";

export default {
  name: "Home",
  components: { PageTitle, Index, StatsPatrimonio, StatsCPL, StatsObras },
  data: function () {
    return {
      stat: {
        patr_objetos: 0,
        patr_deptos: 0,
        patr_locacoes: 0,
        patr_setores: 0,
        patr_unidades: 0,
        cadastros: 0,
        depreciacao_bens: 0,
        depreciacao_cats: 0,
      },
      userParams: {},
      statsBrowser: "",
      title: `Sistema Municipal de Gestão`,
      subTitle: undefined,
    };
  },
  methods: {
    loadPatrimonios() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patrimonio-count`;
        axios.get(url).then((res) => {
          this.stat.patr_objetos = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadPatrDeptos() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patr-deptos-count`;
        axios.get(url).then((res) => {
          this.stat.patr_deptos = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadPatrDocumentos() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patr-documentos-count`;
        axios.get(url).then((res) => {
          this.stat.patr_documento = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadPatrLocacoes() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patr-locacoes-count`;
        axios.get(url).then((res) => {
          this.stat.patr_locacoes = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadPatrSetores() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patr-setores-count`;
        axios.get(url).then((res) => {
          this.stat.patr_setores = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadUnidAutonomas() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/und-autonomas-count`;
        axios.get(url).then((res) => {
          this.stat.patr_unidades = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadCadastros() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/cadastros-count`;
        axios.get(url).then((res) => {
          this.stat.cadastros = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    setStatsBrowserClass() {
      if (isBrowser) this.statsBrowser = "stats-desktop";
    },
    loadUserParams() {
      if (this.user) {
        const url = `${baseApiUrl}/users/${this.user.id}`;
        axios.get(url).then((res) => {
          this.userParams = res.data;
        });
      }
    },
  },
  mounted() {
    this.loadUserParams();
    this.loadPatrimonios();
    this.loadPatrDeptos();
    this.loadPatrDocumentos();
    this.loadPatrLocacoes();
    this.loadPatrSetores();
    this.loadUnidAutonomas();
    this.loadCadastros();
    this.setStatsBrowserClass();
  },
  computed: mapState(["user"]),
};
</script>

<style>
.stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
