<template>
  <div class="siap-panel">
    <PageTitle icon="far fa-edit" main="Compras Públicas" sub="Execução" />
    <div class="siap-panel-tabs">
      <b-card no-body id="siap-panel">
        <b-tabs card>
          <b-tab title="Contratos" lazy>
            <ExecContratosGrid ref="ExecContratosGrid" />
          </b-tab>
          <b-tab title="Alterações de Atas de Registro de Preço" lazy>
            <ExecAlteracoesGrid ref="ExecAlteracoesGrid" />
          </b-tab>
          <b-tab title="Aditivos a Contratos" lazy>
            <ExecAditivosGrid ref="ExecAditivosGrid" />
          </b-tab>
          <b-tab title="Metas de Execução" lazy>
            <ExecMetasGrid ref="ExecMetasGrid" />
          </b-tab>
          <!-- <b-tab title="Etapas Cronograma" lazy v-if="userParams.admin >= 2">
            <ExecEtapasGrid ref="ExecEtapasGrid" />
          </b-tab> -->
          <!-- <b-tab title="Cronograma Desembolso" lazy v-if="userParams.admin >= 2">
            <ExecDesembolsosGrid ref="ExecDesembolsosGrid" />
          </b-tab> -->
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import ExecContratosGrid from "../execContratosAdmin/ExecContratosGrid";
import ExecAlteracoesGrid from "../execAlteracoesAdmin/ExecAlteracoesGrid";
import ExecAditivosGrid from "../execAditivosAdmin/ExecAditivosGrid";
import ExecMetasGrid from "../execMetasAdmin/ExecMetasGrid";
// import ExecEtapasGrid from "../execEtapasAdmin/ExecEtapasGrid";
// import ExecDesembolsosGrid from "../execDesembolsosAdmin/ExecDesembolsosGrid";
import PageTitle from "../template/PageTitle";

export default {
  name: "ExecAdminPanels",
  components: {
    PageTitle,
    ExecContratosGrid,
    ExecAlteracoesGrid,
    ExecAditivosGrid,
    ExecMetasGrid,
    // ExecEtapasGrid,
    // ExecDesembolsosGrid,
  },
  data: function () {
    return {
      userParams: {},
    };
  },
  methods: {
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
  },

  mounted() {
    this.loadUserParams();
  },

  computed: mapState(["user"]),
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>