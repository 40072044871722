<template>
  <div>
    <b-card no-body>
      <div class="patr_locacoes-grid pdd-10">
        <b-input-group size="md" class="mt-3 mb-3">
          <b-button
            :variant="`outline-${lblBtnNewVariant}`"
            size="sm"
            slot="prepend"
            @click="newRow"
          >
            <i class="fa fa-plus"></i>&nbsp;
            <span v-if="isBrowser">{{ lblBtnNew }}</span>
          </b-button>
          <b-form-input
            type="text"
            placeholder="Digite aqui para localizar o registro..."
            v-b-tooltip.hover
            :title="`Digite ao menos ${charSearchCount} caracteres para pesquisa dinâmica e use espaços para pesquisar por mais de uma expressão`"
            v-model="keyword"
            @input="searchPatrLocacoes(charSearchCount)"
            @blur="searchPatrLocacoes(1)"
            ref="keyword"
          />
          <b-btn size="sm" @click="searchPatrLocacoes(1)">
            <i class="fa fa-search"></i>
          </b-btn>
          <b-input-group-text slot="append">
            <span v-if="isBrowser">{{ keyword_res }}&nbsp;</span>
            <b-btn
              :disabled="!keyword"
              variant="link"
              size="sm"
              @click="getRefresh"
              class="p-0"
            >
              <i class="fa fa-remove"></i>
            </b-btn>
          </b-input-group-text>
        </b-input-group>
        <b-table
          hover
          striped
          responsive
          :items="patr_locacoes"
          :fields="fields"
        >
          <template v-slot:cell(nome)="data" v-if="userParams.admin >= 1">
            <span v-html="`${data.item.nome || 'Servidor não informado'} (${data.item.id})`" />
          </template>
          <template v-slot:cell(nome)="data" v-else>
            <span v-html="`${data.item.nome || 'Servidor não informado'}`" />
          </template>
          <template #cell(departamento)="data">
            <span v-html="data.item.departamento" />
          </template>
          <template #cell(actions)="row">
            <b-button
              v-if="userParams.id >= 1"
              variant="outline-info"
              size="sm"
              class="mr-1"
              v-b-tooltip.hover
              title="Editar registro"
              @click="showRow(row.item, row.index, $event.target)"
              href="#header"
            >
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button
              v-if="userParams.id >= 1"
              variant="outline-danger"
              size="sm"
              v-b-tooltip.hover
              title="Remover registro"
              @click="remove(row.item.id)"
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination
          size="md"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
        />
      </div>
    </b-card>
    <b-modal
      size="xl"
      id="modal-form"
      :title="modalTitle"
      centered
      scrollable
      hide-footer
    >
      <PatrLocacaoData
        :item="patrLocacaoInputs"
        @objectInputs="loadPatrLocacoes"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import { getDecimalFormater } from "@/config/globalFacilities";
import { showError, highlight, removeMark } from "@/global";
import PatrLocacaoData from "./PatrLocacaoData";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "PatrLocacoesGrid",
  components: { PatrLocacaoData },
  data: function () {
    return {
      userParams: {},
      charSearchCount: 3,
      modalTitle: "Novo Registro",
      patr_locacoes: [], // table
      patr_locacao: {}, // field
      patrLocacaoInputs: {
        id_patr_tr: "0",
      }, // form data
      loading: false,
      lblBtnNew: "Novo Registro",
      lblBtnNewVariant: "primary",
      mode: "save",
      keyword: "",
      isBrowser: isBrowser,
      keyword_res: "",
      lblCancelar: "Cancelar",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        { key: "nome", label: "Cadastro", sortable: true },
        {
          key: "departamento",
          label: "Departamento",
          sortable: true,
        },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions2",
          tdClass: "col-actions2",
        },
      ],
    };
  },
  methods: {
    searchPatrLocacoes(length) {
      if (
        this.keyword.toString().length >= length ||
        this.keyword.toString().length == 0
      )
        this.loadPatrLocacoes();
    },
    loadPatrLocacoes() {
      const url = `${baseApiUrl}/patr-locacoes?page=${this.page}&key=${this.keyword}`;
      axios.get(url).then((res) => {
        this.patr_locacoes = res.data.data;
        this.patr_locacoes.forEach((element) => {
          element.departamento = highlight(
            element.departamento.toUpperCase(),
            this.keyword.toString().toUpperCase()
          );
          element.nome = highlight(
            element.nome,
            this.keyword.toString().toUpperCase()
          );
        });
        this.count = res.data.count;
        this.limit = res.data.limit;
        const pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${pluralize}`;
      });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.loadPatrLocacoes();
    },
    reset() {
      this.keyword = "";
      this.mode = "save";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.getRefresh();
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    newRow() {
      this.patrLocacaoInputs = {
        id_patr_tr: "0",
      };
      this.modalTitle = `Novo Registro`;
      this.$root.$emit("bv::show::modal", "modal-form");
    },
    showRow(item, index, button) {
      Object.entries(item).forEach(([key, value]) => {
        if (item)
          this.patrLocacaoInputs = {
            ...this.patrLocacaoInputs,
            [key]: removeMark(value || ""),
          };
      });
      this.modalTitle = `Registro: ${this.patrLocacaoInputs.nome}`;
      this.$root.$emit("bv::show::modal", "modal-form", button);
    },
    remove(id) {
      const url = `${baseApiUrl}/patr-locacoes/${id}`;
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.patrLocacaoInputs = { id_patr_tr: "0" };
                this.loadPatrLocacoes();
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
  },

  mounted() {
    this.loadUserParams();
    this.loadPatrLocacoes();
  },
  watch: {
    page() {
      this.loadPatrLocacoes();
    },
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.patr_locacoes-data {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>