<template>
  <div class="plan_item-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group label="Categoria do Item" label-for="plan_itens-id_itens_categorias">
            <v-select
              id="plan_itens-id_itens_categorias"
              :options="optionCategorias"
              v-model="itemData.id_itens_categorias"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe a Categoria ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" v-if="!modeImport">
          <b-form-group label="Nº do Item" label-for="plan_itens-n_item">
            <b-form-input id="plan_itens-n_item" type="text" v-model="itemData.n_item" @keypress="isNumber($event)"
              maxlength="10" required placeholder="Informe o Nº do Item ..." />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" v-if="!modeImport">
          <b-form-group label="Quantidade" label-for="plan_itens-quantidade">
            <b-form-input id="plan_itens-quantidade" type="text" v-model="itemData.quantidade"
              @keypress="isNumber($event)" maxlength="10" required placeholder="Informe a Quantidade ..." />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12" v-if="!modeImport">
          <b-form-group label="Valor Unitário" label-for="plan_itens-valor_unit_estimado">
            <b-input-group prepend="R$">
              <money v-model="itemData.valor_unit_estimado" v-bind="money" id="plan_itens-valor_unit_estimado"
                class="valor-field-right form-control">
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12" v-if="!modeImport">
          <b-form-group label="Unidade Medida" label-for="plan_itens-unid_medida">
            <b-form-input id="plan_itens-unid_medida" type="text" v-model="itemData.unid_medida" maxlength="255"
              required placeholder="Informe a Unidade de Medida ..." />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12" v-if="modeImport">
          <code>
            Inclusão de lote de itens seguindo a seguinte ordem informada abaixo e com colunas separadas por ";" (ponto e vírgula)
          </code>
          <br />
          <code v-html="`>>> Item;Descrição;Unidade de medida;Quantidade;Valor Unitário!!! <<<`" />
          <br />
          <code>### Importante: Item e Quantidade - Informar apenas números inteiros (1,2,3,4,5...)!!!</code>
          <br />
          <code>
            ### Importante 02: Valor - Informar apenas números em formato decimal separado por virgula (0,00)
          </code>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="plan_itens-descricao">
            <b-form-textarea rows="7" max-rows="7" id="plan_itens-descricao" type="text" v-model="itemData.descricao"
              :maxlength="modeImport ? 9999999999 : 1024" required placeholder="Informe a Descrição ..." />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button variant="primary" class="float-left" v-if="mode === 'save' && !modeImport" @click="save">
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button variant="success" class="ml-2" @click="save('validar')" v-if="modeImport">Validar Lote de Itens
        </b-button>
        <b-button variant="warning" class="ml-2" @click="save('executar')" v-if="modeImport" :disabled="!isValid">
          Importar Lote de Itens</b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right">Item Licitação: {{ itemData.hash + itemData.tblName }}</code>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";
import { Money } from "v-money";

export default {
  name: "PlanItemData",
  props: ["item", "tipoProposta", "modeImport"],
  components: { Money },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isValid: false,
      isDate: "",
      optionCategorias: [],
      licitacao: {},
      listContratacao: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/plan-itens/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      } else {
        this.itemData = {
          id_plan_licit: this.item.id_plan_licit,
          id_plan_contrat: this.item.id_plan_contrat,
        };
      }
      this.getLicitacao();
    },
    save(mode) {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id
        ? `/${this.itemData.id}`
        : `/${this.itemData.id_plan_licit || 0}/${this.itemData.id_plan_contrat || 0
        }`;
      this.itemData.mode = mode;
      axios[method](`${baseApiUrl}/plan-itens${id}`, this.itemData)
        .then((res) => {
          if (["validar", "executar"].includes(mode)) {
            this.$toasted.global.defaultSuccess({ msg: res.data.data });
            if (this.isValid && mode == "executar") this.reset();
          } else {
            this.itemData = res.data;
            this.getLicitacao();
            this.$toasted.global.defaultSuccess();
          }
          this.isValid = res.data.isValid || false;
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form-plan-item-data");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getLicitacao() {
      const url = `${baseApiUrl}/plan-licitacoes/${this.itemData.id_plan_licit}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.licitacao = res.data;
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListPlanContratacoes() {
      const url = `${baseApiUrl}/plan-contratacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listContratacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contratacao}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaCategorias() {
      const url = `${baseApiUrl}/plan-categorias`;
      axios
        .patch(url)
        .then((res) => {
          this.optionCategorias = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaCategorias();
    if (this.tipoProposta == "1") this.getListPlanLicitacoes();
    else if (this.tipoProposta == "2") this.getListPlanContratacoes();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}

.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
