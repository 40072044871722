<template>
  <div class="editais-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="4" sm="12">
          <b-form-group
            :label="`Nº do Edital${
              userParams.admin >= 1 && itemData.id
                ? ' (' + itemData.id + ')'
                : ''
            }`"
            label-for="editais-nr_edital"
          >
            <b-form-input
              id="editais-nr_edital"
              type="text"
              v-model="itemData.nr_edital"
              required
              maxlength="50"
              placeholder="Informe o número do edital ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-form-group
            label="Descrição Curta"
            label-for="editais-descricao_curta"
          >
            <b-form-input
              id="editais-descricao_curta"
              type="text"
              v-model="itemData.descricao_curta"
              maxlength="255"
              required
              placeholder="Descreva o edital ..."
            />
          </b-form-group>
        </b-col>
        <b-col :md="itemData.id ? '8' : '12'" sm="12">
          <b-form-group
            label="Descrição Longa"
            label-for="editais-descricao_longa"
          >
            <VueEditor
              id="editais-descricao_longa"
              v-model="itemData.descricao_longa"
              required
              placeholder="Descreva o edital ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12" v-if="itemData.id || formFiles">
          <b-form-group
            label="Arquivos (jpg, png, gif, docx, xlsx, pptx, pdf, txt, zip, rar)"
            label-for="editais-arquivos"
          >
            <b-form-file
              v-if="itemUploads"
              v-model="itemUploads"
              :state="Boolean(itemUploads)"
              accept=".jpg, .png, .gif, .docx, .doc, .xlsx, .xls, .pptx, .ppt, .pdf, .txt, .zip, .rar"
              placeholder="Escolha ou arraste arquivos para aqui..."
              drop-placeholder="Arraste arquivos aqui..."
              multiple
              :file-name-formatter="formatNames"
            ></b-form-file>
          </b-form-group>
          <div class="clearfix">
            <b-row
              v-if="
                (this.itemFiles && this.itemFiles.length > 0) ||
                this.filesExists
              "
            >
              <b-col sm="12" md="12" v-for="file in itemFiles" :key="file.id">
                <b-button
                  class="float-left text-left text-wrap"
                  style="width: 70%"
                  @click="downloadFile(file.href, file.file_caption)"
                  :variant="file.status == 10 ? 'link' : 'disabled'"
                >
                  {{ file.file_caption }}.{{ file.file_ext }}
                </b-button>
                <b-button
                  variant="danger"
                  class="float-right"
                  v-b-tooltip.hover
                  title="Remover arquivo"
                  @click="removeFile(file)"
                >
                  <i class="fa fa-trash"></i>
                </b-button>
                <b-button
                  :variant="`${file.status == 10 ? 'warning' : 'success'}`"
                  class="float-right"
                  v-b-tooltip.hover
                  :title="`${
                    file.status == 10 ? 'Suspender' : 'Reativar'
                  } download do arquivo`"
                  @click="
                    file.status == 10 ? suspendFile(file) : activeFile(file)
                  "
                >
                  <i class="fas fa-ban" v-if="file.status == 10"></i>
                  <i class="far fa-check-circle" v-else></i>
                </b-button>
                <b-button
                  variant="primary"
                  class="float-right"
                  v-b-tooltip.hover
                  title="Download do arquivo"
                  @click="downloadFile(file.href, file.file_caption)"
                >
                  <i class="fas fa-file-download"></i>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <span v-if="itemUploads && itemUploads.length > 0"
            >Arquivos a enviar</span
          >
          <ul v-if="itemUploads && itemUploads.length > 0">
            <li v-for="file in itemUploads" :key="file.id">{{ file.name }}</li>
          </ul>
          <b-button class="mt-2" variant="outline-primary" @click="submitFile">
            Enviar{{ itemUploads && itemUploads.length > 1 ? " +" : "!" }}
          </b-button>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset" v-if="itemData.id"
          >Cancelar</b-button
        >
        <b-button
          variant="outline-success"
          class="ml-2"
          @click="preview"
          v-if="itemData.id"
          >Preview da Publicação</b-button
        >
        <code v-if="itemData.hash" class="float-right">
          Edital: {{ itemData.hash + itemData.tblName }}
        </code>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError, downloadFile, setValidCep } from "@/global";
import { baseApiUrl, baseFrotendUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";
import { VueEditor } from "vue2-editor";

export default {
  name: "EditaisData",
  props: ["item", "userParams"],
  components: { VueEditor },
  data: function () {
    return {
      itemData: {},
      formFiles: false,
      downloadFile: downloadFile,
      formData: new FormData(),
      itemFiles: [],
      itemUploads: [],
      filesExists: false,
      count: 0,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      setValidCep: setValidCep,
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/editais/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.loadFiles(this.itemData);
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/editais${id}`, this.itemData)
        .then((res) => {
          if (!this.itemData.id) this.itemData.id = res.data.id;
          this.formFiles = true;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
          this.$bvModal.hide("modal-form");
        })
        .catch((error) => {
          showError(error);
        });
    },
    submitFile() {
      if (this.itemUploads) {
        this.formData = new FormData();
        for (let file of this.itemUploads) {
          this.formData.append(`files`, file);
        }
        axios
          .patch(`${baseApiUrl}/editais/${this.itemData.id}`, this.formData)
          .then(() => {
            this.formData = new FormData();
            this.$emit("objectInputs");
            this.$toasted.global.defaultSuccess();
            this.loadFiles(this.itemData);
            this.filesExists = true;
          })
          .catch(showError);
      }
    },
    removeFile(item) {
      this.$confirm({
        message: `A exclusão é irreversível. Confirma a exclusão do arquivo?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            const url = `${baseApiUrl}/editais-uploads/${this.itemData.id_editais}/${item.id}`;
            axios
              .delete(url)
              .then(() => {
                this.loadFiles(this.itemData);
                this.$emit("objectInputs");
                this.$toasted.global.defaultSuccess({
                  msgs: "Arquivo excluído com sucesso",
                });
              })
              .catch(showError);
          }
        },
      });
    },
    suspendFile(item) {
      const url = `${baseApiUrl}/editais-uploads/${this.itemData.id_editais}/${item.id}?susp=susp`;
      axios
        .delete(url)
        .then(() => {
          this.loadFiles(this.itemData);
          this.$emit("objectInputs");
          this.$toasted.global.defaultSuccess({
            msgs: "Arquivo suspenso com sucesso",
          });
        })
        .catch(showError);
    },
    activeFile(item) {
      const url = `${baseApiUrl}/editais-uploads/${this.itemData.id_editais}/${item.id}?susp=active`;
      axios
        .delete(url)
        .then(() => {
          this.loadFiles(this.itemData);
          this.$emit("objectInputs");
          this.$toasted.global.defaultSuccess({
            msgs: "Arquivo suspenso com sucesso",
          });
        })
        .catch(showError);
    },
    loadFiles(item) {
      const url = `${baseApiUrl}/editais-uploads/${this.userParams.cliente}/${this.userParams.dominio}/${item.id}?susp=susp`;
      axios.get(url).then((res) => {
        this.itemFiles = res.data.data;
        this.itemFiles.forEach((element) => {
          element.name = element.file_name;
          element.href = `${baseFrotendUrl}/assets/uploads/${this.userParams.cliente}/${this.userParams.dominio}/${item.id}/${element.file_name}`;
        });
      });
      setTimeout(() => {
        this.itemUploads = [];
      }, 2000);
    },
    preview() {
      const route = `${baseFrotendUrl}/editais-publicos/${this.userParams.cliente}/${this.userParams.dominio}/${this.itemData.hash}`;
      window.open(route, "_blank");
      console.log(route)
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    formatNames(itemFiles) {
      return itemFiles.length === 1
        ? itemFiles[0].name
        : `${itemFiles.length} arquivos escolhidos`;
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped lang="css">
input[type="email"] {
  text-transform: lowercase;
}
.custom-file-input:lang(pt-BR) ~ .custom-file-label::after {
  content: "Selecionar";
}
.form-group > .input-group-flex-30 {
  flex: 0 0 30%;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
