<template>
  <div class="patr_locacao-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Setor / Departamento"
            label-for="patr_locacoes-id_patr_depto"
          >
            <v-select
              id="patr_locacoes-id_patr_depto"
              :options="listDep"
              v-model="itemData.id_patr_depto"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o Departamento..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12" hidden>
          <b-form-group
            label="Termo de Responsabilidade"
            label-for="patr_locacoes-id_patr_tr"
          >
            <b-form-input
              id="patr_locacoes-id_patr_tr"
              type="text"
              v-model="itemData.id_patr_tr"
              @keypress="isNumber($event)"
              required
              maxlength="20"
              placeholder="Informe Termo de Responsabilidade ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Servidor"
            label-for="patr_locacoes-id_servidor"
          >
            <v-select
              id="patr_locacoes-id_servidor"
              :options="listServidor"
              v-model="itemData.id_servidor"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o Servidor..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Observação" label-for="patr_locacoes-observacao">
            <b-form-textarea
              id="patr_locacoes-observacao"
              rows="4"
              v-model="itemData.observacao"
              maxlength="1024"
              placeholder="Informe Observação ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset"> Cancelar </b-button>
        <code v-if="itemData.hash" class="float-right"
          >Locação de Objeto: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";

export default {
  name: "PatrLocacaoData",
  props: ["item"],
  data: function () {
    return {
      userParams: {},
      itemData: {
        id_patr_tr: "0",
      },
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listDep: [],
      listServidor: [],
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/patr-locacoes/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/patr-locacoes${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
          this.$bvModal.hide("modal-form");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListaPatrDeptos() {
      const url = `${baseApiUrl}/patr-deptos`;
      axios
        .patch(url)
        .then((res) => {
          this.listDep = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.setor} > ${data.departamento}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaCadServidores() {
      const url = `${baseApiUrl}/cad-servidores`;
      axios
        .patch(url)
        .then((res) => {
          this.listServidor = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaCadServidores();
    this.getListaPatrDeptos();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
