<template>
  <div>
    <b-overlay :show="downloading" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <Loading />
          <p id="cancel-label">Aguarde só um pouco &#128521;</p>
          <p id="cancel-label">O arquivo está no forno e chega já ...</p>
        </div>
      </template>
      <b-form>
        <b-row>
          <b-col offset="4" md="4" sm="12">
            <h2 class="mt-5 mb-5 text-center">Baixar Arquivos SIAP / Compras Públicas</h2>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col offset="2" md="8" sm="12">
            <b-form-group label="Selecione a Unidade" label-for="arquivos-cardug">
              <v-select id="arquivos-cardug" :options="listUnidades" v-model="item.cardug" :reduce="(label) => label.code"
                @input="activeDownload" required placeholder="Informe a Unidade Autonôma ...">
                <div slot="no-options">
                  A opção digitada não existe!!! Tente novamente...
                </div>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col offset="2" md="3" sm="12">
            <b-form-group label="Exercício" label-for="arquivos-exercicio">
              <b-form-select id="arquivos-exercicio" type="text" v-model="item.exercicio" :options="optionExercicios"
                @input="activeDownload(); getExercicios();" required placeholder="Informe o exercício ..." />
            </b-form-group>
          </b-col>
          <b-col md="3" sm="12">
            <b-form-group label="Mes" label-for="arquivos-mes">
              <b-form-select id="arquivos-mes" type="text" v-model="item.mes" :options="optionMes" @input="activeDownload"
                required placeholder="Informe o mes ..." />
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12">
            <b-form-group label="." label-class="invis" label-for="arquivos-mes">
              <b-button @click="downloadFiles" variant="outline-success" block :disabled="disabled || downloading">
                Baixar Arquivos <i class="fas fa-file-download"></i>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { showError, downloadFile } from "@/global";
import { baseApiUrl } from "@/env";
import axios from "axios";
import Loading from "@/components/template/Loading";

export default {
  name: "Arquivos",
  props: ["userParams"],
  components: { Loading },
  data: function () {
    return {
      item: {},
      optionExercicios: [],
      optionMes: [],
      listUnidades: [],
      disabled: true,
      downloading: false,
      Loading: false,
      downloadFile: downloadFile,
    };
  },
  methods: {
    getListaUnidades() {
      const url = `${baseApiUrl}/und-autonomas`;
      axios
        .patch(url)
        .then((res) => {
          this.listUnidades = res.data.data.map((data) => {
            return {
              code: data.cardug,
              label: `(${data.cnpj})- ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getExercicios() {
      this.optionExercicios = []
      const today = new Date()
      const anoAtual = today.getFullYear()
      for (let index = 0; index < 5; index++) {
        this.optionExercicios.push({ value: anoAtual - index, text: anoAtual - index })
      }
      this.getMes()
    },
    getMes() {
      const today = new Date()
      const mesAtual = today.getMonth()
      this.optionMes = []
      if (new Date().getFullYear() == this.item.exercicio) {
        for (let index = 0; index < mesAtual + 1; index++) {
          this.optionMes.push({ value: mesAtual + 1 - index, text: mesAtual + 1 - index })
        }
      } else {
        this.optionMes = [
          { value: 1, text: 1 },
          { value: 2, text: 2 },
          { value: 3, text: 3 },
          { value: 4, text: 4 },
          { value: 5, text: 5 },
          { value: 6, text: 6 },
          { value: 7, text: 7 },
          { value: 8, text: 8 },
          { value: 9, text: 9 },
          { value: 10, text: 10 },
          { value: 11, text: 11 },
          { value: 12, text: 12 }
        ]
      }
    },
    // getExercicios() {
    //   this.optionExercicios = []
    //   const today = new Date()
    //   const anoAtual = today.getFullYear()
    //   for (let index = 0; index < 5; index++) {
    //     this.optionExercicios.push({ value: anoAtual - index, text: anoAtual - index })
    //   }
    //   this.getMes()
    // },
    // getMes() {
    //   const today = new Date()
    //   const mesAtual = today.getMonth()
    //   this.optionMes = []
    //   if (new Date().getFullYear() == this.item.exercicio) {
    //     for (let index = 0; index < mesAtual + 1; index++) {
    //       this.optionMes.push({ value: mesAtual + 1 - index, text: mesAtual + 1 - index })
    //     }
    //   } else {
    //     this.optionMes = [
    //       { value: 1, text: 1 },
    //       { value: 2, text: 2 },
    //       { value: 3, text: 3 },
    //       { value: 4, text: 4 },
    //       { value: 5, text: 5 },
    //       { value: 6, text: 6 },
    //       { value: 7, text: 7 },
    //       { value: 8, text: 8 },
    //       { value: 9, text: 9 },
    //       { value: 10, text: 10 },
    //       { value: 11, text: 11 },
    //       { value: 12, text: 12 }
    //     ]
    //   }
    // },
    downloadFiles() {
      this.downloading = true;
      let url = `${baseApiUrl}/compras-publicas-xml`;
      axios
        .post(url, this.item)
        .then((body) => {
          setTimeout(() => {
            url = `${baseApiUrl}/compras-publicas-xml/f-a/szf`;
            axios
              .post(url, body.data)
              .then((body) => {
                this.$toasted.global.defaultSuccess({
                  msg: `Arquivo ${body.data.data.zipNameFile} baixado com sucesso!`,
                });
                const urlDown = `${baseApiUrl}/${body.data.data.zipUrlFile}`;
                this.downloadFile(urlDown, body.data.data.zipNameFile);
                this.downloading = false;
              })
              .catch((error) => {
                showError({ msg: error });
                console.log(error);
              });
          }, 3000);
        })
        .catch((error) => {
          showError({ msg: error });
          console.log(error);
        });
    },
    activeDownload() {
      this.disabled = !(
        this.item.cardug &&
        this.item.exercicio &&
        this.item.mes
      );
    },
  },
  mounted() {
    this.getListaUnidades();
    this.getExercicios();
  },
  computed: mapState(["user"]),
};
</script>

<style>
.invis {
  color: white;
}
</style>