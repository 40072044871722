<template>
  <div class="conv_situacao-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nº do Convênio"
            label-for="conv_situacoes-id_conv_cv"
          >
            <v-select
              id="conv_situacoes-id_conv_cv"
              :options="listConvenio"
              v-model="itemData.id_conv_cv"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Termo do Convênio ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data de Mudança"
            label-for="conv_convenios-data"
          >
            <date-picker
              v-model="itemData.data"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="9" sm="12">
          <b-form-group label="Situacao" label-for="conv_situacoes-situacao">
            <b-form-select
              id="conv_situacoes-situacao"
              :options="optionsSituacaoConv"
              v-model="itemData.situacao"
              required
              placeholder="Informe a Situação do Convênio ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="conv_situacoes-descricao">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="conv_situacoes-descricao"
              type="text"
              v-model="itemData.descricao"
              maxlength="1024"
              required
              placeholder="Informe a Descrição da Nova Situação..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Situação Convênio: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import moment from "moment";
import { optionsSituacaoConv } from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ConvSituacaoData",
  props: ["item"],
  components: { DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      labelsDatePicker: datePickerLocale,
      listConvenio: [],
      optionsSituacaoConv: optionsSituacaoConv,
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/conv-situacoes/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/conv-situacoes${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    setDataPt(data) {
      return moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListConvenios() {
      const url = `${baseApiUrl}/conv-convenios`;
      axios
        .patch(url)
        .then((res) => {
          this.listConvenio = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_termo} - ${data.ano}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListConvenios();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
