<template>
  <div class="obra_licenca-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nº do Processo"
            label-for="obras_licencas-id_obra"
          >
            <v-select
              id="obras_licencas-id_obra"
              :options="listProcesso"
              v-model="itemData.id_obra"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº do Processo ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Tipo" label-for="obras_licencas-tipo">
            <b-form-select
              id="obras_licencas-tipo"
              :options="optionsTipoLicenca"
              v-model="itemData.tipo"
              required
              placeholder="Informe o Tipo de Licença..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Compensação Ambiental"
            label-for="obras_licencas-compensacao"
          >
            <b-form-select
              id="obras_licencas-compensacao"
              :options="optionSimNao"
              v-model="itemData.compensacao"
              required
              placeholder="Existe Compensação Ambiental para a Obra? ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Valor Compensação"
            label-for="obras_licencas-valor_comp"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor_comp"
                v-bind="money"
                id="obras_licencas-valor_comp"
                class="valor-field-right form-control"
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Unidade Autônoma Interessada"
            label-for="obras_licencas-id_ua"
          >
            <v-select
              id="obras_licencas-id_ua"
              :options="listUnidades"
              v-model="itemData.id_ua"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe a Unidade Autônoma Interessada..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Empreendimento"
            label-for="obras_licencas-empreendimento"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="obras_licencas-empreendimento"
              type="text"
              v-model="itemData.empreendimento"
              maxlength="255"
              required
              placeholder="Informe o Nome do Empreendimento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Endereço" label-for="obras_licencas-endereco">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="obras_licencas-endereco"
              type="text"
              v-model="itemData.endereco"
              maxlength="255"
              required
              placeholder="Informe o Nome do Endereco ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Tipo Compensação"
            label-for="obras_licencas-tipo_comp"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="obras_licencas-tipo_comp"
              type="text"
              v-model="itemData.tipo_comp"
              maxlength="255"
              placeholder="Descritivo do Tipo de Compensação ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Licença Ambiental: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";
import { optionsTipoLicenca, optionSimNao } from "@/config/lists";
import { Money } from "v-money";

export default {
  name: "ObraLicencaData",
  props: ["item"],
  components: { Money },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listProcesso: [],
      listUnidades: [],
      optionsTipoLicenca: optionsTipoLicenca,
      optionSimNao: optionSimNao,
      isDate: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/obras-licencas/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/obras-licencas${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListObras() {
      const url = `${baseApiUrl}/obras`;
      axios
        .patch(url)
        .then((res) => {
          this.listProcesso = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaUnidAutonomas() {
      const url = `${baseApiUrl}/und-autonomas`;
      axios
        .patch(url)
        .then((res) => {
          this.listUnidades = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cnpj} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListObras();
    this.getListaUnidAutonomas();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
