<template>
  <div class="alm_estoque-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col :md="itemData.id ? '2' : '3'" sm="12">
          <b-form-group label="Cod Processo" label-for="alm_estoques-id_alm_proc">
            <v-select id="alm_estoques-id_alm_proc" :options="listAlmoProc" v-model="itemData.id_alm_proc"
              :reduce="(label) => label.code" disabled placeholder="Informe o Nome ...">
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" v-if="itemData.id">
          <b-form-group label="Código Estoque" label-for="alm_estoques-codigo">
            <b-form-input id="alm_estoques-codigo" type="text" v-model="itemData.codigo" @keypress="isNumber($event)"
              readonly placeholder="Informe o Código ..." />
          </b-form-group>
        </b-col>
        <b-col :md="itemData.id ? '6' : '7'" sm="12">
          <b-form-group :label="`Item${userParams.admin && itemData.id
          ? ` (${itemData.id})`
          : ''
          }`" label-for="alm_estoques-id_pl_it">
            <v-select id="alm_estoques-id_pl_it" :options="listItem" v-model="itemData.id_pl_it"
              :reduce="(label) => label.code" :disabled="mode === 'remove'" required placeholder="Informe o Item ...">
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Unidade" label-for="alm_estoques-unidade">
            <b-form-input id="alm_estoques-unidade" type="text" v-model="itemData.unidade" required
              placeholder="Informe a Unidade ..." />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Quantidade Adquirida" label-for="alm_estoques-quantidade">
            <b-form-input id="alm_estoques-quantidade" type="text" v-model="itemData.quantidade"
              @keypress="isNumber($event)" required :class="`form-control ${qtdAdq}`" @input="setQtdAdquirida"
              placeholder="Informe a Quantidade ..." />
          </b-form-group>
        </b-col>
        <!-- @input="getQntItem" -->
        <b-col md="4" sm="12">
          <b-form-group label="Quantidade Mínima" label-for="alm_estoques-qtd_minima">
            <b-form-input id="alm_estoques-qtd_minima" type="text" v-model="itemData.qtd_minima"
              @keypress="isNumber($event)" @input="setQtdAdquirida" required :class="`form-control ${qtdMin}`"
              placeholder="Informe a Quantidade Mínima..." />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Valor Unitário" label-for="alm_estoques-valor_unitario">
            <b-input-group prepend="R$">
              <money v-model="itemData.valor_unitario" v-bind="money" id="alm_estoques-valor_unitario"
                class="valor-field-right form-control" required>
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="alm_estoques-descricao">
            <b-form-textarea rows="7" max-rows="7" id="alm_estoques-descricao" type="text" v-model="itemData.descricao"
              maxlength="1024" required placeholder="Informe a Descrição ..." />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-alert show variant="danger" v-if="qtdAdqLabel && qtdAdqLabel.length > 0">
            {{ qtdAdqLabel }}
          </b-alert>
        </b-col>
        <b-col md="6" sm="12">
          <b-alert show variant="danger" v-if="qtdMin == 'is-invalid'">
            Quantidade mínima atingida!
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="texti">
          <b-card bg-variant="light" no-body class="text-right">
            <h4 class="float-right">
              Estoque Real: {{ estoqueReal || 0 }} ({{
                  (estoqueReal * itemData.valor_unitario).toLocaleString('pt-br', {
                    style: 'currency', currency:
                      'BRL'
                  })
              }})
            </h4>
            <h4 class="float-right">
              Estoque Contábil: {{ estoqueContabil || 0 }} ({{
                  (estoqueContabil * itemData.valor_unitario).toLocaleString('pt-br', {
                    style: 'currency', currency:
                      'BRL'
                  })
              }})
            </h4>
          </b-card>
        </b-col>
      </b-row>
      <code v-if="itemData.hash" class="float-right">
        Estoque: {{ itemData.hash + itemData.tblName }}
      </code>
      <div class="clearfix">
        <b-button variant="primary" class="float-left" v-if="mode === 'save'" @click="save" :disabled="!isValid">
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <b-button class="ml-2" variant="warning" v-if="itemData.id" @click="showRow">
          Movimentos do Estoque
        </b-button>
      </div>
    </b-form>
    <b-modal size="xl" id="modal-form-movimentos-grid" :title="`Movimentos do Estoque`" centered scrollable hide-footer>
      <AlmMovimentosGrid :item="itemData" :estoqueContabil="estoqueContabil" @objectInputs="loadData" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";
import { Money } from "v-money";
import AlmMovimentosGrid from "../almMovimentosAdmin/AlmMovimentosGrid";

export default {
  name: "AlmEstoqueData",
  props: ["item"],
  components: { Money, AlmMovimentosGrid },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      isValid: true,
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listContratacao: [],
      listItem: [],
      listAlmoProc: [],
      estoqueReal: 0,
      estoqueContabil: 0,
      qtdAdq: "",
      qtdAdqLabel: "",
      qtdMin: "",
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    async loadData() {
      if (this.itemData.id) {
        const url = `${baseApiUrl}/alm-estoques/${this.itemData.id_alm_proc}/${this.itemData.id}`;
        await axios.get(url).then(async (body) => {
          this.itemData = body.data;
          await this.estoque(this.itemData.id)
        });
      } else
        this.itemData = this.item;
      await this.getListPlanItens();
      await this.setQtdAdquirida();
    },
    estoque(id) {
      // Estoque Real
      axios
        .post(`${baseApiUrl}/alm-movimentos/f-a/ger`, {
          id_alm_estq: id,
        })
        .then((body) => {
          this.estoqueReal = body.data.quantidadeReal;
        });
      // Estoque Contábil
      axios
        .post(`${baseApiUrl}/alm-movimentos/f-a/gec`, {
          id_alm_estq: id,
        })
        .then((body) => {
          this.estoqueContabil = body.data.quantidadeContabil;
        });
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id
        ? `/${this.itemData.id_alm_proc}/${this.itemData.id}`
        : `/${this.itemData.id_alm_proc}`;
      if (!this.itemData.id) {
        this.itemData = {
          ...this.itemData,
          id_pl_lic: this.item.id_pl_lic,
          id_pl_cont: this.item.id_pl_cont,
          id_alm_proc: this.item.id_alm_proc,
        };
      }
      if (this.isValid)
        axios[method](`${baseApiUrl}/alm-estoques${id}`, this.itemData)
          .then((res) => {
            this.itemData = res.data;
            this.estoque(this.itemData.id)
            this.$toasted.global.defaultSuccess();
            this.$emit("objectInputs");
          })
          .catch((error) => {
            showError(error);
          });
    },
    reset() {
      this.$bvModal.hide("modal-form-alm-estoque-data");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    async setQtdAdquirida() {
      let url = `${baseApiUrl}`;
      if (this.item.agrupamento == "2") url += `/plan-grup-itens/0/${this.itemData.id_pl_it}`
      else url += `/plan-itens/${this.itemData.id_pl_it}`
      const plan_item = await axios.get(url);
      this.qtdAdqLabel = "";
      let validPlanItem = true
      let validQuantAdq = true
      if (this.itemData.quantidade && this.itemData.quantidade > 0)
        validPlanItem = Number(plan_item.data.quantidade) >= Number(this.itemData.quantidade);
      if (this.itemData.quantidade && this.itemData.quantidade > 0 && this.itemData.qtd_minima && this.itemData.qtd_minima > 0)
        validQuantAdq = Number(this.itemData.quantidade) >= Number(this.itemData.qtd_minima);

      this.isValid =
        validPlanItem &&
        validQuantAdq

      if (this.isValid && this.itemData.id && this.itemData.id > 0)
        this.isValid = this.itemData.qtd_minima < this.estoqueContabil;

      if (this.itemData.quantidade) {
        this.qtdAdq = validPlanItem && validQuantAdq ? "" : "is-invalid";
        if (this.qtdAdq == "") this.qtdAdqLabel = "";
        else {
          if (!validPlanItem)
            this.qtdAdqLabel =
              "Quantidade adquirida é maior que a quantidade licitada!";
          if (!validQuantAdq)
            this.qtdAdqLabel +=
              " Quantidade adquirida é menor que a quantidade mínima!";
        }
      }
      if (this.itemData.id && this.itemData.id > 0 && this.itemData.qtd_minima) {
        this.qtdMin =
          this.itemData.qtd_minima < this.estoqueContabil ? "" : "is-invalid";
      } else {
        this.qtdMin = "";
      }
    },
    getListPlanContratacoes() {
      const url = `${baseApiUrl}/plan-contratacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listContratacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contratacao}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    async getListPlanItens() {
      const id_pl_it = this.item.id_pl_it
        ? `id_pl_it=${this.item.id_pl_it}&`
        : "";
      const id_pl_lic = this.item.id_pl_lic
        ? `id_plan_licit=${this.item.id_pl_lic}&`
        : "";
      const id_pl_cont = this.item.id_pl_cont
        ? `id_plan_contrat=${this.item.id_pl_cont}&`
        : "";
      const query = `?${id_pl_it}${id_pl_lic}${id_pl_cont}`;
      const url = `${baseApiUrl}/alm-estoques/0/0${query}`;
      await axios
        .patch(url)
        .then((res) => {
          this.listItem = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_item} ${data.descricao} (Quant: ${data.quantidade})(${data.id}) `,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListAlmProces() {
      const url = `${baseApiUrl}/alm-processos`;
      axios
        .patch(url)
        .then((res) => {
          this.listAlmoProc = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_almo}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    showRow(item, index, button) {
      this.$root.$emit("bv::show::modal", "modal-form-movimentos-grid", button);
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListAlmProces();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}

.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.mx-datepicker {
  width: 100%;
}

.texti {
  padding: 3px;
  padding-right: 1rem;
  padding-left: 1rem;
}
</style>
