<template>
  <div class="siap-panel">
    <PageTitle icon="far fa-edit" main="Patrimônio" sub="Dados & Cia" />
    <div class="siap-panel-tabs">
      <b-card no-body>
        <b-tabs v-model="tabIndex" card>
          <b-tab title="Setores" lazy>
            <PatrSetoresGrid ref="PatrSetoresGrid" />
          </b-tab>
          <b-tab title="Departamentos" lazy>
            <PatrDeptosGrid ref="PatrDeptosGrid" />
          </b-tab>
          <b-tab title="Locações" lazy>
            <PatrLocacoesGrid ref="PatrLocacoesGrid" />
          </b-tab>
          <b-tab title="Objetos" id="objetos" lazy>
            <PatrimoniosGrid ref="PatrimoniosGrid" />
          </b-tab>
          <b-tab title="Arquivos e Relatórios" lazy>
            <Arquivos ref="Arquivos" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PatrSetoresGrid from "../patrSetoresAdmin/PatrSetoresGrid";
import PatrDeptosGrid from "../patrDeptosAdmin/PatrDeptosGrid";
import PatrLocacoesGrid from "../patrLocacoesAdmin/PatrLocacoesGrid";
import PatrimoniosGrid from "../patrimonioAdmin/PatrimoniosGrid";
import PageTitle from "../template/PageTitle";
import Arquivos from "./Arquivos";

export default {
  name: "PatrimonioAdminPanels",
  components: {
    PatrSetoresGrid,
    PatrDeptosGrid,
    PatrLocacoesGrid,
    PatrimoniosGrid,
    Arquivos,
    PageTitle,
  },
  data: function () {
    return {
      tabIndex: undefined,
    };
  },
  methods: {
    goTab() {
      switch (location.hash) {
        case "#departamentos":
          this.tabIndex = 1;
          break;
        case "#locacoes":
          this.tabIndex = 2;
          break;
        case "#objetos":
          this.tabIndex = 3;
          break;
        case "#arquivos":
          this.tabIndex = 4;
          break;
        default:
          this.tabIndex = 0;
          break;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.goTab();
    }, 250);
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    $route() {
      this.goTab();
    },
  },
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>