<template>
  <div class="exec_desembolso-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="3" sm="12">
          <b-form-group
            label="Nº Parcela"
            label-for="exec_desembolsos-n_parcela"
          >
            <b-form-input
              id="exec_desembolsos-n_parcela"
              type="number"
              v-model="itemData.n_parcela"
              @keypress="isNumber($event)"
              maxlength="10"
              min="1"
              required
              placeholder="Informe Nº da Parcela Desembolso ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Mês" label-for="exec_desembolsos-mes_desembolso">
            <b-form-select
              id="exec_desembolsos-mes_desembolso"
              :options="optionsMes"
              v-model="itemData.mes_desembolso"
              required
              placeholder="Selecione ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Ano" label-for="exec_desembolsos-ano_desembolso">
            <b-input
              type="number"
              id="exec_desembolsos-ano_desembolso"
              v-model="itemData.ano_desembolso"
              required
              :min="minExercicio"
              :class="anoIsValid"
              placeholder="Informe o Ano ..."
              @input="setMinAnoDesembolso"
            >
            </b-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Responsável"
            label-for="exec_desembolsos-responsavel"
          >
            <b-form-select
              id="exec_desembolsos-responsavel"
              :options="optionsResponsavel"
              v-model="itemData.responsavel"
              required
              placeholder="Informe o Responsável ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Valor da Parcela"
            label-for="exec_desembolsos-valor"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor"
                v-bind="money"
                id="exec_desembolsos-valor"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Cronograma Desembolso: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { Money } from "v-money";
import { optionsMes, optionsResponsavel } from "@/config/lists"; 
import moment from "moment";

export default {
  name: "ExecDesembolsoData",
  props: ["item"],
  components: { Money },
  data: function () {
    return {
      userParams: {},
      minExercicio: moment().year() - 5,
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listContrato: [],
      listEtapas: [],
      isDate: "",
      anoIsValid: "",
      optionsResponsavel: optionsResponsavel,
      optionsMes: optionsMes,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/exec-desembolsos/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      } else {
        this.itemData = {
          id_ex_etp: this.item.id_ex_etp,
          id_ex_cnt: this.item.id_ex_cnt,
        };
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/exec-desembolsos${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
          // this.$bvModal.hide("modal-form");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    setMinAnoDesembolso() {
      if (
        this.itemData.ano_desembolso &&
        this.itemData.ano_desembolso.length > 0
      )
        this.anoIsValid =
          this.itemData.ano_desembolso >= (new Date().getFullYear() - 5) ? "is-valid" : "is-invalid";
      else this.anoIsValid = "";
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
