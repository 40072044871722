<template>
  <div class="patrimonio-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <input type="hidden" v-model="itemData.id_patrimonio" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group label="Alocação do objetos" label-for="patr_objetos-id_patr_loc">
            <v-select id="patr_objetos-id_patr_loc" :options="listLocacao" v-model="itemData.id_patr_loc"
              :reduce="(label) => label.code" :disabled="mode === 'remove'"
              placeholder="Selecione a Alocação do Objeto ...">
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Situação" label-for="patr_objetos-situacao">
            <b-form-select id="patr_objetos-situacao" :options="optionsSituacaoObj" v-model="itemData.situacao"
              required />
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-form-group label="Conservação" label-for="patr_objetos-conservacao">
            <b-form-select id="patr_objetos-conservacao" :options="optionsConservacaoObj" v-model="itemData.conservacao"
              required />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Data do Registro" label-for="patr_objetos-registro_data">
            <date-picker v-model="itemData.registro_data" valueType="DD/MM/YYYY" format="DD/MM/YYYY" type="date"
              input-class="form-control" required />
          </b-form-group>
        </b-col>
        <!-- <b-col md="2" sm="12">
          <b-form-group
            label="Data do Registro"
            label-for="patr_objetos-registro_data"
          >
            <b-form-datepicker
              id="patr_objetos-registro_data"
              right
              v-model="itemData.registro_data"
              placeholder="Data do registro..."
              required
              locale="pt"
              v-bind="labelsDatePicker['pt'] || {}"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </b-col> -->
        <b-col md="2" sm="12">
          <b-form-group label="Nota Fiscal" label-for="patr_objetos-nf_compra">
            <b-form-input id="patr_objetos-nf_compra" type="text" v-model="itemData.nf_compra"
              @keypress="isNumber($event)" maxlength="12" placeholder="Informe a Nota Fiscal ..." />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Data Nota Fiscal" label-for="patr_objetos-nf_data">
            <date-picker v-model="itemData.nf_data" valueType="DD/MM/YYYY" format="DD/MM/YYYY" type="date"
              input-class="form-control" :required="
                itemData.nf_compra && itemData.nf_compra.trim().length > 0
              " />
          </b-form-group>
        </b-col>
        <!-- <b-col md="2" sm="12">
          <b-form-group
            label="Data Nota Fiscal"
            label-for="patr_objetos-nf_data"
          >
            <b-form-datepicker
              id="patr_objetos-nf_data"
              right
              v-model="itemData.nf_data"
              placeholder="Data Nota Fiscal..."
              :required="
                itemData.nf_compra && itemData.nf_compra.trim().length > 0
              "
              locale="pt"
              v-bind="labelsDatePicker['pt'] || {}"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </b-col> -->
        <b-col md="3" sm="12">
          <b-form-group label="Valor" label-for="patr_objetos-valor">
            <b-input-group prepend="R$">
              <money v-model="itemData.valor" v-bind="money" id="patr_objetos-valor"
                class="valor-field-right form-control">
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Nº de Série" label-for="patr_objetos-nr_serie">
            <b-form-input id="patr_objetos-nr_serie" type="text" v-model="itemData.nr_serie" @keypress="isNumber($event)"
              maxlength="20" placeholder="Informe o Nº de Série ..." />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Data da Garantia" label-for="patr_objetos-garantia_data">
            <date-picker v-model="itemData.garantia_data" valueType="DD/MM/YYYY" format="DD/MM/YYYY" type="date"
              input-class="form-control" />
          </b-form-group>
        </b-col>
        <!-- <b-col md="2" sm="12">
          <b-form-group
            label="Data da Garantia"
            label-for="patr_objetos-garantia_data"
          >
            <b-form-datepicker
              id="patr_objetos-garantia_data"
              right
              v-model="itemData.garantia_data"
              placeholder="Data da Garantia..."
              locale="pt"
              v-bind="labelsDatePicker['pt'] || {}"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </b-col> -->
        <b-col md="8" sm="12">
          <b-form-group label="Apólice" label-for="patr_objetos-apolice">
            <b-form-input id="patr_objetos-apolice" type="text" v-model="itemData.apolice" maxlength="20"
              placeholder="Informe a Apólice ..." />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Data da Apólice" label-for="patr_objetos-apolice_data">
            <date-picker v-model="itemData.apolice_data" valueType="DD/MM/YYYY" format="DD/MM/YYYY" type="date"
              input-class="form-control" :required="itemData.apolice && itemData.apolice.trim().length > 0" />
          </b-form-group>
        </b-col>
        <!-- <b-col md="2" sm="12">
          <b-form-group
            label="Data da Apólice"
            label-for="patr_objetos-apolice_data"
          >
            <b-form-datepicker
              id="patr_objetos-apolice_data"
              right
              v-model="itemData.apolice_data"
              placeholder="Data da Apólice..."
              :required="itemData.apolice && itemData.apolice.trim().length > 0"
              locale="pt"
              v-bind="labelsDatePicker['pt'] || {}"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
      <div class="clearfix">
        <b-button variant="primary" class="float-left" v-if="mode === 'save'" @click="save">
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset"> Sair </b-button>
        <code v-if="itemData.hash"
          class="float-right">Dados adicionais do objeto: {{ itemData.hash + itemData.tblName }}</code>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { optionsSituacaoObj, optionsConservacaoObj } from "@/config/lists";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "PatrimonioAlocacaoData",
  props: ["item"],
  components: { Money, DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {
        id_patrimonio: "0",
        valor: 0,
        situacao: 1,
      },
      mode: "save",
      isNumber: isNumber,
      labelsDatePicker: datePickerLocale,
      isDate: "",
      tabIndex: 0,
      optionsConservacaoObj: optionsConservacaoObj,
      optionsSituacaoObj: optionsSituacaoObj,
      listLocacao: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadData() {
      this.itemData = this.item
      this.getListaLocacoes();
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      const url = `${baseApiUrl}/patr-objetos/${this.itemData.id_patrimonio}${id}`;

      axios[method](url, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          delete this.item.duplicate
          this.$toasted.global.defaultSuccess();
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListaLocacoes() {
      const url = `${baseApiUrl}/patr-locacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listLocacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `(${data.departamento}-${data.sigla_depto})-${data.nome || 'Servidor não informado'}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}

.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.mx-datepicker {
  width: 100%;
}
</style>
