<template>
  <div class="stats-relatorios">
    <b-row>
      <b-col md="3" sm="12">
        <b-form-group label="Exercício" label-for="relatorios-exercicio">
          <b-form-select
            id="relatorios-exercicio"
            type="text"
            :options="optionExercicios"
            required
            placeholder="Informe o exercício ..."
          />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="12">
        <b-form-group label="Mês" label-for="plan_licitacoes-mes">
          <b-form-select
            id="plan_licitacoes-mes"
            :options="optionsMes"
            v-model="itemData.mes"
            required
            placeholder="Selecione ..."
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-card title="Relatórios do Almoxarifado" class="mb-3">
      <div class="stats">
        <Stat
          :class="statsBrowser"
          title="Entradas"
          :value="stat.alm_estoques_ent"
          font-awesome-icon icon="fas fa-angle-double-left"
          color="#3a1413d8"
          link="/almoxarifado#processos"
        />
        <Stat
          :class="statsBrowser"
          title="Saídas"
          :value="stat.alm_estoques_sda"
          font-awesome-icon icon="fas fa-angle-double-right"
          color="#3a1413d8"
          link="/almoxarifado#relatorios"
        />
        <Stat
          :class="statsBrowser"
          title="Quantidade Atual de Itens"
          :value="stat.alm_movimentos"
          icon="fas fa-align-justify"
          color="#205120"
          link="/almoxarifado#relatorios"
        />
        <Stat
          :class="statsBrowser"
          title="Almoxarifados"
          :value="stat.almoxarifados"
          font-awesome-icon icon="fas fa-archway" 
          color="orange"
          link="/almoxarifado#relatorios"
        />
        <Stat
          :class="statsBrowser"
          title="Movimentações"
          :value="stat.alm_movimentos1"
          font-awesome-icon icon="fas fa-dice-d6" 
          color="#930707"
          link="/almoxarifado#relatorios"
        />
        <Stat
          :class="statsBrowser"
          title="Registro de Estoques"
          :value="stat.alm_estoques"
          font-awesome-icon icon="fas fa-archive" 
          color="#202051"
          link="/almoxarifado#processos"
        />
        <Stat
          :class="statsBrowser"
          title="Quantidade de Categorias"
          :value="stat.plan_categorias"
          font-awesome-icon icon="fas fa-book-open"
          color="#3a1413d8"
          link="/almoxarifado#processos"
        />
        <Stat
          :class="statsBrowser"
          title="Itens Abaixo Estoque"
          :value="stat.alm_estoques_min"
          font-awesome-icon icon="fas fa-caret-square-down"
          link="/almoxarifado#processos"
        />
        <Stat
          :class="statsBrowser"
          title="Valor Atual do Estoque $"
          :value="stat.alm_estoques_val"
          font-awesome-icon icon="fas fa-cart-plus"
          color="grey"
          link="/almoxarifado#processos"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import Stat from "../home/Stat";
import { mapState } from "vuex";
import { baseApiUrl } from "@/env";
import axios from "axios";
import { isBrowser } from "mobile-device-detect";
import { optionsMes } from "@/config/lists";
// import { showError } from "@/global";

export default {
  name: "AlmRelatorioData", //Home
  props: ["title"],
  components: { Stat },
  data: function () {
    return {
      stat: {
        alm_estoques_ent: 0,
        alm_estoques_sda: 0,
        alm_estoques: 0,
        alm_movimentos: 0,
        alm_movimentos1: 0,
        almoxarifados: 0,
        plan_categorias: 0,
        alm_estoques_min: 0,
        alm_estoques_val: 0,
      },
      itemData: {},
      statsBrowser: "",
      anoIsValid: "",
      optionsMes: optionsMes,
      optionExercicios: [{ value: new Date().getFullYear(), text: new Date().getFullYear() }],
    };
  },
  methods: {
    loadEntradaEstq() {  // Quant total de itens em estoque
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/alm-estoques/f-a/gen`;
        axios.get(url).then((res) => {
          this.stat.alm_estoques_ent = res.data.soma > 0 ? res.data.soma : "0";
        });
      }
    },
    loadSaidaEstq() {  // Quant itens retirados?
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/alm-estoques/f-a/ges`;
        axios.get(url).then((res) => {
          this.stat.alm_estoques_sda = res.data.soma > 0 ? res.data.soma : "0";
        });
      }
    },
    loadQntAtual() {  //a soma de todo o estoque real?
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/alm-movimentos/f-a/gef`;
        axios.get(url).then((res) => {
          this.stat.alm_movimentos = res.data.soma > 0 ? res.data.soma : "0";
          //console.log(res.data.soma)
        });
      }
    },
    loadAlmoxarifados() {  // Quant almoxarifados
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/almoxarifados-count`;
        axios.get(url).then((res) => {
          this.stat.almoxarifados = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadMovimentacoes() {  // Quant movimentaçoes
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/alm-movimentos-count`;
        axios.get(url).then((res) => {
          this.stat.alm_movimentos1 = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadEstoques() {  // Quant de estoques registrados
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/alm-estoques-count`;
        axios.get(url).then((res) => {
          this.stat.alm_estoques = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadCategorias() { // Quant de Categorias
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/plan-categorias-count`;
        axios.get(url).then((res) => {
          this.stat.plan_categorias = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadQntMinima() { // Quant itens abaixo do estoque
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/alm-estoques/f-a/gmin`;
        axios.get(url).then((res) => {
          this.stat.alm_estoques_min = res.data.quantidade > 0 ? res.data.quantidade : "0";
        });
      }
    },
    loadValorAtual() {  // Valor atual do estoque $
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/alm-estoques/f-a/gva`;
        axios.get(url).then((res) => {
          this.stat.alm_estoques_val = res.data.quantidadeAdiquirida > 0 ? res.data.quantidadeAdiquirida : "0";
        });
      }
    },
    getExercicios() {
      this.optionExercicios = []
      const today = new Date()
      const anoAtual = today.getFullYear()
      for (let index = 0; index < 5; index++) {
        this.optionExercicios.push({ value: anoAtual - index, text: anoAtual - index })
      }
      this.getMes()
    },
    getMes() {
      const today = new Date()
      const mesAtual = today.getMonth()
      this.optionMes = []
      if (new Date().getFullYear() == this.item.exercicio) {
        for (let index = 0; index < mesAtual + 1; index++) {
          this.optionMes.push({ value: mesAtual + 1 - index, text: mesAtual + 1 - index })
        }
      } else {
        this.optionMes = [
          { value: 1, text: 1 },
          { value: 2, text: 2 },
          { value: 3, text: 3 },
          { value: 4, text: 4 },
          { value: 5, text: 5 },
          { value: 6, text: 6 },
          { value: 7, text: 7 },
          { value: 8, text: 8 },
          { value: 9, text: 9 },
          { value: 10, text: 10 },
          { value: 11, text: 11 },
          { value: 12, text: 12 }
        ]
      }
    },
    // getExercicios() {
    //   const url = `${baseApiUrl}/alm-estoques/f-a/gle`;
    //   axios
    //     .get(url)
    //     .then((body) => {
    //       this.optionExercicios = body.data.data.map((data) => {
    //         return {
    //           value: data.exercicio,
    //           text: data.exercicio,
    //         };
    //       });
    //     })
    //     .catch(showError);
    // },
    // getMes() {
    //   const url = `${baseApiUrl}/alm-estoques/f-a/glm`;
    //   axios
    //     .get(url)
    //     .then((body) => {
    //       this.optionMes = body.data.data.map((data) => {
    //         return {
    //           value: data.mes,
    //           text: data.mes,
    //         };
    //       });
    //     })
    //     .catch(showError);
    // },
    setStatsBrowserClass() {
      if (isBrowser) this.statsBrowser = "stats-desktop";
    },
  },
  mounted() {
    this.loadEntradaEstq();
    //this.loadSaidaEstq();
    this.loadAlmoxarifados();
    this.loadMovimentacoes();
    this.loadEstoques();
    this.loadQntAtual();
    this.loadCategorias();
    //this.loadQntMinima();
    this.loadValorAtual();
    this.setStatsBrowserClass();
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
