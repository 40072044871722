<template>
  <div class="adj_prop_item-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group
            :label="`Item${
              this.userParams.admin && this.itemData.id
                ? ` (${this.itemData.id})`
                : ''
            }`"
            label-for="adj_prop_itens-id_pl_it"
          >
            <v-select
              id="adj_prop_itens-id_pl_it"
              :options="listItem"
              v-model="itemData.id_pl_it"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Item ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Marca do Item"
            label-for="adj_prop_itens-marca_item"
          >
            <b-form-input
              id="adj_prop_itens-marca_item"
              type="text"
              v-model="itemData.marca_item"
              maxlength="255"
              placeholder="Informe a Marca do Item ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Código de Barras"
            label-for="adj_prop_itens-cod_barras"
          >
            <b-form-input
              id="adj_prop_itens-cod_barras"
              type="text"
              v-model="itemData.cod_barras"
              @keypress="isNumber($event)"
              maxlength="32"
              placeholder="Informe o Código de Barras ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-row>
            <b-col :md="`${itemData.id ? '3' : '4'}`" sm="12">
              <b-form-group
                label="Data Homologação"
                label-for="adj_prop_itens-data_homologacao"
              >
                <date-picker
                  v-model="itemData.data_homologacao"
                  valueType="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  type="date"
                  required
                  input-class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col :md="`${itemData.id ? '3' : '4'}`" sm="12">
              <b-form-group
                label="Quantidade"
                label-for="adj_prop_itens-quantidade"
              >
                <b-form-input
                  id="adj_prop_itens-quantidade"
                  type="text"
                  v-model="itemData.quantidade"
                  @keypress="isNumber($event)"
                  maxlength="10"
                  placeholder="Informe a Quantidade de Itens Vencidos ..."
                />
              </b-form-group>
            </b-col>
            <b-col :md="`${itemData.id ? '3' : '4'}`" sm="12">
              <b-form-group
                label="Valor Unitário"
                label-for="adj_prop_itens-valor_unit"
              >
                <b-input-group prepend="R$">
                  <money
                    v-model="itemData.valor_unit"
                    v-bind="money"
                    id="adj_prop_itens-valor_unit"
                    class="valor-field-right form-control"
                    required
                  >
                  </money>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12" v-if="itemData.id">
              <b-form-group
                label="Situação Adjudicação"
                label-for="adj_itens-adjudicado"
              >
                <b-form-select
                  id="adj_itens-adjudicado"
                  :options="optionsAdjudicacao"
                  v-model="itemData.adjudicado"
                  placeholder="Informe a Situação da Adjudicação ..."
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Proponentes Itens: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "AdjPropItemData",
  props: ["item", "tipoProposta"],
  components: { Money, DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      labelsDatePicker: datePickerLocale,
      isDate: "",
      optionsAdjudicacao: [],
      listContratacao: [],
      listItem: [],
      listCadastros: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    async loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/adj-prop-itens/${this.item.id_adj_prop}/${this.item.id}`;
        await axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      } else {
        this.itemData = this.item;
      }
      this.optionsAdjudicacao = [
        {
          value: "1",
          text: "Adjudicado",
          disabled:
            this.itemData.isAdjudicado == "1" &&
            this.itemData.isAdjudicado != this.itemData.adjudicado,
        },
        { value: "2", text: "Anulado" },
        { value: "3", text: "Revogado" },
        { value: "4", text: "Fracassado" },
      ];
      this.getListPlanItens();
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id
        ? `/${this.itemData.id_adj_prop}/${this.itemData.id}`
        : `/${this.itemData.id_adj_prop}`;
      if (!this.itemData.id) {
        this.itemData = {
          ...this.itemData,
          id_pl_lic: this.item.id_pl_lic,
          id_pl_cont: this.item.id_pl_cont,
          id_adj_prop: this.item.id_adj_prop,
        };
      }
      axios[method](`${baseApiUrl}/adj-prop-itens${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form-AdjPropItemData");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListPlanContratacoes() {
      const url = `${baseApiUrl}/plan-contratacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listContratacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contratacao}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListPlanItens() {
      const id_pl_it = this.item.id_pl_it
        ? `id_pl_it=${this.item.id_pl_it}&`
        : "";
      const id_pl_lic = this.item.id_pl_lic
        ? `id_plan_licit=${this.item.id_pl_lic}&`
        : "";
      const id_pl_cont = this.item.id_pl_cont
        ? `id_plan_contrat=${this.item.id_pl_cont}&`
        : "";
      const id_cadastro = this.item.id_cadastro
        ? `id_cadastro=${this.item.id_cadastro}`
        : "";
      const query = `?${id_pl_it}${id_pl_lic}${id_pl_cont}${id_cadastro}`;
      const url = `${baseApiUrl}/adj-prop-itens/0/0${query}`;
      axios
        .patch(url)
        .then((res) => {
          this.listItem = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_item} (${data.descricao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadastros = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListCadastros();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
