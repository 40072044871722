<template>
  <div class="patr_setor-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Unidade Autônoma"
            label-for="patr_setores-id_ua"
          >
            <v-select
              id="patr_setores-id_ua"
              :options="listPUnidade"
              v-model="itemData.id_ua"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe a Unidade ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Setor"
            label-for="patr_setores-setor"
          >
            <b-form-input
              id="patr_setores-setor"
              type="text"
              v-model="itemData.setor"
              required
              maxlength="50"
              placeholder="Informe o Setor ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="CEP" label-for="patr_setores-cep">
            <the-mask
              id="patr_setores-cep"
              type="text"
              @input="getEnderecoApi"
              v-model="itemData.cep"
              :mask="['########']"
              :class="`form-control ${cepClass}`"
              :readonly="mode === 'remove'"
              required
              placeholder="Informe o Cep..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="UF" label-for="patr_setores-uf">
            <b-form-select
              id="patr_setores-uf"
              :options="ufList"
              v-model="itemData.uf"
              @input="getListaCidades"
              required
              :disabled="!isCep || mode === 'remove'"
              placeholder="Informe o uf ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Municipio" label-for="patr_setores-municipio">
            <b-form-select
              id="patr_setores-municipio"
              v-model="itemData.municipio"
              :options="citiesList_cidade"
              required
              :disabled="!isCep || mode === 'remove'"
              placeholder="Informe a Municipio..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Bairro" label-for="patr_setores-bairro">
            <b-form-input
              id="patr_setores-bairro"
              type="text"
              v-model="itemData.bairro"
              :readonly="!isCep || mode === 'remove'"
              maxlength="50"
              required
              placeholder="Informe o Bairro ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Nº:" label-for="patr_setores-nr">
            <b-form-input
              id="patr_setores-nr"
              ref="nr"
              type="text"
              v-model="itemData.nr"
              :readonly="!isCep || mode === 'remove'"
              required
              placeholder="Informe o Número ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="9" sm="12">
          <b-form-group label="Logradouro" label-for="patr_setores-logradouro">
            <b-form-input
              id="patr_setores-logradouro"
              ref="logradouro"
              type="text"
              v-model="itemData.logradouro"
              maxlength="100"
              required
              :readonly="!isCep || mode === 'remove'"
              placeholder="Informe o Logradouro ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Complemento" label-for="patr_setores-complemento">
            <b-form-input
              id="patr_setores-complemento"
              type="text"
              v-model="itemData.complemento"
              maxlength="50"
              placeholder="Informe o Complemento ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset"> Cancelar </b-button>
        <code v-if="itemData.hash" class="float-right">Setor: {{ itemData.hash + itemData.tblName }}</code>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError, setValidCep } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";
import { ufList } from "@/config/lists";
import { TheMask } from "vue-the-mask";

export default {
  name: "PatrSetorData",
  props: ["item"],
  components: { TheMask },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      citiesList_cidade: [],
      ufList: ufList,
      cepClass: undefined,
      isCep: undefined,
      listPUnidade: [],
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/patr-setores/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          const cep = setValidCep(this.itemData.cep);
          this.cepClass = cep.cepClass;
          this.isCep = cep.isCep;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/patr-setores${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
          this.$bvModal.hide("modal-form");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getEnderecoApi() {
      const cep = setValidCep(this.itemData.cep);
      this.cepClass = cep.cepClass;
      this.isCep = cep.isCep;
      if (this.isCep) {
        const urlCep = `https://viacep.com.br/ws/${this.itemData.cep}/json/`
        axios.get(urlCep)
          .then((obj) => {
            this.cepData = obj.data;
            this.getListaCidades(this.cepData.uf);
          })
          .then(() => {
            if (this.cepData.cep) {
              this.itemData.logradouro = this.cepData.logradouro;
              this.itemData.complemento = this.cepData.complemento;
              this.itemData.bairro = this.cepData.bairro;
              this.itemData.uf = this.cepData.uf;
              this.itemData.municipio = this.cepData.localidade.toUpperCase();
              if (this.cepData.logradouro) this.$refs.nr.focus();
              else this.$refs.logradouro.focus();
            }
          });
      }
    },
    getListaCidades(uf) {
      if (uf) {
        const url = `${baseApiUrl}/cidades/${uf}`;
        axios
          .get(url)
          .then((res) => {
            this.citiesList_cidade = res.data.data.map((data) => {
              return {
                value: data.municipio.toUpperCase(),
                text: data.municipio.toUpperCase(),
              };
            });
          })
          .catch((error) => {
            showError(error);
          });
      }
    },
    getListaUnidAutonomas() {
      const url = `${baseApiUrl}/und-autonomas`;
      axios
        .patch(url)
        .then((res) => {
          this.listPUnidade = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cnpj} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListaUnidAutonomas();
    if (this.itemData.uf) this.getListaCidades(this.itemData.uf);
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
