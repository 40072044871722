<template>
  <div class="adj_proponente-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12" v-if="tipoProposta == '1'">
          <b-form-group
            label="Nº do Processo (Licitação)"
            label-for="adj_proponentes-id_pl_lic"
          >
            <v-select
              id="adj_proponentes-id_pl_lic"
              :options="listProcessos"
              v-model="itemData.id_pl_lic"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe a Licitação ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12" v-if="tipoProposta == '2'">
          <b-form-group
            label="Nº da Contratação"
            label-for="adj_proponentes-id_pl_cont"
          >
            <v-select
              id="adj_proponentes-id_pl_cont"
              :options="listContratacao"
              v-model="itemData.id_pl_cont"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº da Contratação ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Proponente (Pessoas & Empresas)"
            label-for="adj_proponentes-id_cad"
          >
            <v-select
              id="adj_proponentes-id_cad"
              :options="listCadastros"
              v-model="itemData.id_cad"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe Nome ou Razão Social ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Adjudicação Proponentes: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
    <AdjPropItensGrid
      :item="itemData"
      class="mt-3"
      v-if="itemData.agrupamento != 2 && itemData.id"
    />
    <AdjGrupoData
      :item="itemData"
      class="mt-3"
      v-else-if="itemData.agrupamento == 2 && itemData.id"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";
import AdjPropItensGrid from "../adjPropItensAdmin/AdjPropItensGrid";
import AdjGrupoData from "../adjGruposAdmin/AdjGrupoData";

export default {
  name: "AdjProponenteData",
  props: ["item", "tipoProposta"],
  components: { AdjPropItensGrid, AdjGrupoData },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listProcessos: [],
      listContratacao: [],
      listCadastros: [],
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/adj-proponentes/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      const url = `${baseApiUrl}/adj-proponentes${id}`;
      axios[method](url, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListPlanLicitacoes() {
      const url = `${baseApiUrl}/plan-licitacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listProcessos = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListPlanContratacoes() {
      const url = `${baseApiUrl}/plan-contratacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listContratacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contratacao}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadastros = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    if (this.tipoProposta == "1") this.getListPlanLicitacoes();
    else if (this.tipoProposta == "2") this.getListPlanContratacoes();
    this.getListCadastros();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
