<template>
  <div>
    <b-card no-body>
      <div class="plan_agentes-grid pdd-10">
        <b-input-group size="md" class="mt-3 mb-3">
          <b-dropdown text="Novo registro" :variant="`outline-${lblBtnNewVariant}`" slot="prepend">
            <b-dropdown-item href="#" @click="newRow('1')">Licitação</b-dropdown-item>
            <b-dropdown-item href="#" @click="newRow('2')">Contratação</b-dropdown-item>
            <b-dropdown-item href="#" @click="newRow('3')">Adesão</b-dropdown-item>
          </b-dropdown>
          <!-- <b-button
            :variant="`outline-${lblBtnNewVariant}`"
            size="sm"
            slot="prepend"
            @click="newRow"
          >
            <i class="fa fa-plus"></i>&nbsp;
            <span v-if="isBrowser">{{ lblBtnNew }}</span>
          </b-button> -->
          <b-form-input type="text" placeholder="Digite aqui para localizar o registro..." v-b-tooltip.hover
            :title="`Digite ao menos ${charSearchCount} caracteres para pesquisa dinâmica e use espaços para pesquisar por mais de uma expressão`"
            v-model="keyword" @input="searchPlanAgentes(charSearchCount)" @blur="searchPlanAgentes(0)" ref="keyword" />
          <b-btn size="sm" @click="searchPlanAgentes(0)">
            <i class="fa fa-search"></i>
          </b-btn>
          <b-input-group-text slot="append">
            <span v-if="isBrowser">{{ keyword_res }}&nbsp;</span>
            <b-btn :disabled="!keyword" variant="link" size="sm" @click="getRefresh" class="p-0">
              <i class="fa fa-remove"></i>
            </b-btn>
          </b-input-group-text>
        </b-input-group>
        <b-table hover striped responsive :items="plan_agentes" :fields="fields">
          <template v-slot:cell(nome)="data" v-if="userParams.admin >= 1">
            <span v-html="`${data.item.nome} (${data.item.id})`" />
          </template>
          <template v-slot:cell(nome)="data" v-else>
            <span v-html="`${data.item.nome}`" />
          </template>
          <template #cell(n_licitacao)="data">
            <span v-html="data.item.n_licitacao" />
          </template>
          <template #cell(actions)="row">
            <b-button v-if="userParams.id >= 1" variant="outline-info" size="sm" class="mr-1" v-b-tooltip.hover
              title="Editar registro" @click="showRow(row.item, row.index, $event.target)" href="#header">
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button v-if="userParams.id >= 1" variant="outline-danger" size="sm" v-b-tooltip.hover
              title="Remover registro" @click="remove(row.item.id)">
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination size="md" v-model="page" :total-rows="count" :per-page="limit" />
      </div>
    </b-card>
    <b-modal size="xl" id="modal-form" :title="modalTitle" centered scrollable hide-footer>
      <PlanAgenteData :tipoProposta="objProponente" :item="planAgenteInputs" @objectInputs="loadPlanAgentes" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import { getDecimalFormater } from "@/config/globalFacilities";
import { showError, highlight, removeMark } from "@/global";
import PlanAgenteData from "./PlanAgenteData";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "PlanAgentesGrid",
  components: { PlanAgenteData },
  data: function () {
    return {
      userParams: {},
      charSearchCount: 3,
      objProponente: "1",
      modalTitle: "Novo Registro",
      plan_agentes: [], // table
      plan_agente: {}, // field
      planAgenteInputs: {}, // form data
      loading: false,
      lblBtnNew: "Novo Registro",
      lblBtnNewVariant: "primary",
      mode: "save",
      keyword: "",
      isBrowser: isBrowser,
      keyword_res: "",
      lblCancelar: "Cancelar",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        { key: "nome", label: "Nome", sortable: true },
        { key: "n_licitacao", label: "Nº Licitação", sortable: true },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions2",
          tdClass: "col-actions2",
        },
      ],
    };
  },
  methods: {
    searchPlanAgentes(length) {
      if (
        this.keyword.toString().length >= length ||
        this.keyword.toString().length == 0
      )
        this.loadPlanAgentes();
    },
    loadPlanAgentes() {
      const url = `${baseApiUrl}/plan-agentes?page=${this.page}&key=${this.keyword}`;
      axios.get(url).then((res) => {
        this.plan_agentes = res.data.data;
        this.plan_agentes.forEach((element) => {
          element.n_licitacao = highlight(
            element.n_licitacao.toUpperCase(),
            this.keyword.toString().toUpperCase()
          );
          element.nome = highlight(
            element.nome.toUpperCase(),
            this.keyword.toString().toUpperCase()
          );
        });
        this.count = res.data.count;
        this.limit = res.data.limit;
        const pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${pluralize}`;
      });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.loadPlanAgentes();
    },
    reset() {
      this.keyword = "";
      this.mode = "save";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.getRefresh();
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    newRow(value) {
      this.planAgenteInputs = {};
      this.modalTitle = `Novo Registro`;
      this.$root.$emit("bv::show::modal", "modal-form");


      this.objProponente = value;
      this.planAgenteInputs = {};
      this.modalTitle = `Novo Registro de Agente`;
      switch (value) {
        case "1": this.modalTitle += " para a Licitação"
          break;
        case "2": this.modalTitle += " para a Contratação"
          break;
        case "3": this.modalTitle += " para a Adesão"
          break;
      }
      this.$root.$emit("bv::show::modal", "modal-form");

    },
    showRow(item, index, button) {
      Object.entries(item).forEach(([key, value]) => {
        if (item)
          this.planAgenteInputs = {
            ...this.planAgenteInputs,
            [key]: removeMark(value || ""),
          };
      });
      this.modalTitle = `Registro: ${this.planAgenteInputs.nome}`;
      this.$root.$emit("bv::show::modal", "modal-form", button);
    },
    remove(id) {
      const url = `${baseApiUrl}/plan-agentes/${id}`;
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.planAgenteInputs = {};
                this.loadPlanAgentes();
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
  },
  mounted() {
    this.loadUserParams();
    this.loadPlanAgentes();
  },
  watch: {
    page() {
      this.loadPlanAgentes();
    },
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.plan_agentes-data {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>