<template>
  <div :class="`stat${isDesktop}`">
    <div class="stat-icon">
      <i :class="icon" :style="style"></i>
    </div>
    <div class="stat-info">
      <b-link class="stat-title" v-if="link" :to="link">{{ title }}</b-link>
      <span class="stat-value" v-if="value">{{ value }}</span>
    </div>
  </div>
</template>

<script>
import { isBrowser } from "mobile-device-detect";
export default {
  name: "Stat",
  props: ["title", "value", "icon", "color", "items", "link"],
  data: function () {
    return {
      isBrowser: isBrowser,
    };
  },
  methods: {},
  computed: {
    style() {
      return "color: " + (this.color || "#000");
    },
    isDesktop() {
      let classDesk = "";
      if (this.isBrowser) classDesk = " statDesktop";
      return classDesk;
    },
  },
  mounted() {
  },
};
</script>

<style>
.statDesktop {
  margin-right: 20px;
  min-width: 300px;
}
.stat {
  flex: 1;
  display: flex;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.719);
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.stat-icon {
  display: flex;
  align-items: center;
}
.stat-icon i {
  font-size: 5rem;
}
.stat-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.stat-title {
  font-size: 1.2rem;
  color: #212529;
}
.stat-value {
  font-size: 3rem;
}
</style>