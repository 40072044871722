<template>
  <div>
    <b-card no-body>
      {{ item }}
      <div class="alm_movimentos-grid pdd-10">
        <b-input-group size="md" class="mt-3 mb-3">
          <b-button :variant="`outline-${lblBtnNewVariant}`" size="sm" slot="prepend" @click="newRow">
            <i class="fa fa-plus"></i>&nbsp;
            <span v-if="isBrowser">{{ lblBtnNew }}</span>
          </b-button>
          <b-form-input type="text" placeholder="Digite aqui para localizar o registro..." v-b-tooltip.hover
            :title="`Digite ao menos ${charSearchCount} caracteres para pesquisa dinâmica e use espaços para pesquisar por mais de uma expressão`"
            v-model="keyword" @input="searchAlmMov(charSearchCount)" @blur="searchAlmMov(0)" ref="keyword" />
          <b-btn size="sm" @click="searchAlmMov(0)">
            <i class="fa fa-search"></i>
          </b-btn>
          <b-input-group-text slot="append">
            <span v-if="isBrowser">{{ keyword_res }}&nbsp;</span>
            <b-btn :disabled="!keyword" variant="link" size="sm" @click="getRefresh" class="p-0">
              <i class="fa fa-remove"></i>
            </b-btn>
          </b-input-group-text>
        </b-input-group>
        <b-table hover striped responsive :items="alm_movimentos" :fields="fields">
          <template v-slot:cell(codigo)="data">
            <span v-html="
              `${data.item.codigo} ${userParams.admin >= 1 ? `(${data.item.id})` : ''
              }`
            " />
          </template>
          <template #cell(created_at)="data">
            <span v-html="data.item.created_at" />
          </template>
          <template #cell(nome)="data">
            <span v-html="data.item.nome" />
          </template>
          <template #cell(movimento)="data">
            <span v-html="data.item.movimento" />
          </template>
          <template #cell(actions)="row">
            <b-button v-if="userParams.id >= 1" variant="outline-info" size="sm" class="mr-1" v-b-tooltip.hover title=""
              @click="showRow(row.item, row.index, $event.target)" href="#processos">
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button v-if="userParams.id >= 1" variant="outline-danger" size="sm" v-b-tooltip.hover
              title="Remover registro" @click="remove(row.item.id)">
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination size="md" v-model="page" :total-rows="count" :per-page="limit" />
      </div>
    </b-card>
    <b-modal size="xl" id="modal-form-alm-movimento-data" :title="modalTitle" centered scrollable hide-footer>
      <AlmMovimentoData :estoqueContabil="estoqueContabil" :item="almMovInputs" @objectInputs="loadAlmMovimento" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import { getDecimalFormater } from "@/config/globalFacilities";
import { showError, highlight, removeMark } from "@/global";
import AlmMovimentoData from "./AlmMovimentoData";
import { isBrowser } from "mobile-device-detect";
import moment from "moment";

export default {
  name: "AlmMovimentosGrid",
  components: { AlmMovimentoData },
  props: ["item", "estoqueContabil"],
  data: function () {
    return {
      userParams: {},
      charSearchCount: 3,
      modalTitle: "Novo Registro",
      alm_movimentos: [], // table
      alm_movimento: {}, // field
      almMovInputs: {}, // form data
      loading: false,
      lblBtnNew: "Registrar Movimentação",
      lblBtnNewVariant: "primary",
      objEstoque: "1",
      mode: "save",
      keyword: "",
      isBrowser: isBrowser,
      keyword_res: "",
      lblCancelar: "Cancelar",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        { key: "created_at", label: "Data", sortable: true },
        { key: "nome", label: "Servidor", sortable: true },
        { key: "quantidade", label: "Quant", sortable: true },
        { key: "movimento", label: "Tipo", sortable: true },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions2",
          tdClass: "col-actions2",
        },
      ],
    };
  },
  methods: {
    searchAlmMov(length) {
      if (
        this.keyword.toString().length >= length ||
        this.keyword.toString().length == 0
      )
        this.loadAlmMovimento();
    },
    loadAlmMovimento() {
      const url = `${baseApiUrl}/alm-movimentos/${this.item.id}?page=${this.page}&key=${this.keyword}`;
      axios.get(url).then((res) => {
        this.$emit("objectInputs");
        this.alm_movimentos = res.data.data;
        this.alm_movimentos.forEach((element) => {
          element.created_at = moment(element.created_at).format("DD/MM/YYYY");
          const movmto = element.movimento;
          switch (movmto) {
            case "2":
              element.movimento = "Devolução(+)";
              break;
            case "3":
              element.movimento = "Ajuste(-)";
              break;
            case "4":
              element.movimento = "Ajuste(+)";
              break;
            case "5":
              element.movimento = "Empenho(-)";
              break;
            default:
              element.movimento = "Retirada(-)";
              break;
          }
          element.nome = highlight(
            element.nome.toUpperCase(),
            this.keyword.toString().toUpperCase()
          );
        });
        this.count = res.data.count;
        this.limit = res.data.limit;
        const pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${pluralize}`;
      });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.loadAlmMovimento();
      this.searchAlmMov(); //
    },
    reset() {
      this.keyword = "";
      this.mode = "save";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.getRefresh();
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    newRow() {

      this.almMovInputs = {
        id_alm_estq: this.item.id,
      };
      this.objEstoque = 1;
      this.modalTitle = `Registrar Movimentação`;
      this.$root.$emit("bv::show::modal", "modal-form-alm-movimento-data");
    },
    showRow(item, index, button) {
      Object.entries(item).forEach(([key, value]) => {
        if (item)
          this.almMovInputs = {
            ...this.almMovInputs,
            [key]: removeMark(value || ""),
          };
      });
      if (item.id_alm_proc) this.objEstoque = "1";
      this.modalTitle = `Registro: ${this.almMovInputs.nome}`;
      this.$root.$emit(
        "bv::show::modal",
        "modal-form-alm-movimento-data",
        button
      );
    },
    remove(id) {
      const url = `${baseApiUrl}/alm-movimentos/${this.item.id}/${id}`;
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.almMovInputs = {};
                this.loadAlmMovimento();
                this.searchAlmMov(); //
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
  },
  mounted() {
    this.loadUserParams();
    this.loadAlmMovimento();
    this.searchAlmMov();
  },
  watch: {
    page() {
      this.loadAlmMovimento();
      this.searchAlmMov(); //
    },
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.alm_movimentos-data {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>