<template>
  <div class="alm_movimento-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <input type="hidden" v-model="itemData.id_alm_estq" />
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group
            label="Servidor Responsável"
            label-for="alm_movimentos-id_servidor"
          >
            <v-select
              id="alm_movimentos-id_servidor"
              :options="listServidor"
              v-model="itemData.id_servidor"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Servidor ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Movimento" label-for="alm_movimentos-movimento">
            <b-form-select
              id="alm_movimentos-movimento"
              :options="optionsMovimento"
              v-model="itemData.movimento"
              @input="isQuantValid"
              :disabled="itemData.id && itemData.id > 0"
              :title="itemData.id && itemData.id > 0 ? 'Não é possível alterar o tipo de movimento. Exclua o registro se for necessário' : ''"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Quantidade"
            label-for="alm_movimentos-quantidade"
          >
            <b-form-input
              id="alm_movimentos-quantidade"
              type="text"
              v-model="itemData.quantidade"
              @keypress="isNumber($event)"
              :class="`${quantidadeValida ? '' : 'is-invalid'}`"
              required
              @input="isQuantValid"
              placeholder="Informe a Quantidade ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Motivo" label-for="alm_movimentos-motivo">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="alm_movimentos-motivo"
              type="text"
              v-model="itemData.motivo"
              maxlength="1024"
              required
              placeholder="Informe o Motivo ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
          :disabled="!quantidadeValida"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Almoxarifado Movimentos: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";

export default {
  name: "AlmMovimentoData",
  props: ["item", "estoqueContabil"],
  components: {},
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listAlmoEstq: [],
      listServidor: [],
      estoqueAtual: 0,
      quantidadeValida: true,
      optionsMovimento: [
        { value: "1", text: "Retirada(-)" },
        { value: "2", text: "Devolução(+)" },
        { value: "3", text: "Ajuste(-)" },
        { value: "4", text: "Ajuste(+)" },
        { value: "5", text: "Empenho(-)" },
      ],
    };
  },
  methods: {
    async loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/alm-movimentos/${this.item.id_alm_estq}/${this.item.id}`;
        await axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      } else {
        this.itemData = this.item;
      }
      this.estoqueAtual = this.itemData.quantidade || 0;
      this.isQuantValid();
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      const url = `${baseApiUrl}/alm-movimentos/${this.itemData.id_alm_estq}${id}`;
      if (this.quantidadeValida)
        axios[method](url, this.itemData)
          .then((res) => {
            this.itemData = res.data;
            this.$toasted.global.defaultSuccess();
            this.$emit("objectInputs");
          })
          .catch((error) => {
            showError(error);
          });
    },
    isQuantValid() {
      this.quantidadeValida = true;
      if (
        this.itemData.movimento &&
        ["1", "3", "5"].includes(this.itemData.movimento)
      )
        this.quantidadeValida =
          Number(this.itemData.quantidade) <=
          Number(this.estoqueAtual) + Number(this.estoqueContabil);
    },
    reset() {
      this.$bvModal.hide("modal-form-alm-movimento-data");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListAlmEstq() {
      const url = `${baseApiUrl}/alm-estoques/:id_alm_proc`;
      axios
        .patch(url)
        .then((res) => {
          this.listAlmoEstq = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.codigo}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaCadServidores() {
      const url = `${baseApiUrl}/cad-servidores`;
      axios
        .patch(url)
        .then((res) => {
          this.listServidor = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListAlmEstq();
    this.getListaCadServidores();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-right: 15px;
}
</style>
