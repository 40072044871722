<template>
  <!-- <div class="patrUnidade-data"> -->
  <div class="und_autonoma-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="CARDUG" label-for="und_autonomas-cardug">
            <b-form-input
              id="und_autonomas-cardug"
              type="text"
              v-model="itemData.cardug"
              @keypress="isNumber($event)"
              maxlength="6"
              required
              placeholder="Informe o CARDUG ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="7" sm="12">
          <b-form-group label="Nome" label-for="und_autonomas-nome">
            <b-form-input
              id="und_autonomas-nome"
              type="text"
              v-model="itemData.nome"
              maxlength="100"
              required
              placeholder="Informe o Nome ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="CNPJ" label-for="und_autonomas-cnpj">
            <the-mask
              id="und_autonomas-cnpj"
              class="form-control"
              :class="`form-control ${isCnpj}`"
              type="text"
              @input="setValidCnpj"
              v-model="itemData.cnpj"
              :mask="['##.###.###/####-##']"
              required
              placeholder="Informe o Cnpj ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Unid. Autônoma" label-for="und_autonomas-c_ua">
            <b-form-input
              id="und_autonomas-c_ua"
              type="text"
              @keypress="isNumber($event)"
              v-model="itemData.c_ua"
              required
              maxlength="4"
              placeholder="Informe a unidade autônoma ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="CEP" label-for="und_autonomas-cep">
            <the-mask
              id="und_autonomas-cep"
              type="text"
              @input="getEnderecoApi"
              v-model="itemData.cep"
              :mask="['########']"
              :class="`form-control ${cepClass}`"
              :readonly="mode === 'remove'"
              required
              placeholder="Informe o Cep..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="UF" label-for="und_autonomas-uf">
            <b-form-select
              id="und_autonomas-uf"
              :options="ufList"
              v-model="itemData.uf"
              @input="getListaCidades"
              required
              :disabled="!isCep || mode === 'remove'"
              placeholder="Informe o uf ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Cidade" label-for="und_autonomas-cidade">
            <b-form-select
              id="und_autonomas-cidade"
              v-model="itemData.cidade"
              :options="citiesList_cidade"
              required
              :disabled="!isCep || mode === 'remove'"
              placeholder="Informe a Cidade..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Bairro" label-for="und_autonomas-bairro">
            <b-form-input
              id="und_autonomas-bairro"
              type="text"
              v-model="itemData.bairro"
              :readonly="!isCep || mode === 'remove'"
              maxlength="50"
              required
              placeholder="Informe o Bairro ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Logradouro" label-for="und_autonomas-logradouro">
            <b-form-input
              id="und_autonomas-logradouro"
              ref="logradouro"
              type="text"
              v-model="itemData.logradouro"
              maxlength="100"
              required
              :readonly="!isCep || mode === 'remove'"
              placeholder="Informe o Logradouro ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Nº:" label-for="und_autonomas-numero">
            <b-form-input
              id="und_autonomas-numero"
              ref="numero"
              type="text"
              v-model="itemData.numero"
              :readonly="!isCep || mode === 'remove'"
              required
              placeholder="Informe o Numero ..."
            />
          </b-form-group>
        </b-col>

        <b-col md="5" sm="12">
          <b-form-group label="Complemento" label-for="und_autonomas-complemento">
            <b-form-input
              id="und_autonomas-complemento"
              type="text"
              v-model="itemData.complemento"
              maxlength="50"
              required
              placeholder="Informe o Complemento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Email Cpl" label-for="und_autonomas-email_cpl">
            <b-form-input
              id="und_autonomas-email_cpl"
              :class="`form-control ${isEmail}`"
              type="email"
              v-model="itemData.email_cpl"
              @input="setValidEmail"
              required
              placeholder="Informe o Email Cpl ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Telefone" label-for="und_autonomas-telefone">
            <the-mask
              id="und_autonomas-telefone"
              class="form-control"
              v-model="itemData.telefone"
              :mask="['(##) ####-####', '(##) #####-####']"
              required
              :readonly="mode === 'remove'"
              placeholder="Informe o Telefone ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nome Respensável da Gestão"
            label-for="und_autonomas-nome_resp_gestao"
          >
            <b-form-input
              id="und_autonomas-nome_resp_gestao"
              type="text"
              v-model="itemData.nome_resp_gestao"
              maxlength="200"
              required
              placeholder="Informe o Nome Resp Gestao ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Cpf Responsável da Gestão"
            label-for="und_autonomas-cpf_resp_gestao"
          >
            <the-mask
              class="form-control"
              id="und_autonomas-cpf_resp_gestao"
              :class="`form-control ${setValidCpf(itemData.cpf_resp_gestao)}`"
              type="text"
              v-model="itemData.cpf_resp_gestao"
              :mask="['###.###.###-##']"
              required
              @input="setValidCpf"
              :readonly="mode === 'remove'"
              placeholder="Informe o CPF do Responsável da Gestão..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nome do Controlador interno"
            label-for="und_autonomas-nome_cont_int"
          >
            <b-form-input
              id="und_autonomas-nome_cont_int"
              type="text"
              v-model="itemData.nome_cont_int"
              maxlength="200"
              required
              placeholder="Informe o Nome Controlador Interno ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Cpf Controlador interno"
            label-for="und_autonomas-cpf_cont_int"
          >
            <the-mask
              class="form-control"
              id="und_autonomas-cpf_cont_int"
              :class="`form-control ${setValidCpf(itemData.cpf_cont_int)}`"
              type="text"
              v-model="itemData.cpf_cont_int"
              :mask="['###.###.###-##']"
              required
              @input="setValidCpf"
              :readonly="mode === 'remove'"
              placeholder="Informe o CPF do Controlador Interno ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Nome do Responsável Contábil"
            label-for="und_autonomas-nome_resp_contabil"
          >
            <b-form-input
              id="und_autonomas-nome_resp_contabil"
              type="text"
              v-model="itemData.nome_resp_contabil"
              maxlength="200"
              required
              placeholder="Informe o Nome Resp Contábil ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Cpf do Responsável Contabil"
            label-for="und_autonomas-cpf_resp_contabil"
          >
            <the-mask
              class="form-control"
              id="und_autonomas-cpf_resp_contabil"
              :class="`form-control ${setValidCpf(itemData.cpf_resp_contabil)}`"
              type="text"
              v-model="itemData.cpf_resp_contabil"
              :mask="['###.###.###-##']"
              required
              @input="setValidCpf"
              :readonly="mode === 'remove'"
              placeholder="Informe o Cpf Resp Contabil ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset"> Cancelar </b-button>
        <code v-if="itemData.hash" class="float-right"
          >Unidade Autônoma: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError, setValidCep } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, emailOrError } from "@/config/globalFacilities";
import { ufList } from "@/config/lists";
import { TheMask } from "vue-the-mask";
import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";

export default {
  name: "UAData",
  props: ["item"],
  components: { TheMask },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isEmail: "",
      isCnpj: "",
      citiesList_cidade: [],
      ufList: ufList,
      cepClass: undefined,
      isCep: undefined,
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/und-autonomas/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          const cep = setValidCep(this.itemData.cep);
          this.cepClass = cep.cepClass;
          this.isCep = cep.isCep;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      const url = `${baseApiUrl}/und-autonomas${id}`
      console.log(url);
      axios[method](url, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
          this.$bvModal.hide("modal-form");
        })
        .catch((error) => {
          console.log(error);
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    setValidCpf(value) {
      let isValid = "";
      let classe = "";
      if (value) {
        // Remove caracteres não numéricos
        value = value.toString().replace(/([^\d])+/gim, "");
        // Só executa a validação se o campo tiver onze dígitos numéricos
        if (value && value.length == 11) isValid = cpf.isValid(value);

        if (isValid) classe = "is-valid";
        // Se for válido...
        else if (!isValid) classe = "is-invalid";
        // Se for inválido...
        else classe = ""; // Se não...
      }
      return classe;
    },
    setValidCnpj() {
      if (
        this.itemData.cnpj &&
        this.itemData.cnpj.length == 14 &&
        cnpj.isValid(this.itemData.cnpj)
      )
        this.isCnpj = "is-valid";
      else if (
        this.itemData.cnpj &&
        this.itemData.cnpj.length == 14 &&
        !cnpj.isValid(this.itemData.cnpj)
      )
        this.isCnpj = "is-invalid";
      else this.isCnpj = "";
    },
    setValidEmail() {
      if (this.itemData.email_cpl && this.itemData.email_cpl.length > 0) {
        if (emailOrError(this.itemData.email_cpl)) this.isEmail = "is-valid";
        else this.isEmail = "is-invalid";
      } else this.isEmail = "";
    },
    getEnderecoApi() {
      const cep = setValidCep(this.itemData.cep);
      this.cepClass = cep.cepClass;
      this.isCep = cep.isCep;
      if (this.isCep) {
        const urlCep = `https://viacep.com.br/ws/${this.itemData.cep}/json/`
        axios.get(urlCep)
          .then((obj) => {
            this.cepData = obj.data;
            this.getListaCidades(this.cepData.uf);
          })
          .then(() => {
            if (this.cepData.cep) {
              this.itemData.logradouro = this.cepData.logradouro;
              this.itemData.complemento = this.cepData.complemento;
              this.itemData.bairro = this.cepData.bairro;
              this.itemData.uf = this.cepData.uf;
              this.itemData.cidade = this.cepData.localidade.toUpperCase();
              if (this.cepData.logradouro) this.$refs.numero.focus();
              else this.$refs.logradouro.focus();
            }
          });
      }
    },
    getListaCidades(uf) {
      if (uf) {
        const url = `${baseApiUrl}/cidades/${uf}`;
        axios
          .get(url)
          .then((res) => {
            this.citiesList_cidade = res.data.data.map((data) => {
              return {
                value: data.municipio.toUpperCase(),
                text: data.municipio.toUpperCase(),
              };
            });
          })
          .catch((error) => {
            showError(error);
          });
      }
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    if (this.itemData.uf) this.getListaCidades(this.itemData.uf);
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
