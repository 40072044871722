<template>
  <div class="exec_item-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="9" sm="12">
          <b-form-group label="Nº Item" label-for="exec_itens-id_item">
            <v-select
              id="exec_itens-id_item"
              :options="listItens"
              v-model="itemData.id_item"
              :reduce="(label) => label.code"
              required
              placeholder="Informe o Nº do Item ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Unidade Medida"
            label-for="exec_itens-unid_medida"
          >
            <b-form-input
              id="exec_itens-unid_medida"
              type="text"
              v-model="itemData.unid_medida"
              maxlength="255"
              required
              placeholder="Informe a Unidade de Medida ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Quantidade Inicial"
            label-for="exec_itens-qtd_inicial"
          >
            <b-form-input
              id="exec_itens-qtd_inicial"
              type="text"
              @keypress="isNumber($event)"
              v-model="itemData.qtd_inicial"
              maxlength="10"
              required
              placeholder="Informe a Quantidade Contratada ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Quantidade Nova" label-for="exec_itens-qtd_nova">
            <b-form-input
              id="exec_itens-qtd_nova"
              type="text"
              @keypress="isNumber($event)"
              v-model="itemData.qtd_nova"
              maxlength="10"
              required
              placeholder="Informe a Quantidade Nova ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Valor Unitário Inicial"
            label-for="exec_itens-valor_unit_inicial"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor_unit_inicial"
                v-bind="money"
                id="exec_itens-valor_unit_inicial"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Valor Unitário Novo"
            label-for="exec_itens-valor_unit_novo"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor_unit_novo"
                v-bind="money"
                id="exec_itens-valor_unit_novo"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="exec_itens-descricao">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_itens-descricao"
              type="text"
              v-model="itemData.descricao"
              maxlength="1024"
              required
              placeholder="Descrição do Item ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Item Aditivo: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber } from "@/config/globalFacilities";
import { Money } from "v-money";

export default {
  name: "ExecAditivoData",
  props: ["item"], // exec_itens
  components: { Money },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listContrato: [],
      listAditivo: [],
      isDate: "",
      listItens: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/exec-itens/${this.item.id_exec_aditivo}/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      } else {
        this.itemData = {
          cod_lote: this.item.aditivo.idPlanGrupos,
          id_exec_contrat: this.item.id_exec_contrat,
          id_exec_aditivo: this.item.id_exec_aditivo,
        };
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](
        `${baseApiUrl}/exec-itens/${this.item.id_exec_aditivo}${id}`,
        this.itemData
      )
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form-ExecItemData");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListItens() {
      let lic_cont = "";
      let id;
      let agrupamento = this.item.aditivo && this.item.aditivo.agrupamento || 1;
      if (this.item.aditivo && this.item.aditivo.idPlanLicitacoes > 0) {
        lic_cont = "1";
        id = this.item.aditivo.idPlanLicitacoes;
      } else if (this.item.aditivo && this.item.aditivo.idPlanContratos > 0) {
        lic_cont = "2";
        id = this.item.aditivo.idPlanContratos;
      }
      const url = `${baseApiUrl}/exec-aditivos/${id}?lic_cont=${lic_cont}&agrupamento=${agrupamento}`;
      axios
        .patch(url)
        .then((res) => {
          this.listItens = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_item} (${data.descricao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListItens();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
</style>
