<template>
  <div class="siap-panel">
    <PageTitle
      icon="far fa-edit"
      main="Almoxarifado"
      sub="Processos, Estoque e Movimentações"
    />
    <div class="siap-panel-tabs">
      <b-card no-body id="siap-panel">
        <b-tabs v-model="tabIndex" card>
          <b-tab title="Almoxarifado" lazy>
            <AlmoxarifadosGrid ref="AlmoxarifadosGrid" />
          </b-tab>
          <b-tab title="Movimentação" lazy>
            <AlmProcessosGrid ref="AlmProcessosGrid" />
          </b-tab>
          <b-tab title="Relatórios" lazy>
            <AlmRelatorioData ref="AlmRelatorioData" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AlmoxarifadosGrid from "../almoxarifadosAdmin/AlmoxarifadosGrid";
import AlmProcessosGrid from "../almProcessosAdmin/AlmProcessosGrid";
import AlmRelatorioData from "../almRelatoriosAdmin/AlmRelatorioData";
import PageTitle from "../template/PageTitle";

export default {
  name: "AlmAdminPanels",
  components: {
    PageTitle,
    AlmoxarifadosGrid,
    AlmProcessosGrid,
    AlmRelatorioData,
  },
  data: function () {
    return {
      tabIndex: undefined,
    };
  },
  methods: {
    goTab() {
      switch (location.hash) {
        case "#processos":
          this.tabIndex = 1;
          break;
        case "#relatorios":
          this.tabIndex = 2;
          break;
        default:
          this.tabIndex = 0;
          break;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.goTab();
    }, 250);
  },
  computed: mapState(["user"]),
  watch: {
    $route() {
      this.goTab();
    },
  },
};
</script>

<style>
.card-body {
  padding: 0.5rem;
}
</style>