<template>
  <div class="exec_contrato-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12" v-if="tipoProposta == '1'">
          <b-form-group
            label="Nº do Processo (Licitação)"
            label-for="exec_contratos-id_pl_lic"
          >
            <v-select
              id="exec_contratos-id_pl_lic"
              :options="listLicitacao"
              v-model="itemData.id_pl_lic"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe a Licitação ..."
              @input="getLicitacao"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12" v-if="tipoProposta == '2'">
          <b-form-group
            label="Nº da Contratação"
            label-for="exec_contratos-id_pl_cont"
          >
            <v-select
              id="exec_contratos-id_pl_cont"
              :options="listContratacao"
              v-model="itemData.id_pl_cont"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº da Contratação ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <!-- <b-col md="12" sm="12">
          {{itemData.adjudicado.cadas_nome}}
        </b-col>
        <p>Nome do Adjudicado</p> -->
        <!-- <b-col md="12" sm="12">
          <b-form-group
            label="Contratado (Pessoas & Empresas)"
            label-for="exec_contratos-id_cadastro"
          >
            <v-select
              id="exec_contratos-id_cadastro"
              :options="listCadastros"
              v-model="itemData.id_cadastro"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Contratado ..."
              @input="getVencedor"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col> -->
        <b-col md="4" sm="12">
          <b-form-group
            label="Nº Contrato"
            label-for="exec_contratos-n_contrato"
          >
            <b-form-input
              id="exec_contratos-n_contrato"
              type="text"
              v-model="itemData.n_contrato"
              @keypress="isNumber($event)"
              maxlength="16"
              required
              placeholder="Informe o Nº do Contrato ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Tipo Jurídico Contratado"
            label-for="exec_contratos-tipo_juridico"
          >
            <b-form-select
              id="exec_contratos-tipo_juridico"
              :options="optionsTipoPessoa"
              v-model="itemData.tipo_juridico"
              required
              placeholder="Informe o Tipo Jurídico Contratado ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Pagamento Antecipado"
            label-for="exec_contratos-pag_antecipado"
          >
            <b-form-select
              id="exec_contratos-pag_antecipado"
              :options="optionSimNao"
              v-model="itemData.pag_antecipado"
              required
              placeholder="Informe se Permite o Pagamento Antecipado ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nº Ata Registro"
            label-for="exec_contratos-id_adj_reg"
          >
            <v-select
              id="exec_contratos-id_adj_reg"
              :options="listAtaRegistro"
              v-model="itemData.id_adj_reg"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº Ata Registro ..."
              @input="getRegistro"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Inicio Vigência"
            label-for="exec_contratos-inicio_vigencia"
          >
            <date-picker
              v-model="itemData.inicio_vigencia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Fim Vigência"
            label-for="exec_contratos-fim_vigencia"
          >
            <date-picker
              v-model="itemData.fim_vigencia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Valor" label-for="exec_contratos-valor">
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor"
                v-bind="money"
                id="exec_contratos-valor"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Tipo" label-for="exec_contratos-tipo">
            <b-form-select
              id="exec_contratos-tipo"
              :options="optionsTipoContrato"
              v-model="itemData.tipo"
              required
              placeholder="Informe o Tipo de Contrato ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Tipo Processo"
            label-for="exec_contratos-tipo_processo"
          >
            <b-form-select
              id="exec_contratos-tipo_processo"
              :options="optionsProcessoCont"
              v-model="itemData.tipo_processo"
              required
              placeholder="Informe o Tipo de Processo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Referência Legal"
            label-for="exec_contratos-ref_legal_disp"
          >
            <b-form-select
              id="exec_contratos-ref_legal_disp"
              :options="optionsRefLegal"
              v-model="itemData.ref_legal_disp"
              placeholder="Informe o Referência Legal para Dispensa ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Natureza Objeto"
            label-for="exec_contratos-natureza_obj"
          >
            <b-form-select
              id="exec_contratos-natureza_obj"
              :options="optionsNatObjContEx"
              type="text"
              v-model="itemData.natureza_obj"
              required
              placeholder="Informe a Natureza Objeto ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Garantia" label-for="exec_contratos-garantia">
            <b-form-select
              id="exec_contratos-garantia"
              :options="optionSimNao"
              v-model="itemData.garantia"
              required
              placeholder="Exige Garantia?"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Tipo Garantia"
            label-for="exec_contratos-tipo_garantia"
          >
            <b-form-select
              id="exec_contratos-tipo_garantia"
              :options="optionsTipoGarantia"
              v-model="itemData.tipo_garantia"
              placeholder="Informe o Tipo Garantia ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Inicio Vigência Garantia"
            label-for="exec_contratos-inicio_vig_garantia"
          >
            <date-picker
              v-model="itemData.inicio_vig_garantia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Fim Vigência Garantia"
            label-for="exec_contratos-fim_vig_garantia"
          >
            <date-picker
              v-model="itemData.fim_vig_garantia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Base Pagamento Antecipado"
            label-for="exec_contratos-base_pag_antecip"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_contratos-base_pag_antecip"
              type="text"
              v-model="itemData.base_pag_antecip"
              maxlength="255"
              placeholder="Informe a Base Pagamento Antecipado ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="exec_contratos-descricao">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_contratos-descricao"
              type="text"
              v-model="itemData.descricao"
              maxlength="1024"
              required
              placeholder="Descrição do Objeto do Contrato ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Execução Contrato: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import {
  optionSimNao,
  optionsTipoPessoa,
  optionsTipoContrato,
  optionsProcessoCont,
  optionsNatObjContEx,
  optionsRefLegal,
  optionsTipoGarantia,
} from "@/config/lists";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ExecContratoData",
  props: ["item", "tipoProposta"],
  components: { Money, DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listLicitacao: [],
      licitacao: {},
      registro: {},
      vencedor: {},
      listContratacao: [],
      listCadastros: [],
      listAtaRegistro: [],
      optionSimNao: optionSimNao,
      optionsTipoPessoa: optionsTipoPessoa,
      optionsTipoContrato: optionsTipoContrato,
      optionsProcessoCont: optionsProcessoCont,
      optionsNatObjContEx: optionsNatObjContEx,
      optionsRefLegal: optionsRefLegal,
      optionsTipoGarantia: optionsTipoGarantia,
      isCnpj: "",
      isDate: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/exec-contratos/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.getLicitacao();
          this.getRegistro();
          // this.getVencedor();
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/exec-contratos${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.getLicitacao();
          this.getRegistro();
          // this.getVencedor();
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListPlanLicitacoes() {
      const url = `${baseApiUrl}/plan-licitacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listLicitacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getLicitacao() {
      const url = `${baseApiUrl}/plan-licitacoes/${this.itemData.id_pl_lic}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.licitacao = res.data
          //  axios.get(`${baseApiUrl}/cadastros/${this.itemData.id_pl_lic}`)
        })
        .catch((error) => {
          showError(error);
        });
    },
    getRegistro() {
      const url = `${baseApiUrl}/adj-registros/${this.itemData.id_adj_reg}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.registro = res.data
        })
        .catch((error) => {
          showError(error);
        });
    },
      //const url = `${baseApiUrl}/adj-grupos/:id_plan_licit/${this.itemData.id_cadastro}`;
    // getVencedor() {
    //   const url = `${baseApiUrl}/adj-prop-itens/:id_adj_prop/${this.itemData.id_cadastro}`;
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       this.itemData.vencedor = res.data
    //     })
    //     .catch((error) => {
    //       showError(error);
    //     });
    // },
    getListPlanContratacoes() {
      const url = `${baseApiUrl}/plan-contratacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listContratacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contratacao}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadastros = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListAdjRegistros() {
      const url = `${baseApiUrl}/adj-registros`;
      axios
        .patch(url)
        .then((res) => {
          this.listAtaRegistro = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_ata}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    if (this.tipoProposta == "1") this.getListPlanLicitacoes();
    else if (this.tipoProposta == "2") this.getListPlanContratacoes();
    this.getListCadastros();
    this.getListAdjRegistros();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
