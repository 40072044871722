<template>
  <div class="cad_servidor-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
          <b-col md="3" sm="12">
          <b-form-group label="CPF" label-for="fp_servidores-cpf">
            <the-mask
              id="fp_servidores-cpf"
              class="form-control"
              :class="`form-control ${isCpf}`"
              type="text"
              v-model="itemData.cpf"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              required
              @input="setValidCpf"
              :readonly="mode === 'remove'"
              placeholder="Informe o CPF..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Nome" label-for="fp_servidores-nome">
            <b-form-input
              id="fp_servidores-nome"
              type="text"
              v-model="itemData.nome"
              maxlength="255"
              required
              placeholder="Informe o Nome ..."
            />
          </b-form-group>
        </b-col>
         <b-col md="3" sm="12">
          <b-form-group
            label="Data de Nascimento"
            label-for="fp_servidores-nascimento"
          >
            <date-picker
              v-model="itemData.nascimento"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Nome Social" label-for="fp_servidores-nome_social">
            <b-form-input
              id="fp_servidores-nome_social"
              type="text"
              v-model="itemData.nome_social"
              maxlength="255"
              placeholder="Informe o Nome Social..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="RG" label-for="fp_servidores-rg">
            <b-form-input
              id="fp_servidores-rg"
              type="text"
              v-model="itemData.rg"
              maxlength="10"
              required
              placeholder="Informe o RG..."
            />
          </b-form-group>
        </b-col>
         <b-col md="3" sm="12">
          <b-form-group
            label="Data Expedição RG"
            label-for="fp_servidores-data_rg"
          >
            <date-picker
              v-model="itemData.data_rg"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Número CTPS" label-for="fp_servidores-n_ctps">
            <b-form-input
              id="fp_servidores-n_ctps"
              type="text"
              v-model="itemData.n_ctps"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe Número CTPS..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Número PIS/PASEP" label-for="fp_servidores-n_pis_pasep">
            <b-form-input
              id="fp_servidores-n_pis_pasep"
              type="text"
              v-model="itemData.n_pis_pasep"
              @keypress="isNumber($event)"
              maxlength="11"
              required
              placeholder="Informe Número PIS/PASEP..."
            />
          </b-form-group>
        </b-col>
         <b-col md="6" sm="12">
          <b-form-group label="Número TÍtulo Eleitor" label-for="fp_servidores-n_titulo">
            <b-form-input
              id="fp_servidores-n_titulo"
              type="text"
              v-model="itemData.n_titulo"
              @keypress="isNumber($event)"
              maxlength="12"
              required
              placeholder="Informe Número TÍtulo Eleitor..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Zona TÍtulo Eleitor" label-for="fp_servidores-zona_titulo">
            <b-form-input
              id="fp_servidores-zona_titulo"
              type="text"
              v-model="itemData.zona_titulo"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe a Zona TÍtulo Eleitor..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Seção TÍtulo Eleitor" label-for="fp_servidores-secao_titulo">
            <b-form-input
              id="fp_servidores-secao_titulo"
              type="text"
              v-model="itemData.secao_titulo"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe a Seção TÍtulo Eleitor..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="UF TÍtulo Eleitor" label-for="cad_enderecos-uf_titulo">
            <b-form-select
              id="cad_enderecos-uf_titulo"
              :options="ufList"
              v-model="itemData.uf_titulo"
              @input="getListaCidades"
              required
              placeholder="Informe o uf..."
            />
          </b-form-group>
        </b-col>
       
        <b-col md="3" sm="12">
          <b-form-group label="UF Nascimento" label-for="fp_servidores-uf_nascimento">
            <b-form-select
              id="fp_servidores-uf_nascimento"
              :options="ufList"
              v-model="itemData.uf_nascimento"
              @input="getListaCidades"
              required
              placeholder="Informe o uf..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Cidade de Nascimento" label-for="fp_servidores-cidade_nascimento">
            <b-form-select
              id="fp_servidores-cidade_nascimento"
              v-model="itemData.cidade_nascimento"
              :options="citiesList_cidade"
              required
              placeholder="Informe a Cidade de Nascimento..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Escolaridade" label-for="fp_servidores-escolaridade">
            <b-form-select
              id="fp_servidores-escolaridade"
              v-model="itemData.escolaridade"
              :options="optionsEscolaridade"
              required
              placeholder="Informe a Escolaridade..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Gênero" label-for="fp_servidores-genero">
            <b-form-select
              id="fp_servidores-genero"
              v-model="itemData.genero"
              :options="optionsGenero"
              required
              placeholder="Informe o Gênero..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Estado Civil" label-for="fp_servidores-estado_civil">
            <b-form-select
              id="fp_servidores-estado_civil"
              v-model="itemData.estado_civil"
              :options="optionsEstadoCivil"
              required
              placeholder="Informe o Estado Civil..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Nome da Mãe" label-for="fp_servidores-nome_mae">
            <b-form-input
              id="fp_servidores-nome_mae"
              type="text"
              v-model="itemData.nome_mae"
              maxlength="255"
              placeholder="Informe o Nome da Mãe..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Nome do Pai" label-for="fp_servidores-nome_pai">
            <b-form-input
              id="fp_servidores-nome_pai"
              type="text"
              v-model="itemData.nome_pai"
              maxlength="255"
              placeholder="Informe o Nome do Pai..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Email" label-for="fp_servidores-email">
            <b-form-input
              id="fp_servidores-email"
              :class="`form-control ${isEmail}`"
              type="email"
              v-model="itemData.email"
              @input="setValidEmail"
              placeholder="Informe o Email..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Telefone Fixo" label-for="fp_servidores-tel_fixo">
            <the-mask
              id="fp_servidores-tel_fixo"
              class="form-control"
              v-model="itemData.tel_fixo"
              :mask="['(##) ####-####', '(##) #####-####']"
              required
              :readonly="mode === 'remove'"
              placeholder="Informe o Nº do Telefone Fixo..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Celular" label-for="fp_servidores-tel_cel">
            <the-mask
              id="fp_servidores-tel_cel"
              class="form-control"
              v-model="itemData.tel_cel"
              :mask="['(##) ####-####', '(##) #####-####']"
              required
              :readonly="mode === 'remove'"
              placeholder="Informe o Nº do Celular ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Cadastro Servidor: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import {
  isNumber,
  emailOrError,
  datePickerLocale,
} from "@/config/globalFacilities";
import { ufList } from "@/config/lists";
import { TheMask } from "vue-the-mask";
import { cpf } from "cpf-cnpj-validator";
//import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "CadServidorData",
  props: ["item"],
  components: { DatePicker, TheMask },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isEmail: "",
      citiesList_cidade: [],
      ufList: ufList,
      isCpf: "",
      isDate: "",
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      optionsEscolaridade: [
        { value: "1", text: "Analfabeto(a)" },
        { value: "2", text: "Alfabetizado(a)" },
        { value: "3", text: "Fundamental" },
        { value: "4", text: "Ensino Médio" },
        { value: "5", text: "Ensino Superior" },
        { value: "6", text: "Pós-Graduado" },
        { value: "7", text: "Mestrado" },
        { value: "8", text: "Doutorado" },
        { value: "9", text: "Pós-Doutorado" },
      ],
      optionsGenero: [
        { value: "F", text: "Feminino" },
        { value: "M", text: "Masculino" },
        { value: "O", text: "Outros" },
      ],
      optionsEstadoCivil: [
        { value: "1", text: "Solteiro(a)" },
        { value: "2", text: "União Estável" },
        { value: "3", text: "Casado(a)" },
        { value: "4", text: "Divorciado(a)" },
        { value: "5", text: "Viúvo(a)" },
        { value: "6", text: "Outros" },
      ],
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/cad-servidores/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/cad-servidores${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListaCidades(uf) {
      if (uf) {
        const url = `${baseApiUrl}/cidades/${uf}`;
        console.log(url);
        axios
          .get(url)
          .then((res) => {
            console.log(res.data);
            this.citiesList_cidade = res.data.data.map((data) => {
              return {
                value: data.municipio.toUpperCase(),
                text: data.municipio.toUpperCase(),
              };
            });
          })
          .catch((error) => {
            showError(error);
          });
      }
    },
    setValidCpf() {
      if (
        this.itemData.cpf &&
        this.itemData.cpf.length == 11 &&
        cpf.isValid(this.itemData.cpf)
      )
        this.isCpf = "is-valid";
      else if (
        this.itemData.cpf &&
        this.itemData.cpf.length == 11 &&
        !cpf.isValid(this.itemData.cpf)
      )
        this.isCpf = "is-invalid";
      else this.isCpf = "";
    },
    // setValidDate() {
    //   if (this.itemData.nascimento && this.itemData.nascimento.length == 8)
    //     this.isDate = "is-valid";
    //   else if (
    //     this.itemData.nascimento &&
    //     this.itemData.nascimento.length > 0 &&
    //     this.itemData.nascimento.length < 8
    //   )
    //     this.isDate = "is-invalid";
    //   else this.isDate = "";
    // },
    setValidEmail() {
      if (this.itemData.email && this.itemData.email.length > 0) {
        if (emailOrError(this.itemData.email)) this.isEmail = "is-valid";
        else this.isEmail = "is-invalid";
      } else this.isEmail = "";
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    if (this.itemData.uf) this.getListaCidades(this.itemData.uf);
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
