<template>
  <div>
    <b-card no-body>
      <div class="alm_estoques-grid pdd-10">
        <b-input-group size="md" class="mt-3 mb-3">
          <!-- :disabled="btnNoNewItem" -->
          <b-button variant="outline-primary" slot="prepend" @click="newRow()"
            :title="`${btnNoNewItem ? 'Não há mais itens a inserir' : ''}`">
            Registrar Aquisição Material
          </b-button>
          <b-form-input type="text" placeholder="Digite aqui para localizar o registro..." v-b-tooltip.hover
            :title="`Digite ao menos ${charSearchCount} caracteres para pesquisa dinâmica e use espaços para pesquisar por mais de uma expressão`"
            v-model="keyword" @input="searchAlmEstoq(charSearchCount)" @blur="searchAlmEstoq(0)" ref="keyword" />
          <b-btn size="sm" @click="searchAlmEstoq(0)">
            <i class="fa fa-search"></i>
          </b-btn>
          <b-input-group-text slot="append">
            <span v-if="isBrowser">{{ keyword_res }}&nbsp;</span>
            <b-btn :disabled="!keyword" variant="link" size="sm" @click="getRefresh" class="p-0">
              <i class="fa fa-remove"></i>
            </b-btn>
          </b-input-group-text>
        </b-input-group>
        <b-table hover striped responsive :items="alm_estoques" :fields="fields">
          <template #cell(n_item)="data">
            <span v-html="data.item.n_item || data.item.n_item_grupo" />
          </template>
            <template #cell(descricao)="data">
              <span v-html="data.item.descricao" />
            </template>
            <template #cell(valor_unitario)="data">
              <span class="" v-html="data.item.valor_unitario.toLocaleString('pt-br', {
                    style: 'currency', currency:
                      'BRL'
                  })" />
            </template>
          <template #cell(actions)="row">
            <b-button v-if="userParams.id >= 1" variant="outline-info" size="sm" class="mr-1" v-b-tooltip.hover
              title="Editar registro" @click="showRow(row.item, row.index, $event.target)" href="#processos">
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button v-if="userParams.id >= 1" variant="outline-danger" size="sm" class="mr-1" v-b-tooltip.hover
              title="Remover registro" @click="remove(row.item.id)">
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination size="md" v-model="page" :total-rows="count" :per-page="limit" />
      </div>
    </b-card>
    <b-modal size="xl" id="modal-form-alm-estoque-data" :title="modalTitle" centered scrollable hide-footer>
      <AlmEstoqueData :tipoProposta="objProcesso" :item="almEstoquesInputs" @objectInputs="loadAlmEstoque" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import { getDecimalFormater } from "@/config/globalFacilities";
import { showError, highlight, removeMark } from "@/global";
import AlmEstoqueData from "./AlmEstoqueData";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "AlmEstoquesGrid",
  components: { AlmEstoqueData },
  props: ["item"],
  data: function () {
    return {
      userParams: {},
      charSearchCount: 0,
      modalTitle: "Novo Registro",
      alm_estoques: [], // table
      alm_estoque: {}, // field
      almEstoquesInputs: {}, // form data
      loading: false,
      objProcesso: "1",
      mode: "save",
      keyword: "",
      isBrowser: isBrowser,
      btnNoNewItem: false,
      keyword_res: "",
      lblCancelar: "Cancelar",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        { key: "n_item", label: "Item", sortable: true },
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "quantidade", label: "Quant", sortable: true,
          thClass: "col-value",
          tdClass: "col-value", },
        { key: "valor_unitario", label: "R$ Unit", sortable: true,
          thClass: "col-value",
          tdClass: "col-value",
         },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions3",
          tdClass: "col-actions3",
        },
      ],
    };
  },
  methods: {
    searchAlmEstoq(length) {
      if (
        this.keyword.toString().length >= length ||
        this.keyword.toString().length == 0
      )
        this.loadAlmEstoque();
    },
    loadAlmEstoque() {
      const url = `${baseApiUrl}/alm-estoques/${this.item.id}?page=${this.page}&key=${this.keyword}`;
      axios.get(url).then((res) => {
        this.alm_estoques = res.data.data;
        this.alm_estoques.forEach((element) => {
          if (element.descricao)
            element.descricao = highlight(
              element.descricao
                .split(" ")
                .slice(0, 5)
                .toString()
                .replaceAll(",", " ") +
              `${element.descricao.split(" ").length > 5 ? "..." : ""}`,
              this.keyword.toString().toUpperCase()
            );
        });
        // this.getListPlanItens();
        this.count = res.data.count;
        this.limit = res.data.limit;
        const pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${pluralize}`;
      });
    },
    getListPlanItens() {
      const id_cadastro = this.item.id_cad
        ? `id_cadastro=${this.item.id_cad}`
        : "";
      const url = `${baseApiUrl}/alm-estoques/${this.item.id}/0?${id_cadastro}`;
      axios
        .patch(url)
        .then((res) => {
          this.btnNoNewItem = res.data.data.length == 0;
        })
        .catch((error) => {
          showError(error);
        });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.searchAlmEstoq(0);
      // this.getListPlanItens();
    },
    reset() {
      this.keyword = "";
      this.mode = "save";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.getRefresh();
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    newRow() {
      if (this.item.id_pl_lic) {
        this.objProcesso = 1;
        this.modalTitle = `Novo Registro de Estoque de uma Licitação`;
      } else if (this.item.id_pl_cont) {
        this.objProcesso = 2;
        this.modalTitle = `Novo Registro de Estoque de uma Contratação`;
      }
      this.almEstoquesInputs = {
        id_pl_lic: this.item.id_pl_lic,
        id_pl_cont: this.item.id_pl_cont,
        id_alm_proc: this.item.id,
        agrupamento: this.item.agrupamento
      };
      this.$root.$emit("bv::show::modal", "modal-form-alm-estoque-data");
    },
    showRow(item, index, button) {
      Object.entries(item).forEach(([key, value]) => {
        if (item)
          this.almEstoquesInputs = {
            ...this.almEstoquesInputs,
            agrupamento: this.item.agrupamento,
            [key]: removeMark(value || ""),
          };
      });
      if (item.id_pl_lic) this.objProcesso = "1";
      else if (item.id_pl_cont) this.objProcesso = "2";
      this.modalTitle = `Item: Quantidade (${this.almEstoquesInputs.quantidade})`;
      this.$root.$emit(
        "bv::show::modal",
        "modal-form-alm-estoque-data",
        button
      );
    },
    remove(id) {
      const url = `${baseApiUrl}/alm-estoques/${this.item.id}/${id}`;
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.almEstoquesInputs = {};
                this.searchAlmEstoq(0);
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
  },
  mounted() {
    this.loadUserParams();
    this.searchAlmEstoq(0);
  },
  watch: {
    page() {
      this.searchAlmEstoq(0);
    },
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.alm_estoques-data {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>