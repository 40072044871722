<template>
  <div class="plan_agente-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group
            :label="labelProcesso"
            label-for="plan_agentes-id_plan_licit"
          >
            <v-select
              id="plan_agentes-id_plan_licit"
              :options="listLicitacao"
              v-model="itemData.id_plan_licit"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              @input="getItemPlanLicitacao"
              required
              placeholder="Informe o número do processo ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Agente de contratação (Servidor)"
            label-for="plan_agentes-id_servidor"
          >
            <v-select
              id="plan_agentes-id_servidor"
              :options="listServidor"
              v-model="itemData.id_servidor"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Servidor ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Ato Designação"
            label-for="plan_agentes-data_ato"
          >
            <date-picker
              v-model="itemData.data_ato"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Início da Designação"
            label-for="plan_agentes-data_inicio"
          >
            <date-picker
              v-model="itemData.data_inicio"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Ato Designação"
            label-for="plan_agentes-ato_desig"
          >
            <b-form-input
              id="plan_agentes-ato_desig"
              type="text"
              v-model="itemData.ato_desig"
              @keypress="isNumber($event)"
              maxlength="16"
              required
              placeholder="Informe o Número do Ato..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Veículo de Publicação Designação"
            label-for="plan_agentes-vei_pub"
          >
            <b-form-select
              id="plan_agentes-vei_pub"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_pub"
              required
              placeholder="Informe o Veículo de Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Papel" label-for="plan_agentes-funcao">
            <b-form-select
              id="plan_agentes-funcao"
              :options="optionsFuncao"
              v-model="itemData.funcao"
              required
              placeholder="Informe o Papel ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Agente Contratação: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { cpf } from "cpf-cnpj-validator";
//import { TheMask } from "vue-the-mask";
import moment from "moment";
import { optionsVeiculosPub } from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "PlanAgenteData",
  props: ["item", "tipoProposta"],
  components: { DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelProcesso: "Nº do processo (Licitação)",
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      labelsDatePicker: datePickerLocale,
      isDate: "",
      isCpf: "",
      listLicitacao: [],
      listServidor: [],
      listItem: [],
      optionsVeiculosPub: optionsVeiculosPub,
      optionsFuncao: [
              { value: "1", text: "Pregoeiro" },
              { value: "2", text: "Presidente Comissão" },
              { value: "3", text: "Membro Comissão" },
              { value: "4", text: "Suplente Comissão" },
              { value: "5", text: "Membro Equipe de Apoio" },
            ],
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      this.labelProcesso = "Nº do processo ";
    if (this.tipoProposta == "1") this.labelProcesso += "(Licitação)";
    else if (this.tipoProposta == "2") this.labelProcesso += "(Contratação)";
    else if (this.tipoProposta == "3") this.labelProcesso += "(Adesão)";
      if (this.item.id) {
        const url = `${baseApiUrl}/plan-agentes/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.getItemPlanLicitacao()
        });
      }

    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/plan-agentes${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    setDataPt(data) {
      return moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    setValidCpf() {
      if (
        this.itemData.cpf &&
        this.itemData.cpf.length == 11 &&
        cpf.isValid(this.itemData.cpf)
      )
        this.isCpf = "is-valid";
      else if (
        this.itemData.cpf &&
        this.itemData.cpf.length == 11 &&
        !cpf.isValid(this.itemData.cpf)
      )
        this.isCpf = "is-invalid";
      else this.isCpf = "";
    },
    getListPlanLicitacoes() {
      const url = `${baseApiUrl}/plan-licitacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listLicitacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListPlanContratacoes() {
      const url = `${baseApiUrl}/plan-contratacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listContratacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contratacao}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getItemPlanLicitacao() {
      const url = `${baseApiUrl}/plan-licitacoes/${this.itemData.id_plan_licit}`;
      axios
        .get(url)
        .then((res) => {
          this.modalidadeLicitacao = res.data.modalidade;
          // Tom Mendes 03/03/23 - Removido para que todas as modalidades tenha todas as opções
          // if (["6", "7"].includes(this.modalidadeLicitacao)) {
          //   this.optionsFuncao = [{ value: "1", text: "Pregoeiro" }];
          // } else {
          //   this.optionsFuncao = [
          //     { value: "1", text: "Pregoeiro" },
          //     { value: "2", text: "Presidente Comissão" },
          //     { value: "3", text: "Membro Comissão" },
          //     { value: "4", text: "Suplente Comissão" },
          //     { value: "5", text: "Membro Equipe de Apoio" },
          //   ];
          // }
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaCadServidores() {
      const url = `${baseApiUrl}/cad-servidores`;
      axios
        .patch(url)
        .then((res) => {
          this.listServidor = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf} - ${data.nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    if (this.tipoProposta == "1") this.getListPlanLicitacoes();
    else if (this.tipoProposta == "2") this.getListPlanContratacoes();
    else if (this.tipoProposta == "3") this.getListPlanAdesoes();
    // this.getListPlanLicitacoes();
    this.getListaCadServidores();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
