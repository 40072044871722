<template>
  <div class="cadastros-grid">
    <PageTitle
      icon="far fa-edit"
      main="Cadastros"
      sub="Pessoas, Empresas & Outros Órgãos"
    />
    <b-card no-body>
      <div class="cadastros-grid pdd-10">
        <b-input-group size="md" class="mt-3 mb-3">
          <b-dropdown :text="isBrowser ? lblBtnNew : ''" :variant="`outline-${lblBtnNewVariant}`" size="sm"
            slot="prepend">
            <b-dropdown-item @click="newRow(0)">Pessoa/Empresa</b-dropdown-item>
            <b-dropdown-item @click="newRow(1)">Outro Órgão</b-dropdown-item>
          </b-dropdown>
          <!-- <b-button
            :variant="`outline-${lblBtnNewVariant}`"
            size="sm"
            slot="prepend"
            @click="newRow"
          >
            <i class="fa fa-plus"></i>&nbsp;
            <span>{{ lblBtnNew }}</span>
          </b-button> -->
          <b-form-input
            type="text"
            placeholder="Digite aqui para localizar o registro..."
            v-b-tooltip.hover
            :title="`Digite ao menos ${charSearchCount} caracteres para pesquisa dinâmica e use espaços para pesquisar por mais de uma expressão`"
            v-model="keyword"
            @input="searchCadastros(charSearchCount)"
            @blur="searchCadastros(0)"
            ref="keyword"
          />
          <b-btn size="sm" @click="searchCadastros(0)">
            <i class="fa fa-search"></i>
          </b-btn>
          <b-input-group-text slot="append">
            <span class>{{ keyword_res }}&nbsp;</span>
            <b-btn
              :disabled="!keyword"
              variant="link"
              size="sm"
              @click="getRefresh"
              class="p-0"
            >
              <i class="fa fa-remove"></i>
            </b-btn>
          </b-input-group-text>
        </b-input-group>
        <b-table hover striped responsive :items="cadastros" :fields="fields">
          <template v-slot:cell(cadas_nome)="data" v-if="userParams.admin >= 1">
            <span v-html="`${data.item.cadas_nome} (${data.item.id})`" />
          </template>
          <template v-slot:cell(cadas_nome)="data" v-else>
            <span v-html="`${data.item.cadas_nome}`" />
          </template>
          <template #cell(cpf_cnpj)="data">
            <span v-html="data.item.cpf_cnpj" />
          </template>
          <template #cell(actions)="row">
            <b-button
              v-if="userParams.id >= 1"
              variant="outline-info"
              size="sm"
              class="mr-1"
              v-b-tooltip.hover
              title="Editar registro"
              @click="showRow(row.item, row.index, $event.target)"
              href="#header"
            >
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button
              v-if="userParams.id >= 1"
              variant="outline-danger"
              size="sm"
              v-b-tooltip.hover
              title="Remover registro"
              @click="remove(row.item.id)"
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination
          size="md"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
        />
      </div>
    </b-card>
    <b-modal
      size="xl"
      id="modal-form"
      :title="modalTitle"
      centered
      scrollable
      hide-footer
    >
      <CadastroData :item="cadastrosInputs" @objectInputs="loadCadastros" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import axios from "axios";
import { baseApiUrl } from "@/env";
import { getDecimalFormater } from "@/config/globalFacilities";
import { showError, highlight, removeMark } from "@/global";
import CadastroData from "./CadastroData";
import PageTitle from "../template/PageTitle";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "CadastrosGrid",
  components: { CadastroData, PageTitle },
  data: function () {
    return {
      userParams: {},
      cadastros: [], // table
      cadastro: {}, // field
      cadastrosInputs: {}, // form data
      loading: false,
      modalTitle: "Novo Registro",
      lblBtnNew: "Novo Registro",
      isBrowser: isBrowser,
      lblBtnNewVariant: "primary",
      mode: "save",
      keyword: "",
      keyword_res: "",
      charSearchCount: 3,
      lblCancelar: "Cancelar",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      tipoCadastro: 0,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        { key: "cadas_nome", label: "Nome", sortable: true },
        { key: "cpf_cnpj", label: "CPF/CNPJ", sortable: true },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions2",
          tdClass: "col-actions2",
        },
      ],
    };
  },
  methods: {
    searchCadastros(length) {
      if (
        this.keyword.toString().length >= length ||
        this.keyword.toString().length == 0
      )
        this.loadCadastros();
    },
    loadCadastros() {
      const url = `${baseApiUrl}/cadastros?page=${this.page}&key=${this.keyword}`;
      axios.get(url).then((res) => {
        this.cadastros = res.data.data;
        this.cadastros.forEach((element) => {
          element.cpf_cnpj = highlight(element.cpf_cnpj, this.keyword);
          element.cadas_nome = highlight(
            element.cadas_nome,
            this.keyword.toString().toUpperCase()
          );
        });
        this.count = res.data.count;
        this.limit = res.data.limit;
        const pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${pluralize}`;
      });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.loadCadastros();
    },
    reset() {
      this.keyword = "";
      this.mode = "save";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.getRefresh();
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    newRow(tipoCadastro) {
      this.cadastrosInputs = {};
      this.modalTitle = `Novo Registro de ${
        this.tipoCadastro == tipoCadastro ? "Pessoa ou Empresa" : "Outro Órgão"
      }`;
      this.$root.$emit("bv::show::modal", "modal-form");
    },
    showRow(item, index, button) {
      Object.entries(item).forEach(([key, value]) => {
        if (item)
          this.cadastrosInputs = {
            ...this.cadastrosInputs,
            [key]: removeMark(value || ""),
          };
      });
      this.modalTitle = `Registro: ${this.cadastrosInputs.cadas_nome}`;
      this.$root.$emit("bv::show::modal", "modal-form", button);
    },
    setDataPt(data) {
      return moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    remove(id) {
      const url = `${baseApiUrl}/cadastros/${id}`;
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            axios
              .delete(url)
              .then(() => {
                this.$toasted.global.defaultSuccess();
                this.cadastrosInputs = {};
                this.getRefresh();
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
  },
  mounted() {
    this.loadUserParams();
    this.loadCadastros();
  },
  watch: {
    page() {
      this.loadCadastros();
    },
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.cadastros-data {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>