<template>
  <div class="adj_registro-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nº do Processo (Licitação)"
            label-for="adj_registros-id_adj_lic"
          >
            <v-select
              id="adj_registros-id_adj_lic"
              :options="listLicitacao"
              v-model="itemData.id_adj_lic"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe a Licitação ..."
              @input="getLicitacao"
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Contratado (Pessoas & Empresas)"
            label-for="adj_registros-id_cadastro"
          >
            <v-select
              id="adj_registros-id_cadastro"
              :options="listCadastros"
              v-model="itemData.id_cadastro"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Contratado ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Nº Ata Registro" label-for="adj_registros-n_ata">
            <b-form-input
              id="adj_registros-n_ata"
              type="text"
              v-model="itemData.n_ata"
              @keypress="isNumber($event)"
              maxlength="16"
              required
              placeholder="Informe o Nº da Ata de Registro ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Valor Ata" label-for="adj_registros-valor">
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor"
                v-bind="money"
                id="adj_registros-valor"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Início da Vigência"
            label-for="adj_registros-inicio_vigencia"
          >
            <date-picker
              v-model="itemData.inicio_vigencia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Fim da Vigência"
            label-for="adj_registros-fim_vigencia"
          >
            <date-picker
              v-model="itemData.fim_vigencia"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Data Publicação Ata"
            label-for="adj_registros-data_pub_ata"
          >
            <date-picker
              v-model="itemData.data_pub_ata"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Veículo Publicação Ata"
            label-for="adj_registros-vei_pub_ata"
          >
            <b-form-select
              id="adj_registros-vei_pub_ata"
              :options="optionsVeiculosPub"
              v-model="itemData.vei_pub_ata"
              placeholder="Informe o Veículo de Publicação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="adj_registros-descricao">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="adj_registros-descricao"
              type="text"
              v-model="itemData.descricao"
              maxlength="1024"
              required
              placeholder="Descrição do Objeto Registrado ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Link Publicação"
            label-for="adj_registros-link_pub"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="adj_registros-link_pub"
              type="text"
              v-model="itemData.link_pub"
              maxlength="1024"
              placeholder="Informe o Link Publicação ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Adjudicação Registro: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { Money } from "v-money";
import { optionsVeiculosPub } from "@/config/lists";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "AdjRegistroData",
  props: ["item"],
  components: { Money, DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      listLicitacao: [],
      licitacao: {},
      listCadastros: [],
      labelsDatePicker: datePickerLocale,
      optionsVeiculosPub: optionsVeiculosPub,
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/adj-registros/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
          this.getLicitacao();
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/adj-registros${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.getLicitacao();
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getLicitacao() {
      const url = `${baseApiUrl}/adj-licitacoes/${this.itemData.id_adj_lic}`;
      axios
        .get(url)
        .then((res) => {
          this.itemData.licitacao = res.data
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListAdjLicitacoes() {
      const url = `${baseApiUrl}/adj-licitacoes`;
      axios
        .patch(url)
        .then((res) => {
          this.listLicitacao = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_processo} (${data.n_licitacao})`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadastros = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListAdjLicitacoes();
    this.getListCadastros();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
