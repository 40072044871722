<template>
  <b-dropdown :id="`dropdown-${keyId}`" :text="subCategoria.nome" class="ml-2" v-if="visible">
    <b-dropdown-item @click="editSubCat">Renomear
      <b-icon icon="pencil" />
    </b-dropdown-item>
    <b-dropdown-item @click="removeSubCat">Excluir
      <b-icon icon="trash" />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/env";
import { showError } from "@/global";
export default {
  name: "PlanSubCategoria",
  props: ["subCategoria", "categoria", "keyId"],
  data: function(){
    return {
      visible: true,
    }
  },
  methods: {
    removeSubCat() {
      this.$confirm({
        message: `Confirma a exclusão deste registro?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            const url = `${baseApiUrl}/plan-categorias/${this.subCategoria.id}`;
            axios
              .delete(url)
              .then(() => {
                this.visible = false
                this.$toasted.global.defaultSuccess();
              })
              .catch((error) => {
                showError(error);
              });
          }
        },
      });
    },
    editSubCat() {
      const newName = prompt("Por favor insira o novo nome", this.subCategoria.nome);
      const url = `${baseApiUrl}/plan-categorias/${this.subCategoria.id}`;      
      axios
        .put(url, { nome: newName })
        .then(() => {
          this.subCategoria.nome = newName
          this.$toasted.global.defaultSuccess();
        })
        .catch((error) => {
          showError(error);
        });
    },
  }
};
</script>

<style>
</style>