<template>
  <div class="exec_meta-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nº do Contrato"
            label-for="exec_metas-id_exec_contrat"
          >
            <v-select
              id="exec_metas-id_exec_contrat"
              :options="listContrato"
              v-model="itemData.id_exec_contrat"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              required
              placeholder="Informe o Nº do Contrato ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Nº Meta" label-for="exec_metas-n_meta">
            <b-form-input
              id="exec_metas-n_meta"
              type="text"
              v-model="itemData.n_meta"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe o Nº da Meta ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group
            label="Código Programa"
            label-for="exec_metas-cod_programa"
          >
            <b-form-input
              id="exec_metas-cod_programa"
              type="text"
              v-model="itemData.cod_programa"
              maxlength="16"
              required
              placeholder="Informe o Código Programa ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Data Início" label-for="exec_metas-data_inicio">
            <date-picker
              v-model="itemData.data_inicio"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Data Fim" label-for="exec_metas-data_fim">
            <date-picker
              v-model="itemData.data_fim"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Quantidade Meta" label-for="exec_metas-qtd_meta">
            <b-form-input
              id="exec_metas-qtd_meta"
              type="text"
              v-model="itemData.qtd_meta"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe Quantidade da Meta ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Unidade Fornecimento"
            label-for="exec_metas-und_fornecimento"
          >
            <b-form-input
              id="exec_metas-und_fornecimento"
              type="text"
              v-model="itemData.und_fornecimento"
              maxlength="32"
              required
              placeholder="Informe a Und. Fornecimento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Valor da Meta" label-for="exec_itens-valor">
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor"
                v-bind="money"
                required
                id="exec_itens-valor"
                class="valor-field-right form-control"
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nome Programa"
            label-for="exec_metas-nome_programa"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_metas-nome_programa"
              type="text"
              v-model="itemData.nome_programa"
              maxlength="255"
              required
              placeholder="Descrição do Programa de Governo ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="exec_metas-descricao">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_metas-descricao"
              type="text"
              v-model="itemData.descricao"
              maxlength="1024"
              required
              placeholder="Descrição do Programa ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <b-button
          variant="warning"
          class="ml-2"
          @click="execEtapasGridModal"
          v-if="itemData.id"
        >
          Lançar/Ver Etapas do Cronograma
        </b-button>
        <code v-if="itemData.hash" class="float-right">
          Metas Execução: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
    <b-modal
      size="xl"
      id="modal-form-ExecEtapasGrid-2"
      title="Etapas da Execução"
      centered
      scrollable
      hide-footer
    >
      <ExecEtapasGrid :item="itemData" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import ExecEtapasGrid from "../execEtapasAdmin/ExecEtapasGrid";
import moment from "moment";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ExecMetaData",
  props: ["item"],
  components: { Money, DatePicker, ExecEtapasGrid },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listContrato: [],
      modalTitle: "",
      isDate: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/exec-metas/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/exec-metas${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    setDataPt(data) {
      return moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    reset() {
      this.$bvModal.hide("modal-form-ExecMetaData");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListContratos() {
      const url = `${baseApiUrl}/exec-contratos`;
      axios
        .patch(url)
        .then((res) => {
          this.listContrato = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contrato}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    execEtapasGridModal() {
      this.modalTitle = `Etapas do Cronograma`;
      this.$root.$emit("bv::show::modal", "modal-form-ExecEtapasGrid-2");
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListContratos();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
