<template>
  <header id="header">
    <b-navbar toggleable="lg" class="navbar-transparent navbar navbar-expand-md fixed-top header">
      <b-navbar-brand :to="'/'">
        <i class="fas fa-shield-alt"></i>
        {{
          `${userParams.id
            ? "&nbsp;iMobil"
            : "&nbsp;Gestão Municipal"
          }`
        }}
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" v-if="userParams"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav v-if="userParams">
        <b-navbar-nav>
          <b-nav-item-dropdown text="Cadastros" v-if="userParams.id >= 1">
            <b-dropdown-item to="/und-autonomas">Unidades Próprias</b-dropdown-item>
            <b-dropdown-item to="/cad-servidores"> Servidores </b-dropdown-item>
            <b-dropdown-item to="/cadastros">Outros Órgãos, Pessoas & Empresas</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Compras Públicas" v-if="userParams.cpl >= 1">
            <b-dropdown-header>Siap</b-dropdown-header>
            <!-- <b-dropdown-item to="/cpl/siap"> Siap </b-dropdown-item> -->
            <b-dropdown-item to="/cpl/planejamento">
              Planejamento
            </b-dropdown-item>
            <b-dropdown-item to="/cpl/adjudicacao">
              Adjudicação
            </b-dropdown-item>
            <b-dropdown-item to="/cpl/execucao"> Execução </b-dropdown-item>
            <b-dropdown-item to="/cpl/convenio"> Convênio </b-dropdown-item>
            <b-dropdown-item to="/cpl">Arquivos </b-dropdown-item>
            <b-dropdown-header v-if="userParams.cpl_editais >= 1">
              Públicos
            </b-dropdown-header>
            <b-dropdown-item to="/cpl/editais" v-if="userParams.cpl_editais >= 1">
              Editais
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!--PATRIMONIOS-->
          <b-nav-item-dropdown text="Patrimônio" v-if="userParams.patrimonio >= 1">
            <b-dropdown-header>Siap</b-dropdown-header>
            <b-dropdown-item to="/patrimonio#setores">
              Setores
            </b-dropdown-item>
            <b-dropdown-item to="/patrimonio#departamentos">
              Departamentos
            </b-dropdown-item>
            <b-dropdown-item to="/patrimonio#locacoes">
              Locações
            </b-dropdown-item>
            <b-dropdown-item to="/patrimonio#objetos">
              Objetos
            </b-dropdown-item>
            <b-dropdown-item to="/patrimonio#arquivos">
              Arquivos e Relatórios
            </b-dropdown-item>
            <b-dropdown-divider hidden />
            <b-dropdown-header hidden>Depreciação</b-dropdown-header>
            <b-dropdown-item to="/" hidden> Categorias </b-dropdown-item>
            <b-dropdown-item to="/" hidden> Bens </b-dropdown-item>
            <b-dropdown-divider hidden />
            <b-dropdown-header hidden>Documentos</b-dropdown-header>
            <b-dropdown-item to="/" hidden> TCA </b-dropdown-item>
            <b-dropdown-item to="/" hidden> TG </b-dropdown-item>
          </b-nav-item-dropdown>
          <!--OBRAS-->
          <b-nav-item-dropdown text="Obras" v-if="userParams.obras >= 1">
            <b-dropdown-header>Siap</b-dropdown-header>
            <b-dropdown-item to="/obra#obras"> Obras </b-dropdown-item>
            <b-dropdown-item to="/obra#ordens-de-servicos">
              Ordens de Serviço
            </b-dropdown-item>
            <b-dropdown-item to="/obra#cadastro-nacional">
              Cadastro Nacional
            </b-dropdown-item>
            <b-dropdown-item to="/obra#acompanhamentos">
              Acompanhamentos
            </b-dropdown-item>
            <b-dropdown-item to="/obra#medicoes"> Medições </b-dropdown-item>
            <b-dropdown-item to="/obra#documento-responsabilidade-tecnica">
              DRTs
            </b-dropdown-item>
            <b-dropdown-item to="/obra#licenca-ambiental">
              Licença Ambiental
            </b-dropdown-item>
            <b-dropdown-item to="/obra#arquivos"> Arquivos </b-dropdown-item>
          </b-nav-item-dropdown>
          <!--ALMOXARIFADO-->
          <b-nav-item-dropdown text="Almoxarifado" v-if="userParams.admin >= 2">
            <b-dropdown-header>Siap</b-dropdown-header>
            <b-dropdown-item to="/almoxarifado#almoxarifados"> Almoxarifado </b-dropdown-item>
            <b-dropdown-item to="/almoxarifado#processos">
              Processos
            </b-dropdown-item>
            <b-dropdown-item to="/almoxarifado#relatorios">
              Relatórios
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <UserParamsForm v-if="
            (userParams.admin && userParams.admin >= 1) ||
            (userParams.gestor && userParams.gestor >= 1) ||
            (userParams.multiCliente && userParams.multiCliente >= 1)
          " />
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto user-dropdown">
          <!-- <b-nav-item @click="support" v-b-tooltip.hover title="Suporte">
            <i class="fa fa-whatsapp" aria-hidden="true"></i>
          </b-nav-item> -->
          <b-nav-item to="/auth" v-if="!(userParams.id || ['/auth'].includes($route.path))">
            Acessar
          </b-nav-item>
          <b-nav-item-dropdown ref="dropdown" right v-if="userParams.id">
            <template slot="button-content">
              <i class="far fa-user-circle"></i>
              {{ nameInitials(userParams.name) }}
            </template>
            <b-dropdown-item to="/admin"><i class="fa fa-cogs"></i>
              {{
                userParams.admin && userParams.admin >= 1
                ? "Administração"
                : "Perfil"
              }}</b-dropdown-item>
            <b-dropdown-item href @click.prevent="logout"><i class="fa fa-sign-out"></i> Sair</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>
 
<script>
import { userKey } from "@/global";
import { baseApiUrl } from "@/env";
import axios from "axios";
import UserParamsForm from "./UserParamsForm";
import { mapState } from "vuex";

export default {
  name: "Header",
  components: { UserParamsForm },
  data: function () {
    return {
      clientName: "",
      userParams: {},
      admTitle: "Administração",
    };
  },
  props: {
    title: String,
  },
  methods: {
    support() {
      window.open("https://api.whatsapp.com/send?phone=5582981499024");
    },
    nameInitials(name) {
      const names = name.split(" ");
      let ret = "";
      names.forEach((nameElement) => {
        ret += nameElement.substring(0, 1).toUpperCase();
      });
      return ret.substring(0, 2);
    },
    logout() {
      localStorage.removeItem(userKey);
      this.$store.commit("setUser", null);
      this.userParams = {};
      this.$router.push({ name: "auth" });
    },
    setAdmTitle() {
      this.admTitle =
        this.userParams && this.userParams.admin >= 1
          ? "Administração"
          : "Perfil";
    },
  },
  watch: {
    user: function () {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/users/${this.user.id}`;
        axios.get(url).then((res) => {
          this.userParams = res.data;
        });
      }
    },
  },
  mounted() {
    this.setAdmTitle();
  },
  computed: mapState(["user"]),
};
</script>

<style>
.navbar-transparent {
  background-color: transparent;
}

.logoiMobil {
  max-height: 35px;
}

.center-cropped img {
  object-fit: none;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
  height: 350px;
  width: 350px;
}

.foundMark {
  background-color: yellow;
  padding: 0;
}

.header {
  grid-area: header;
  background: #b9ad5d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-inline .search {
  min-width: 300px;
}

.title {
  font-size: 1.2rem;
  color: #3a1413;
  font-weight: 100;
  flex-grow: 1;
  text-align: center;
}

.title a {
  color: #3a1413;
  text-decoration: none;
}

.title a:hover {
  color: #3a1413;
  text-decoration: none;
}

header.header>a.toggle {
  width: 60px;
  height: 100%;
  color: #3a1413;
  justify-self: flex-start;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

header.header>a.toggle:hover {
  color: #3a1413;
  background-color: rgba(0, 0, 0, 0.2);
}

.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.col-actions2 {
  min-width: 95px;
}

.col-actions3 {
  min-width: 125px;
}

.col-value {
  min-width: 120px;
  text-align: right;
}

.valor-field-right {
  text-align: right;
}

.valor-field-center {
  text-align: center;
}

.pdd-10 {
  padding: 10px;
}

.modal-body {
  padding: 1.5rem;
}
</style>
