<template>
  <div class="obra_acompan-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group
            label="Nº do Contrato"
            label-for="obras_acompans-id_ex_cont"
          >
            <v-select
              id="obras_acompans-id_ex_cont"
              :options="listContrato"
              v-model="itemData.id_ex_cont"
              :reduce="(label) => label.code"
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº do Contrato ..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Responsável (Pessoas & Empresas)"
            label-for="obras_acompans-id_cadastro"
          >
            <v-select
              id="obras_acompans-id_cadastro"
              :options="listCadas"
              v-model="itemData.id_cadastro"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o Responsável pela fiscalização da obra..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Nº Parcela Desembolso"
            label-for="obras_acompans-id_desemb"
          >
            <v-select
              id="obras_acompans-id_desemb"
              :options="listDesemb"
              v-model="itemData.id_desemb"
              :reduce="(label) => label.code"
              required
              :disabled="mode === 'remove'"
              placeholder="Informe o Nº Parcela Desembolso..."
            >
              <div slot="no-options">
                A opção digitada não existe!!! Tente novamente...
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Data de Vistoria"
            label-for="obras_acompans-data"
          >
            <date-picker
              v-model="itemData.data"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>  
        <b-col md="4" sm="12">
          <b-form-group
            label="Mês Referência"
            label-for="obras_acompans-mes_ref"
          >
            <b-form-select
              id="obras_acompans-mes_ref"
              :options="optionsMes"
              v-model="itemData.mes_ref"
              required
              placeholder="Informe o Mês de Referência ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-form-group label="Situacao" label-for="obras_acompans-situacao">
            <b-form-select
              id="obras_acompans-situacao"
              :options="optionsSituacaoObra"
              v-model="itemData.situacao"
              required
              placeholder="Informe Situação ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group
            label="Valor Executado"
            label-for="obras_acompans-valor"
          >
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor"
                v-bind="money"
                id="obras_acompans-valor"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Descrição do Serviço"
            label-for="obras_acompans-desc_servico"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="obras_acompans-desc_servico"
              type="text"
              v-model="itemData.desc_servico"
              maxlength="1024"
              required
              placeholder="Informe a Descrição do Serviço Executado ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Acompanhamento: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import { optionsMes, optionsSituacaoObra } from "@/config/lists";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ObraAcompanData",
  props: ["item"],
  components: { Money, DatePicker },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      listContrato: [],
      listDesemb: [],
      listCadas: [],
      isDate: "",
      optionsMes: optionsMes,
      optionsSituacaoObra: optionsSituacaoObra,
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/obras-acompans/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/obras-acompans${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    reset() {
      this.$bvModal.hide("modal-form");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
    getListExecContratos() {
      const url = `${baseApiUrl}/exec-contratos`;
      axios
        .patch(url)
        .then((res) => {
          this.listContrato = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_contrato}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListDesembolsos() {
      const url = `${baseApiUrl}/exec-desembolsos`;
      axios
        .patch(url)
        .then((res) => {
          this.listDesemb = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.n_parcela}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
    getListaCadastros() {
      const url = `${baseApiUrl}/cadastros`;
      axios
        .patch(url)
        .then((res) => {
          this.listCadas = res.data.data.map((data) => {
            return {
              code: data.id,
              label: `${data.cpf_cnpj} - ${data.cadas_nome}`,
            };
          });
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
    this.getListExecContratos();
    this.getListDesembolsos();
    this.getListaCadastros();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
