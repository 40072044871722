<template>
  <div class="stats-patrimonio">
    <b-card
      title="Dados do patrimônio"
      class="mb-3"      
    >
      <div class="stats">
        <Stat
          :class="statsBrowser"
          title="Unidades"
          :value="stat.patr_unidades"
          icon="fas fa-city"
          color="#3a1413d8"
          link="/und-autonomas"
        />
        <Stat
          :class="statsBrowser"
          title="Setores"
          :value="stat.patr_setores"
          icon="fas fa-building"
          color="#3a1413d8"
          link="/patrimonio#setores"
        />
        <Stat
          :class="statsBrowser"
          title="Departamentos"
          :value="stat.patr_deptos"
          icon="fas fa-map-marked-alt"
          color="#3a1413d8"
          link="/patrimonio#departamentos"
        />
        <Stat
          :class="statsBrowser"
          title="Locações"
          :value="stat.patr_locacoes"
          icon="fas fa-map-marker-alt"
          color="#3a1413d8"
          link="/patrimonio#locacoes"
        />
        <Stat
          :class="statsBrowser"
          title="Objetos"
          :value="stat.patr_objetos"
          icon="fas fa-calculator"
          color="#3a1413d8"
          link="/patrimonio#objetos"
        />
        <Stat
          :class="statsBrowser"
          title="Cadastros"
          :value="stat.cadastros"
          icon="fas fa-file-signature"
          color="#3a1413d8"
          link="/cadastros"
        />
        <Stat
          :class="statsBrowser"
          title="Objetos não alocados"
          :value="stat.patr_objetos"
          icon="fas fa-boxes"
          color="orange"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import Stat from "./Stat";
import { mapState } from "vuex";
import { baseApiUrl } from "@/env";
import axios from "axios";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "Home",
  components: { Stat },
  data: function () {
    return {
      stat: {
        patr_objetos: 0,
        patr_deptos: 0,
        patr_locacoes: 0,
        patr_setores: 0,
        patr_unidades: 0,
        cadastros: 0,
        depreciacao_bens: 0,
        depreciacao_cats: 0,
      },
      statsBrowser: "",
    };
  },
  methods: {
    loadPatrimonios() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patrimonio-count`;
        axios.get(url).then((res) => {
          this.stat.patr_objetos = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadPatrDeptos() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patr-deptos-count`;
        axios.get(url).then((res) => {
          this.stat.patr_deptos = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadPatrDocumentos() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patr-documentos-count`;
        axios.get(url).then((res) => {
          this.stat.patr_documento = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadPatrLocacoes() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patr-locacoes-count`;
        axios.get(url).then((res) => {
          this.stat.patr_locacoes = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadPatrSetores() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/patr-setores-count`;
        axios.get(url).then((res) => {
          this.stat.patr_setores = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadUnidAutonomas() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/und-autonomas-count`;
        axios.get(url).then((res) => {
          this.stat.patr_unidades = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    loadCadastros() {
      if (this.user && this.user.id) {
        const url = `${baseApiUrl}/cadastros-count`;
        axios.get(url).then((res) => {
          this.stat.cadastros = res.data.count > 0 ? res.data.count : "0";
        });
      }
    },
    setStatsBrowserClass() {
      if (isBrowser) this.statsBrowser = "stats-desktop";
    },
  },
  mounted() {
    this.loadPatrimonios();
    this.loadPatrDeptos();
    this.loadPatrDocumentos();
    this.loadPatrLocacoes();
    this.loadPatrSetores();
    this.loadUnidAutonomas();
    this.loadCadastros();
    this.setStatsBrowserClass();
  },
  computed: mapState(["user"]),
};
</script>

<style>
.stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
