<template>
  <div class="exec_etapa-data">
    <b-form>
      <input type="hidden" v-model="itemData.id" />
      <input type="hidden" v-model="itemData.evento" />
      <input type="hidden" v-model="itemData.status" />
      <input type="hidden" v-model="itemData.created_at" />
      <input type="hidden" v-model="itemData.updated_at" />
      <b-row>
        <b-col md="3" sm="12">
          <b-form-group label="Nº Etapa" label-for="exec_etapas-n_etapa">
            <b-form-input
              id="exec_etapas-n_etapa"
              type="text"
              v-model="itemData.n_etapa"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe o Nº da Etapa ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Data Início"
            label-for="exec_etapas-data_inicio"
          >
            <date-picker
              v-model="itemData.data_inicio"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Data Fim"
            label-for="exec_etapas-data_fim"
          >
            <date-picker
              v-model="itemData.data_fim"
              valueType="DD/MM/YYYY"
              format="DD/MM/YYYY"
              type="date"
              required
              input-class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group
            label="Quantidade"
            label-for="exec_etapas-qtd_etapa"
          >
            <b-form-input
              id="exec_etapas-qtd_etapa"
              type="text"
              v-model="itemData.qtd_etapa"
              @keypress="isNumber($event)"
              maxlength="10"
              required
              placeholder="Informe Quantidade da Etapa ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Valor" label-for="exec_itens-valor">
            <b-input-group prepend="R$">
              <money
                v-model="itemData.valor"
                v-bind="money"
                id="exec_itens-valor"
                class="valor-field-right form-control"
                required
              >
              </money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group
            label="Unidade Fornecimento"
            label-for="exec_etapas-und_fornecimento"
          >
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_etapas-und_fornecimento"
              type="text"
              v-model="itemData.und_fornecimento"
              maxlength="255"
              required
              placeholder="Informe a Und. Fornecimento ..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="exec_etapas-descricao">
            <b-form-textarea
              rows="7"
              max-rows="7"
              id="exec_etapas-descricao"
              type="text"
              v-model="itemData.descricao"
              maxlength="1024"
              required
              placeholder="Especificação da Etapa ..."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button
          variant="primary"
          class="float-left"
          v-if="mode === 'save'"
          @click="save"
        >
          {{ itemData.id ? "Editar" : "Salvar" }}
        </b-button>
        <b-button variant="danger" v-if="mode === 'remove'" @click="remove">
          Excluir
        </b-button>
        <b-button class="ml-2" @click="reset">Sair</b-button>
        <code v-if="itemData.hash" class="float-right"
          >Etapas Cronograma: {{ itemData.hash + itemData.tblName }}</code
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { isNumber, datePickerLocale } from "@/config/globalFacilities";
import moment from "moment";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  name: "ExecEtapaData",
  props: ["item"], // exec_etapas + exec_metas(meta)
  components: { Money, DatePicker, },
  data: function () {
    return {
      userParams: {},
      itemData: {},
      labelsDatePicker: datePickerLocale,
      mode: "save",
      lblCancelar: "Cancelar",
      isNumber: isNumber,
      isDate: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
    };
  },
  methods: {
    loadData() {
      if (this.item.id) {
        const url = `${baseApiUrl}/exec-etapas/${this.item.id}`;
        axios.get(url).then((body) => {
          this.itemData = body.data;
        });
      } else {
        this.itemData.id_exec_cont = this.item.id_exec_cont,
        this.itemData.id_exec_meta = this.item.id_exec_meta,
        this.itemData.meta = this.item
      }
    },
    save() {
      const method = this.itemData.id ? "put" : "post";
      const id = this.itemData.id ? `/${this.itemData.id}` : "";
      axios[method](`${baseApiUrl}/exec-etapas${id}`, this.itemData)
        .then((res) => {
          this.itemData = res.data;
          this.$toasted.global.defaultSuccess();
          this.$emit("objectInputs");
        })
        .catch((error) => {
          showError(error);
        });
    },
    setDataPt(data) {
      return moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    reset() {
      this.$bvModal.hide("modal-form-ExecEtapaData");
    },
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
      });
    },
  },
  watch: {},
  mounted() {
    this.loadUserParams();
    this.loadData();
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
input[type="email"] {
  text-transform: lowercase;
}
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}
.mx-datepicker {
  width: 100%;
}
</style>
